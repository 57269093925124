const getModuleState = (state) => state.team;

export const getFeaturedTeam = (state) => getModuleState(state).featured;
export const getMoreInfoLinksTeam = (state) => getModuleState(state).moreInfoLinks;
export const getHeadingImages = (state) => getModuleState(state).headingImages;

export const getWorkWithUsInfo = (state) => getModuleState(state).workWithUs.mainInfo;
export const getWorkWithUsImage = (state) => getModuleState(state).workWithUs.mainImage;
export const getWorkWithUsFormInfo = (state) => getModuleState(state).workWithUs.formInfo;
export const getWorkWithUsInputs = (state) => getModuleState(state).workWithUs.inputsList;
export const getWorkWithUsInputError = (state) => getModuleState(state).workWithUs.inputError;
export const getWorkWithUsConfirm = (state) => getModuleState(state).workWithUs.confirmForm;
export const getWorkWithUsSend = (state) => getModuleState(state).workWithUs.sendForm;
export const getWorkWithUsSubmitMessages = (state) => getModuleState(state).workWithUs.submitMessages;
export const getWorkWithUsMoreInfoLinks = (state) => getModuleState(state).workWithUs.moreInfoLinks;