import { combineReducers } from 'redux';

const initialState = {
    displayRoute: "notFound.route.text",

    messages: [
        {
            image: "/images/notFound/fede-circle.webp",
            imageAlt: "fede",
            name: "notFound.messagesList.message.name1",
            textStrong: "notFound.messagesList.message.text1.strong",
            text: "notFound.messagesList.message.text1"
        },
        {
            image: "/images/notFound/fran-circle.webp",
            imageAlt: "fran",
            name: "notFound.messagesList.message.name2",
            text: "notFound.messagesList.message.text2"
        },
        {
            image: "/images/notFound/mou-circle.webp",
            imageAlt: "mou",
            name: "notFound.messagesList.message.name3",
            text: "notFound.messagesList.message.text3"
        },
        {
            image: "/images/notFound/fede-circle.webp",
            imageAlt: "fede",
            name: "notFound.messagesList.message.name4",
            text: "notFound.messagesList.message.text4"
        }
    ],

    buttons: [
        "notFound.buttons.text1",
        "notFound.buttons.text2"
    ]
};

const displayRoute = (state = initialState.displayRoute, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const messages = (state = initialState.messages, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const buttons = (state = initialState.buttons, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const reducer = combineReducers({
  displayRoute,
  messages,
  buttons
});

export default reducer;
