import React from 'react';
import { createRoot } from 'react-dom/client';

import {Provider} from 'react-redux';
import {IntlProvider} from 'react-intl';

import configureStore from './store';
import {App} from './modules/app';
import * as actions from './modules/app/actions';
import api from './api';
import { NetworkError } from './api';
import {initReactIntl} from './i18n';

import { getCookieAnalytics } from './utils/cookiesUtils';
import TagManager from 'react-gtm-module'

let worker;
let refreshing = false;

const store = configureStore();

api.init((_) => store.dispatch(actions.error(new NetworkError())));

const {locale, messages} = initReactIntl();

const tagManagerArgs = {
  gtmId: 'GTM-W8W97KB'
}

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <IntlProvider locale={locale} messages={messages}>
      <App />
    </IntlProvider>
  </Provider>
);

if(getCookieAnalytics() && getCookieAnalytics() != "false"){
  TagManager.initialize(tagManagerArgs);
  //ReactGA.initialize('G-X9GMQSQPZX');
}

// Service Worker installation
// if ('serviceWorker' in navigator && process.env.NODE_ENV === 'production') {
//   window.addEventListener('load', () => {
//     navigator.serviceWorker.register('./serviceWorker.js').then(
//       (registration) => {
//         registration.addEventListener('updatefound', () => {
//           const worker = registration.installing;
//           worker.addEventListener('statechange', () => {
//             if (worker.state === 'installed') {
//               worker.postMessage({action: 'skipWaiting'});
//             }
//           });
//         });
//       },
//       (err) => {
//         console.error('Service worker failed', err);
//       }
//     );
//   });
  
//   navigator.serviceWorker.addEventListener('controllerchange', () => {
//     if (!refreshing) {
//       window.location.reload();
//       refreshing = true;
//     }
//   });
// }