import { combineReducers } from "redux";

const initialState = {
    featured: {
        left: {
            image: "/images/homepage/projects/galicine.webp",
            altImage: "Proyecto Galicine",
            title: "Galicine",
            link: "/proyecto/galicine",
        },
        rightTop: {
            image: "/images/homepage/projects/regolodos.webp",
            altImage: "Proyecto Rego Lodos",
            title: "Rego Lodos",
            link: "/proyecto/regolodos",
        },
        rightBottom: {
            image: "/images/homepage/projects/batilas.webp",
            altImage: "Proyecto Batilas",
            title: "Batilas",
            link: "/proyecto/batilas",
        },
    },
    projectsPage: {
        servicesList: ['Ecommerce', 'Web', 'Marketing'],
        moreInfoLinks: {
            group1: [
                { name: "Ecommerce", url: "ecommerce" },
                { name: "Web", url: "web-corporativa" },
                { name: "Marketing", url: "marketing" },
            ]
        },
        projectsSuperiorRow:{
        bouretdesktop:{
            image: '/images/projects/projects-page/bouret-projectspage.webp',
            altImage:'Proyecto Bouret',
            title: 'Boüret',
            link: '/proyecto/bouret'
        },
        regolodosdesktop:{
            image: '/images/projects/projects-page/Rego-horizontal.webp',
            altImage:'Proyecto Regolodos',
            title: 'Rego Lodos',
            link: '/proyecto/regolodos'
        },
        regolodosmobile:{
            image: '/images/projects/projects-page/regolodos-mobile-projectspage.webp',
            altImage:'Proyecto Regolodos',
            title: 'Rego Lodos',
            link: '/proyecto/regolodos'
        }
        },
    
        projectsDoubleRow:{
        gridColLeft:[
            {
            image: '/images/projects/projects-page/cubicaje-projectspage.webp',
            altImage:'Proyecto Cubicaje',
            title: 'C4bicaje',
            link: '/proyecto/cubicaje'
            },
            {
            image: '/images/projects/projects-page/castro-projectspage.webp',
            altImage:'Proyecto Castro',
            title: 'Castro',
            link: '/proyecto/castro'
            }],
        gridColRight:[
            {
            image: '/images/projects/projects-page/termaria-projectspage.webp',
            altImage:'Proyecto Termaria',
            title: 'Termaria',
            link: '/proyecto/termaria'
            },
            {
            image: '/images/projects/projects-page/batilas-projectspage.webp',
            altImage:'Proyecto Batilas',
            title: 'Batilas',
            link: '/proyecto/batilas'
            }
        ],
        vertical:{
            verticaldesktop:{
            image: '/images/projects/projects-page/gadisa-vertical.webp',
            altImage:'Proyecto Gadisa',
            title: 'Gadis',
            link: '/proyecto/gadis'
            }
        }
        },
        normalProjects:[
        {
            image: '/images/projects/projects-page/grunver-projectspage.webp',
            altImage:'Proyecto Grunver',
            title: 'Grunver',
            link: '/proyecto/grunver'
        },
        {
            image: '/images/projects/projects-page/galicine-projectspage.webp',
            altImage:'Proyecto Galicine',
            title: 'Galicine',
            link: '/proyecto/galicine'
        },
        {
            image: '/images/projects/projects-page/autoradio-projectspage.webp',
            altImage:'Proyecto Autoradio',
            title: 'Autoradio',
            link: '/proyecto/autoradio'
        },
        {
            image: '/images/projects/projects-page/cetarea-projectspage.webp',
            altImage:'Proyecto Cetarea-Burela',
            title: 'Cetarea Burela',
            link: '/proyecto/cetarea-burela'
        },
        {
            image: '/images/projects/projects-page/jenifer-projectspage.webp',
            altImage:'Proyecto Jenifer Fuentes',
            title: 'Jenifer Fuentes',
            link: '/proyecto/jenifer-fuentes'
        }
        ]
    },
    cubicaje: {
        servicesList: ["Desarrollo Web", " ", " "],
        externalLink: {
            text: "Visita C4bicaje",
            href: "https://cubicaje.com/",
        },
        mainImage: {
            src: "/images/projects/cubicaje/cubicajeMain.webp",
            alt: "cubicaje",
        },
        descriptionImage: {
            videoSrc: "/images/projects/cubicaje/cubicajeSectionFirst.mp4",
            alt: "cubicajeVideo",
        },
        descriptionText: {
            title: "project.cubicaje.principal.title",
            title1: "project.cubicaje.project-description.title1",
            title2: "project.cubicaje.project-description.title2",
            paragraph1: "project.cubicaje.project-description.paragraph1",
            paragraph2: "project.cubicaje.project-description.paragraph2",
        },
        galleryImages: {
            first: {
                src: "/images/projects/cubicaje/cubicajeSectionFirst.webp",
                alt: "cubicajeGalleryImage",
            },
            second: {
                src: "/images/projects/cubicaje/cubicajeSectionSecond.webp",
                alt: "cubicajeGalleryImage",
            },
            third: {
                src: "/images/projects/cubicaje/cubicajeSectionThird.webp",
                alt: "cubicajeGalleryImage",
            },
            fourth: {
                src: "/images/projects/cubicaje/cubicajeSectionFourth.webp",
                alt: "cubicajeGalleryImage",
            },
            fifth: {
                src: "/images/projects/cubicaje/cubicajeSectionFifth.webp",
                alt: "cubicajeGalleryImage",
            },
            sixth: {
                src: "/images/projects/cubicaje/cubicajeSectionSixth.webp",
                alt: "cubicajeGalleryImage",
            },
        },
        moreInfoLinks: {
            group1: [
                { name: "Web Corporativa", url: "web-corporativa" },
                { name: "GMB", url: "gmb" },
                { name: "Analítica", url: "analitica" },
            ],
        },
    },
    bouret: {
        servicesList: ["Ecommerce", "SEO", "Social Ads"],
        externalLink: {
            text: "Visita Boüret",
            href: "https://bouretshop.com/es/",
        },
        mainImage: {
            src: "/images/projects/bouret/Bouret-main.webp",
            alt: "bouretMain",
        },
        mainGif: {
            src: "/images/projects/bouret/Bouret-gif.webp",
            alt: "bouretGif",
        },
        descriptionImage: {
            src: "/images/projects/bouret/Bouret-Movil.webp",
            alt: "bouretDescription",
        },
        descriptionText: {
            title: "project.bouret.principal.title",
            title1: "project.bouret.project-description.title1",
            title2: "project.bouret.project-description.title2",
            title3: "project.bouret.project-description.title3",
            paragraph1: "project.bouret.project-description.paragraph1",
            paragraph2: "project.bouret.project-description.paragraph2",
        },
        galleryImages: {
            first: {
                src: "/images/projects/bouret/Bouret-1.webp",
                alt: "bouretGallery",
            },
            second: {
                src: "/images/projects/bouret/Bouret-2.webp",
                alt: "bouretGallery",
            },
            third: {
                src: "/images/projects/bouret/Bouret-3.webp",
                alt: "bouretGallery",
            },
            fourth: {
                src: "/images/projects/bouret/Bouret-4.webp",
                alt: "bouretGallery",
            },
            fifth: {
                src: "/images/projects/bouret/Bouret-5.webp",
                alt: "bouretGallery",
            },
        },
        moreInfoLinks: {
            group1: [
                { name: "Ecommerce", url: "ecommerce" },
                { name: "Social Ads", url: "social-ads" },
                { name: "SEO", url: "seo" }
            ],
        },
    },
    regolodos: {
        servicesList: ["Ecommerce", "Social Ads", "Mail marketing"],
        externalLink: {
            text: "Visita Rego Lodos",
            href: "https://regolodos.com/",
        },
        mainImage: {
            src: "/images/projects/regolodos/regolodos-main.webp",
            alt: "regolodosMain",
        },
        descriptionImage: {
            src: "/images/projects/regolodos/regolodos-1.webp",
            alt: "regolodosDescription",
        },
        descriptionText: {
            title: "project.regolodos.principal.title",
            title1: "project.regolodos.project-description.title1",
            title2: "project.regolodos.project-description.title2",
            title3: "project.regolodos.project-description.title3",
            paragraph1: "project.regolodos.project-description.paragraph1",
            paragraph2: "project.regolodos.project-description.paragraph2",
        },
        moreInfoLinks: {
            group1: [
                { name: "Ecommerce", url: "ecommerce" },
                { name: "Social Ads", url: "social-ads" },
                { name: "Mail Marketing", url: "mail-marketing" },
            ],
        },
        galleryImages: {
            second: {
                src: "/images/projects/regolodos/regolodos-1.webp",
                alt: "regolodosGallery",
            },
            third: {
                src: "/images/projects/regolodos/regolodos-2.webp",
                alt: "regolodosGallery",
            },
            fourth: {
                src: "/images/projects/regolodos/regolodos-3.webp",
                alt: "regolodosGallery",
            },
            fifth: {
                src: "/images/projects/regolodos/regolodos-4.webp",
                alt: "regolodosGallery",
            },
            sixth: {
                src: "/images/projects/regolodos/regolodos-5.webp",
                alt: "regolodosGallery",
            },
        },
    },
    castro: {
        servicesList: ["Ecommerce", "Sem", "Mail marketing"],
        externalLink: {
            text: "Visita Castro",
            href: "https://www.castrofarmacias.com/",
        },
        mainImage: {
            src: "/images/projects/castro/castro-main.webp",
            alt: "castroMain",
        },
        descriptionImage: {
            src: "/images/projects/castro/castro-1.webp",
            alt: "castroDescription",
        },
        descriptionText: {
            title: "project.castro.principal.title",
            title1: "project.castro.project-description.title1",
            title2: "project.castro.project-description.title2",
            title3: "project.castro.project-description.title3",
            paragraph1: "project.castro.project-description.paragraph1"
        },
        moreInfoLinks: {
            group1: [
                { name: "Ecommerce", url: "ecommerce" },
                { name: "Sem", url: "sem" },
                { name: "Mail Marketing", url: "mail-marketing" },
            ],
        },
        galleryImages: {
            second: {
                src: "/images/projects/castro/castro-1.webp",
                alt: "castroGallery",
            },
            third: {
                src: "/images/projects/castro/castro-2.webp",
                alt: "castroGallery",
            },
            fourth: {
                src: "/images/projects/castro/castro-3.webp",
                alt: "castroGallery",
            },
            fifth: {
                src: "/images/projects/castro/castro-4.webp",
                alt: "castroGallery",
            },
            sixth: {
                src: "/images/projects/castro/castro-5.webp",
                alt: "castroGallery",
            },
            seventh: {
                src: "/images/projects/castro/castro-6.webp",
                alt: "castroGallery",
            },
            eigth: {
                src: "/images/projects/castro/castro-7.webp",
                alt: "castroGallery",
            },
        },
    },
    cetareaBurela: {
        servicesList: ["WooCommerce"],
        externalLink: {
            text: "Visita Cetárea Burela",
            href: "https://cetareaburela.com/",
        },
        mainImage: {
            src: "/images/projects/cetareaburela/main-cetarea-burela.webp",
            alt: "cetareaBurelaMain",
        },
        descriptionImage: {
            src: "/images/projects/cetareaburela/cetarea-description.webp",
            alt: "cetareaBurelaDescription",
        },
        descriptionText: {
            title: "project.cetareaBurela.principal.title",
            title1: "project.cetareaBurela.project-description.title1",
            title2: "project.cetareaBurela.project-description.title2",
            title3: "project.cetareaBurela.project-description.title3",
            paragraph1: "project.cetareaBurela.project-description.paragraph1",
            paragraph2: "project.cetareaBurela.project-description.paragraph2"
        },
        moreInfoLinks: {
            group1: [
                { name: "Woocommerce", url: "woocommerce" },
                { name: "Ecommerce", url: "ecommerce" },
                { name: "Mail Marketing", url: "mail-marketing" },
            ],
        },
        galleryImages: {
            first: {
                src: "/images/projects/cetareaburela/cetarea-web-3.webp",
                alt: "cetareaburelaGallery",
            },
            second: {
                src: "/images/projects/cetareaburela/cetarea-web-4.webp",
                alt: "cetareaburelaGallery",
            },
            third: {
                src: "/images/projects/cetareaburela/cetarea-web-1.webp",
                alt: "cetareaburelaGallery",
            },
            fourth: {
                src: "/images/projects/cetareaburela/cetarea-web-2.webp",
                alt: "cetareaburelaGallery",
            },
            fifth: {
                src: "/images/projects/cetareaburela/cetarea-web-5.webp",
                alt: "cetareaburelaGallery",
            },
            sixth: {
                src: "/images/projects/cetareaburela/cetarea-web-6.webp",
                alt: "cetareaburelaGallery",
            },
        },
    },
    galicine: {
        servicesList: ["Desarrollo a medida", "Ecommerce", "Imagen corporativa"],
        externalLink: {
            text: "Visita Galicine",
            href: "https://www.galicine.com/",
        },
        mainImage: {
            src: "/images/projects/galicine/galicine-main.webp",
            alt: "galicineMain",
        },
        descriptionImage: {
            src: "/images/projects/galicine/galicine-1.webp",
            alt: "galicineDescription",
        },
        descriptionText: {
            title: "project.galicine.principal.title",
            title1: "project.galicine.project-description.title1",
            title2: "project.galicine.project-description.title2",
            title3: "project.galicine.project-description.title3",
            paragraph1: "project.galicine.project-description.paragraph1",
            paragraph2: "project.galicine.project-description.paragraph2",
        },
        moreInfoLinks: {
            group1: [
                { name: "Ecommerce", url: "ecommerce" },
                { name: "Consultoria de marketing", url: "consultoria-marketing" },
                { name: "Social Ads", url: "social-ads" },
            ],
        },
        galleryImages: {
            third: {
                src: "/images/projects/galicine/galicine-2.webp",
                alt: "galicineGallery",
            },
            fourth: {
                src: "/images/projects/galicine/galicine-3.webp",
                alt: "galicineGallery",
            },
            fifth: {
                src: "/images/projects/galicine/galicine-4.webp",
                alt: "galicineGallery",
            },
            sixth: {
                src: "/images/projects/galicine/galicine-5.webp",
                alt: "galicineGallery",
            },
        },
    },
    batilas: {
        servicesList: ["Ecommerce", "Social Ads", "Google Ads"],
        externalLink: {
            text: "Visita Batilas",
            href: "https://batilas.com/",
        },
        mainImage: {
            src: "/images/projects/batilas/batilas-main.webp",
            alt: "batilasMain",
        },
        descriptionImage: {
            src: "/images/projects/batilas/batilas-1.webp",
            alt: "batilasDescription",
        },
        descriptionText: {
            title: "project.batilas.principal.title",
            title1: "project.batilas.project-description.title1",
            title2: "project.batilas.project-description.title2",
            title3: "project.batilas.project-description.title3",
            paragraph1: "project.batilas.project-description.paragraph1",
            paragraph2: "project.batilas.project-description.paragraph2",
        },
        moreInfoLinks: {
            group1: [
                { name: "Ecommerce", url: "ecommerce" },
                { name: "Google Ads", url: "google-ads" },
                { name: "Social Ads", url: "social-ads" },
            ],
        },
        galleryImages: {
            first: {
                src: "/images/projects/batilas/batilas-1.webp",
                alt: "batilasGalleryImage",
            },
            second: {
                src: "/images/projects/batilas/batilas-2.webp",
                alt: "batilasGalleryImage",
            },
            third: {
                src: "/images/projects/batilas/batilas-3.webp",
                alt: "batilasGalleryImage",
            },
            fourth: {
                src: "/images/projects/batilas/batilas-4.webp",
                alt: "batilasGalleryImage",
            },
            fifth: {
                src: "/images/projects/batilas/batilas-5.webp",
                alt: "batilasGalleryImage",
            },
            sixth: {
                src: "/images/projects/batilas/batilas-6.webp",
                alt: "batilasGalleryImage",
            },
        },
    },
    gadis: {
        servicesList: ["Desarrollo web", "Sem", "GMB"],
        externalLink: {
            text: "Visita Gadis",
            href: "https://gadis.es/",
        },
        mainImage: {
            src: "/images/projects/gadis/gadis-main.webp",
            alt: "gadisMain",
        },
        descriptionImage: {
            src: "/images/projects/gadis/gadis-1.webp",
            alt: "gadisDescription",
        },
        descriptionText: {
            title: "project.gadis.principal.title",
            title1: "project.gadis.project-description.title1",
            title2: "project.gadis.project-description.title2",
            title3: "project.gadis.project-description.title3",
            paragraph1: "project.gadis.project-description.paragraph1",
            paragraph2: "project.gadis.project-description.paragraph2",
        },
        moreInfoLinks: {
            group1: [
                { name: "Web Corporativa", url: "web-corporativa" },
                { name: "Sem", url: "sem" },
                { name: "GMB", url: "gmb" },
            ],
        },
        galleryImages: {
            first: {
                src: "/images/projects/gadis/gadis-1.webp",
                alt: "gadisGalleryImage",
            },
            second: {
                src: "/images/projects/gadis/gadis-2.webp",
                alt: "gadisGalleryImage",
            },
            third: {
                src: "/images/projects/gadis/gadis-3.webp",
                alt: "gadisGalleryImage",
            },
            fourth: {
                src: "/images/projects/gadis/gadis-4.webp",
                alt: "gadisGalleryImage",
            },
            fifth: {
                src: "/images/projects/gadis/gadis-5.webp",
                alt: "gadisGalleryImage",
            },
            sixth: {
                src: "/images/projects/gadis/gadis-6.webp",
                alt: "gadisGalleryImage",
            },
        },
    },
    jenifer: {
        servicesList: ["Woocommerce"],
        externalLink: {
            text: "Visita Jenifer Fuentes",
            href: "https://jeniferfuentes.com/",
        },
        mainImage: {
            src: "/images/projects/jenifer/jenifer-main.webp",
            alt: "jeniferMain",
        },
        descriptionImage: {
            src: "/images/projects/jenifer/jenifer-web-2.webp",
            alt: "jeniferDescription",
        },
        descriptionText: {
            title: "project.jenifer.principal.title",
            title1: "project.jenifer.project-description.title1",
            title2: "project.jenifer.project-description.title2",
            title3: "project.jenifer.project-description.title3",
            title4: "project.jenifer.project-description.title4",
            paragraph1: "project.jenifer.project-description.paragraph1",
            paragraph2: "project.jenifer.project-description.paragraph2",
        },
        moreInfoLinks: {
            group1: [
                { name: "Ecommerce", url: "ecommerce" },
                { name: "Social Ads", url: "social-ads" },
                { name: "Gmb", url: "gmb" },
            ],
        },
        galleryImages: {
            first: {
                src: "/images/projects/jenifer/jenifer-web-1.webp",
                alt: "jeniferGalleryImage",
            },
            second: {
                src: "/images/projects/jenifer/jenifer-web-3.webp",
                alt: "jeniferGalleryImage",
            },
            third: {
                src: "/images/projects/jenifer/jenifer-web-4.webp",
                alt: "jeniferGalleryImage",
            },
            fourth: {
                src: "/images/projects/jenifer/jenifer-web-5.webp",
                alt: "jeniferGalleryImage",
            },
            fifth: {
                src: "/images/projects/jenifer/jenifer-web-6.webp",
                alt: "jeniferGalleryImage",
            },
            sixth: {
                src: "/images/projects/jenifer/jenifer-web-7.webp",
                alt: "jeniferGalleryImage",
            },
        },
    },
    autoradio: {
        servicesList: ["Desarrollo Web"],
        externalLink: {
            text: "Visita Autoradio",
            href: "https://tautoradio.com/",
        },
        mainImage: {
            src: "/images/projects/autoradio/autoradio-main.webp",
            alt: "autoradioMain",
        },
        descriptionImage: {
            videoSrc: "/images/projects/autoradio/autoradio-video.mp4",
            alt: "autoradioVideo",
        },
        descriptionText: {
            title: "project.autoradio.principal.title",
            title1: "project.autoradio.project-description.title1",
            title2: "project.autoradio.project-description.title2",
            title3: "project.autoradio.project-description.title3",
            paragraph1: "project.autoradio.project-description.paragraph1",
            paragraph2: "project.autoradio.project-description.paragraph2",
        },
        moreInfoLinks: {
            group1: [
                { name: "Ecommerce", url: "ecommerce" },
                { name: "Google Ads", url: "google-ads" },
                { name: "Social Ads", url: "social-ads" },
            ],
        },
        galleryImages: {
            first: {
                src: "/images/projects/autoradio/gallery2.webp",
                alt: "autoradioGalleryImage",
            },
            second: {
                src: "/images/projects/autoradio/gallery1.webp",
                alt: "autoradioGalleryImage",
            },
            third: {
                src: "/images/projects/autoradio/gallery3.webp",
                alt: "autoradioGalleryImage",
            },
            fourth: {
                src: "/images/projects/autoradio/gallery2.webp",
                alt: "autoradioGalleryImage",
            },
            fifth: {
                src: "/images/projects/autoradio/gallery4.webp",
                alt: "autoradioGalleryImage",
            },
            sixth: {
                src: "/images/projects/autoradio/gallery5.webp",
                alt: "autoradioGalleryImage",
            },
        },
    },
    grunver: {
        servicesList: ["Desarrollo web", " ", " "],
        externalLink: {
            text: "Visita Grunver",
            href: "https://grunver.com/",
        },
        mainImage: {
            src: "/images/projects/grunver/grunver-main.webp",
            alt: "grunverMain",
        },
        descriptionImage: {
            src: "/images/projects/grunver/grunver-1.webp",
            alt: "grunverDescription",
        },
        descriptionText: {
            title: "project.grunver.principal.title",
            title1: "project.grunver.project-description.title1",
            title2: "project.grunver.project-description.title2",
            title3: "project.grunver.project-description.title3",
            paragraph1: "project.grunver.project-description.paragraph1",
            paragraph2: "project.grunver.project-description.paragraph2",
        },
        moreInfoLinks: {
            group1: [
                { name: "Web Corporativa", url: "web-corporativa" },
                { name: "SEO", url: "seo" },
                { name: "Mail Marketing", url: "mail-marketing" },
            ],
        },
        galleryImages: {
            third: {
                src: "/images/projects/grunver/grunver-2.webp",
                alt: "grunverGallery",
            },
            fourth: {
                src: "/images/projects/grunver/grunver-3.webp",
                alt: "grunverGallery",
            },
            fifth: {
                src: "/images/projects/grunver/grunver-5.webp",
                alt: "grunverGallery",
            },
            sixth: {
                src: "/images/projects/grunver/grunver-4.webp",
                alt: "grunverGallery",
            },
        },
    },
    termaria: {
        servicesList: ["Desarrollo a medida", "Sem", " "],
        externalLink: {
            text: "Visita Termaria",
            href: "https://termaria.es/",
        },
        mainImage: {
            src: "/images/projects/termaria/termaria-main.webp",
            alt: "termariaMain",
        },
        descriptionImage: {
            src: "/images/projects/termaria/termaria-1.webp",
            alt: "termariaDescription",
        },
        descriptionText: {
            title: "project.termaria.principal.title",
            title1: "project.termaria.project-description.title1",
            title2: "project.termaria.project-description.title2",
            title3: "project.termaria.project-description.title3",
            paragraph1: "project.termaria.project-description.paragraph1",
            paragraph2: "project.termaria.project-description.paragraph2",
        },
        moreInfoLinks: {
            group1: [
                { name: "Web Corporativa", url: "web-corporativa" },
                { name: "Ecommerce", url: "ecommerce" },
                { name: "Sem", url: "sem" },
            ],
        },
        galleryImages: {
            third: {
                src: "/images/projects/termaria/termaria-2.webp",
                alt: "termariaGallery",
            },
            fourth: {
                src: "/images/projects/termaria/termaria-3.webp",
                alt: "termariaGallery",
            },
            fifth: {
                src: "/images/projects/termaria/termaria-5.webp",
                alt: "termariaGallery",
            },
            sixth: {
                src: "/images/projects/termaria/termaria-4.webp",
                alt: "termariaGallery",
            },
        },
    },
    projectsSlider: {
        titles: {
            ecommerce: ["Nuestros ", "ecommerce", " destacados"],
            corporativeWeb: ["Nuestras ", "webs", " destacadas"],
            woocommerce: ["Nuestros ", "Woocommerce", " destacados"],
            magento: ["Nuestros ", "Magento", " destacados"],
        },
        imagesEcommerce: [
            [
                "/images/projects/sliders/castro.webp",
                "Proyecto Castrofarmacias",
                "Castro Parafarmacia",
                "/proyecto/castro",
            ],
            [
                "/images/projects/sliders/termaria.webp",
                "Proyecto Termaria",
                "Termaria",
                "/proyecto/termaria",
            ],
            [
                "/images/projects/sliders/galicine.webp",
                "Proyecto Galicine",
                "Galicine",
                "/proyecto/galicine",
            ],
        ],
        imagesWoocommerce: [
            [
                "/images/projects/sliders/cetarea-burela.webp",
                "Proyecto Cetárea Burela",
                "Cetárea Burela",
                "/proyecto/cetarea-burela",
            ],
            [
                "/images/projects/sliders/jenifer-fuentes.webp",
                "Proyecto Jenifer Fuentes",
                "Jenifer Fuentes",
                "/proyecto/jenifer-fuentes",
            ]
        ],
        imagesCorporativeWeb:[
            [
                "/images/projects/sliders/gadis.webp",
                "Proyecto Gadis",
                "Gadis",
                "/proyecto/gadis",
            ],
            [
                "/images/projects/sliders/cubicaje.webp",
                "Proyecto C4bicaje",
                "C4bicaje",
                "/proyecto/cubicaje",
            ],
            [
                "/images/projects/sliders/grunver.webp",
                "Proyecto Grunver",
                "Grunver",
                "/proyecto/grunver",
            ],
            [
                "/images/projects/sliders/autoradio.webp",
                "Proyecto Autoradio",
                "Autoradio",
                "/proyecto/autoradio",
            ]
        ],
        imagesMagento:[
            [
                "/images/projects/sliders/bouret.webp",
                "Proyecto Bouret",
                "Boüret",
                "/proyecto/bouret"
            ],
            [
                "/images/projects/sliders/regolodos.webp",
                "Proyecto Regolodos",
                "Regolodos",
                "/proyecto/regolodos"
            ],
            [
                "/images/projects/sliders/castro.webp",
                "Proyecto Castrofarmacias",
                "Castro Parafarmacia",
                "/proyecto/castro"
            ],
            [
                "/images/projects/sliders/batilas.webp",
                "Proyecto Batilas",
                "Batilas",
                "/proyecto/batilas"
            ]
        ]
    },
};

const featured = (state = initialState.featured, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const cubicaje = (state = initialState.cubicaje, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const bouret =  (state = initialState.bouret, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const regolodos = (state = initialState.regolodos, action) => {
    switch (action.type) {
        default:
            return state;
    }
};

const castro = (state = initialState.castro, action) => {
    switch (action.type) {
        default:
            return state;
    }
};

const cetareaBurela = (state = initialState.cetareaBurela, action) => {
    switch (action.type) {
        default:
            return state;
    }
};

const galicine = (state = initialState.galicine, action) => {
    switch (action.type) {
        default:
            return state;
    }
};

const autoradio = (state = initialState.autoradio, action) => {
    switch (action.type) {
        default:
            return state;
    }
};

const batilas = (state = initialState.batilas, action) => {
    switch (action.type) {
        default:
            return state;
    }
};

const gadis = (state = initialState.gadis, action) => {
    switch (action.type) {
        default:
            return state;
    }
};

const jenifer = (state = initialState.jenifer, action) => {
    switch (action.type) {
        default:
            return state;
    }
};

const grunver = (state = initialState.grunver, action) => {
    switch (action.type) {
        default:
            return state;
    }
};

const termaria = (state = initialState.termaria, action) => {
    switch (action.type) {
        default:
            return state;
    }
};

const projectsSlider =  (state = initialState.projectsSlider, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const projectsSuperiorRow = (state = initialState.projectsPage.projectsSuperiorRow, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const projectsDoubleRow = (state = initialState.projectsPage.projectsDoubleRow, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const normalProjects = (state = initialState.projectsPage.normalProjects, action) => {
  switch (action.type) {
    default:
      return state;
  }
}

const projectsMoreInfoLinks = (state = initialState.projectsPage.moreInfoLinks, action) => {
  switch (action.type) {
    default:
      return state;
  }
}

const reducer = combineReducers({
    featured,
    cubicaje,
    bouret,
    regolodos,
    castro,
    cetareaBurela                  ,
    batilas,
    gadis,
    jenifer,
    galicine,
    batilas,
    autoradio,
    grunver,
    termaria,
    projectsSlider,
    projectsSuperiorRow,
    projectsDoubleRow,
    normalProjects,
    projectsMoreInfoLinks,
});

export default reducer;
