export default {
  'project.common.ErrorDialog.title': 'Error',
  'project.global.exceptions.NetworkError': 'Network error',
  'project.global.buttons.close': 'Close',

  'project.global.title.prefix': 'Pululart |',
  'project.global.title.hiddentab': 'Te echo de menos :(',

  'project.homepage.principal.title1': 'Somos el',
  'project.homepage.principal.title2': 'impulso',
  'project.homepage.principal.title3': 'digital',
  'project.homepage.principal.title4': 'que necesitas',
  'project.homepage.principal.p1': 'Descubre todo lo que podemos',
  'project.homepage.principal.p2': 'hacer por tu negocio',
  'project.homepage.video.title1': 'Agencia de',
  'project.homepage.video.title2': 'diseño web',
  'project.homepage.video.title3': 'y marketing digital en A Coruña',
  'project.homepage.video.p1': 'Somos especialistas en trasladar tu marca, producto o negocio a internet. No solo diseñamos tu ecommerce o tu web, también nos aseguramos de que genere ingresos para ti.',
  'project.homepage.video.button': '¿Hablamos?',
  'project.homepage.video.button.alternative': '¡Cuéntanos tu caso!',
  'project.homepage.video.img.alt': 'Video Introducción',
  'project.homepage.services.title': 'Servicios',
  'project.homepage.services.ecommerce.title': 'ECommerce',
  'project.homepage.services.ecommerce.text': 'Creamos tu tienda online con las especificaciones que nos indiques. Te ayudamos a vender más y mejor.',
  'project.homepage.services.web.title': 'Web',
  'project.homepage.services.web.text': 'Desarrollamos tu web corporativa para que refuerces la imagen de tu marca y generes confianza en tus clientes.',
  'project.homepage.services.marketing.title': 'Marketing',
  'project.homepage.services.marketing.text': 'Diseñamos estrategias de marketing completamente adaptadas a tus recursos y a tus objetivos. SEO. SEM, Social Ads… ¡Tu eliges!',
  'project.homepage.services.consult.title': 'Consultoría',
  'project.homepage.services.consult.text': 'Te asesoramos en decisiones estratégicas',
  'project.homepage.services.moreinfo': 'más info',
  'project.homepage.counters.projects': 'Proyectos realizados',
  'project.homepage.counters.customers': 'Clientes sastifechos',
  'project.homepage.counters.pululeños': 'Años de experiencia',
  'project.homepage.projects.title': 'Y para muestra...',
  'project.homepage.projects.p1': 'Te enseñamos algunos de nuestros proyectos más destacados, así puedes hacerte una idea de todo lo que podemos hacer por ti.',
  'project.homepage.projects.p2a': 'Si te ha sabido a poco, puedes echarle un ojo a',
  'project.homepage.projects.p2b': 'todos nuestros proyectos',
  'project.homepage.projects.p2c': ';)',
  'project.homepage.projects.link.title': 'ver proyecto',
  'project.homepage.brands.title1': 'Ya',
  'project.homepage.brands.title2': 'confían',
  'project.homepage.brands.title3': 'en nosotros',
  'project.homepage.contact.title': 'Estamos deseando trabajar contigo...',
  'project.homepage.contact.button': '¿Empezamos?',
  'project.homepage.contact.button.alternative': '¡Vamos allá!',
  'project.homepage.contact.p2': 'Si quieres más información te invitamos a ver',
  'project.homepage.contact.a1': 'quiénes somos',
  'project.homepage.contact.p3': 'o descubrir más sobre nuevos servicios de ',
  'project.homepage.contact.a2': 'marketing',
  'project.homepage.contact.a3': 'web',
  'project.homepage.contact.a4': ' ecommerce',
  'project.homepage.history.text1': 'Más de 20 años a tu servicio',
  'project.homepage.history.text2': 'Llevamos desde 2002 desarrollando proyectos digitales. Tu satisfacción como cliente es nuestra prioridad. Escuchamos tus necesidades y planteamos una solución a medida.',

  'project.cubicaje.principal.title': 'C4BICAJE',
  'project.cubicaje.project-description.title1': 'Una imagen web diseñada para ',
  'project.cubicaje.project-description.title2': 'perdurar',
  'project.cubicaje.project-description.paragraph1': 'Con 5 almacenes estratégicamente situados en Europa y Asia, la web corporativa de Cubicaje debía reflejar su relevancia a nivel internacional, así como su consolidada experiencia en el sector ¡Un reto que en Pululart estuvimos encantados de asumir!',
  'project.cubicaje.project-description.paragraph2': 'El diseño de su web respeta las líneas corporativas de la marca, siendo limpio y atemporal. Detallamos sus servicios al tiempo que ponemos en alza los valores de la empresa: respeto al medio ambiente, transparencia y lealtad con los suyos y hacia los demás.',

  'project.bouret.principal.title': 'BOÜRET',
  'project.bouret.project-description.title1': 'Un desarrollo con la vista puesta en los',
  'project.bouret.project-description.title2': 'detalles',
  'project.bouret.project-description.title3': '.',
  'project.bouret.project-description.paragraph1': 'Boüret es una firma de moda independiente con un <strong>lenguaje propio</strong>, en la que la sofisticación y la alta cultura se dan la mano para crear piezas únicas. Por eso su tienda online fue un reto que el equipo de Pululart asumió con muchísima ilusión.',
  'project.bouret.project-description.paragraph2': 'En Boüret todos los detalles están pensados para transmitir la esencia de la marca mientras el usuario se sumerge en una experiencia de compra óptima.',

  'project.regolodos.principal.title': 'REGO LODOS',
  'project.regolodos.project-description.title1': 'Un ecommerce',
  'project.regolodos.project-description.title2': 'optimizado',
  'project.regolodos.project-description.title3': 'y super cute :)',
  'project.regolodos.project-description.paragraph1': 'Especialistas en el cuidado de la piel y medicina natural, en Rego Lodos destacan por  ese trato tan especial que le dan a cada uno de sus clientes ¡y su web no podía ser menos!',
  'project.regolodos.project-description.paragraph2': 'El reto de esta tienda era transmitir la personalidad y el trato amable de la farmacia física en un entorno digital. Así surge nuestro ecommerce más cute, cargado de buen rollo y con un proceso de compra ágil y súper cómodo.',

  'project.castro.principal.title': 'CASTRO FARMACIAS',
  'project.castro.project-description.title1': 'Un ecommerce con los',
  'project.castro.project-description.title2': 'usuarios',
  'project.castro.project-description.title3': 'en el centro de las decisiones.',
  'project.castro.project-description.paragraph1': 'Con más de 50 años de experiencia con sus farmacias en A Coruña, Farmacias Castro nos ha elegido para diseñar, desarrollar y promocionar su marca en internet.  Hemos llevado a cabo un ecommerce limpio, transparente y muy intuitivo con un objetivo muy definido: que quien visita la tienda online de Castro Farmacias se sienta como en la propia farmacia.',

  'project.cetareaBurela.principal.title': 'CETÁREA BURELA',
  'project.cetareaBurela.project-description.title1': 'Un',
  'project.cetareaBurela.project-description.title2': 'woocommerce fresco',
  'project.cetareaBurela.project-description.title3': 'y con mucho sabor',
  'project.cetareaBurela.project-description.paragraph1': 'Cetárea Burela ofrece una gran variedad de pescado y marisco fresco del Mar Cantábrico. Gracias a su increíble calidad, este ecommerce se ha ganado la confianza de clientes  de todo tipo, tanto del sector hostelero como particulares.',
  'project.cetareaBurela.project-description.paragraph2': 'En Pululart no solo rediseñamos su web para ajustarla a las tendencias actuales, también hemos trabajado en el flujo de navegación para mejorar la experiencia de usuario y conseguir que comprar los productos más frescos sea super fácil e intuitivo.',
 
  'project.galicine.principal.title': 'GALICINE',
  'project.galicine.project-description.title1': 'Mucha',
  'project.galicine.project-description.title2': 'personalidad',
  'project.galicine.project-description.title3': 'y una gran usabilidad en un mismo lugar.',
  'project.galicine.project-description.paragraph1': 'Galicine es una ventana a un mundo de emociones. El mejor cine, los últimos estrenos y las películas más taquilleras tienen su punto de encuentro en esta página web cargada de personalidad.',
  'project.galicine.project-description.paragraph2': 'Nuestros amigos de Galicine son sinónimo de espectáculo, y su página no podía ser menos. El equipo de Pululart ha replanteado su imagen corporativa y su página web para promover una experiencia de compra inigualable, con la información de tus películas favoritas a golpe de clic.',

  'project.batilas.principal.title': 'BATILAS',
  'project.batilas.project-description.title1': 'Mucho cariño y',
  'project.batilas.project-description.title2': 'comodidad',
  'project.batilas.project-description.title3': 'en un ecommerce de calzado infantil',
  'project.batilas.project-description.paragraph1': 'Batilas es un fabricante español de calzado infantil con más de 20 años de experiencia en el sector. El amor y cuidado con el que fabrican sus zapatos hacen que sus productos sean muy especiales.',
  'project.batilas.project-description.paragraph2': 'Para nosotros ha sido un placer trabajar con el equipo de Autoradio, siempre dispuestos a dar lo mejor de sí. El resultado es una web corporativa con  mucha personalidad, en la que se ve reflejada su sólida historia y que, al mismo tiempo, abre paso a un futuro igual de prometedor.',

  'project.autoradio.principal.title': 'AUTORADIO',
  'project.autoradio.project-description.title1': 'Acortando',
  'project.autoradio.project-description.title2': 'distancias',
  'project.autoradio.project-description.title3': 'con una nueva web moderna y funcional.',
  'project.autoradio.project-description.paragraph1': 'Autoradio es una empresa de transporte nacida en Galicia hace ya más de 35 años. Su amplia experiencia la ha convertido en una de las empresas referentes del sector con un abanico de servicios que les permite llegar cada vez más lejos.',
  'project.autoradio.project-description.paragraph2': 'Ese miso cariño ha puesto nuestro equipo en el desarrollo de una tienda online con una gran usabilidad,  para que los usuarios puedan comprar en Batilas desde cualquier dispositivo con facilidad y la garantía de estar comprando un producto único y de calidad.',

  'project.gadis.principal.title': 'GADIS',
  'project.gadis.project-description.title1': 'Una propuesta',
  'project.gadis.project-description.title2': 'multiservicios',
  'project.gadis.project-description.title3': 'para potenciar su presencia online',
  'project.gadis.project-description.paragraph1': 'Los supermercados Gadis son una referencia en Galicia y Castilla y León, cuentan con más de 200 establecimientos y miles de productos disponibles en cada uno de ellos.',
  'project.gadis.project-description.paragraph2': 'En Pululart trabajamos mano a mano con el equipo de Gadis para ayudarlos a crecer cuidando siempre su presencia en Internet. Con ellos nutrimos con mucho mimo sus fichas de Google My Business, planteamos campañas de Ads, gestionamos y desarrollamos su página corporativa y creamos proyectos tan chulos como Alimentes Digital, entre otras muchas iniciativas.',

  'project.jenifer.principal.title': 'JENIFER FUENTES',
  'project.jenifer.project-description.title1': ' ',
  'project.jenifer.project-description.title2': 'Profesionalidad',
  'project.jenifer.project-description.title3': 'y dinamismo en un Woocommerce super',
  'project.jenifer.project-description.title4': ' in',
  'project.jenifer.project-description.paragraph1': 'Centrados en la elegancia y usabilidad, nuestro equipo trabajó con Jenifer para desarrollar una web  que reflejara su pasión por el arte del maquillaje. En ella los usuarios pueden explorar los distintos servicios que ofrece, desde maquillaje para bodas hasta cursos personalizados. Además, integra una tienda online con los mejores productos.',
  'project.jenifer.project-description.paragraph2': 'Nuestro objetivo era crear una página cautivadora donde los visitantes se sientan inspirados por la belleza del maquillaje. Estamos orgullosos de haber colaborado con Jenifer Fuentes en este proyecto.',

  'project.grunver.principal.title': 'GRUNVER',
  'project.grunver.project-description.title1': 'Innovación y',
  'project.grunver.project-description.title2': 'medioambiente',
  'project.grunver.project-description.title3': 'como estrategia de acción',
  'project.grunver.project-description.paragraph1': 'El objetivo de Grunver Sostenibilidad es prestar asesoria y apoyo a empresas y organizaciones en el campo del medioambiente. Especializados en la evaluación del impacto ambiental, su pasión y compromiso los define.',
  'project.grunver.project-description.paragraph2': 'Su página web, navegable en tres idiomas, es el reflejo de los principios de esta empresa, en la que están representados tanto su filosofía como el trabajo diario que realizan, todo ello con un diseño innovador y una imagen fresca y limpia.',

  'project.termaria.principal.title': 'TERMARIA',
  'project.termaria.project-description.title1': 'Una web para generar ',
  'project.termaria.project-description.title2': 'impacto',
  'project.termaria.project-description.title3': 'con mucho que contar.',
  'project.termaria.project-description.paragraph1': 'Termaria Casa del Agua representa el Ocio, la Relajación, el Deporte y la Salud en un solo centro y todo ello tenía que estar alcance de sus usuarios en muy pocos clics.',
  'project.termaria.project-description.paragraph2': 'Nuestro equipo se volcó en el rediseño de esta página para conseguir una línea visual única acompañada de una experiencia de usuario de 10.  Una página desde la que poder ver horarios, instalaciones, comprar entradas y mucho más.',

  'generic.welcome.services': '¿HABLAMOS?',
  'generic.welcome.secondary.services': '¡PODEMOS AYUDARTE!',

  'project.service.moreinfo': 'más info',

  'project.service.ecommerce.main.title1': 'Agencia especializada en diseño de ecommerce',
  'project.service.ecommerce.main.paragraph1': '¡Llega a miles de personas e incrementa tus ventas online! Abre tu propia tienda en internet y plántale cara a tu competencia.',
  'project.service.ecommerce.main.paragraph2': 'Adaptamos el ecommerce a todas tus necesidades para que sea totalmente operativo y funcional. Gestionamos el proyecto de principio a fin para que no tengas que preocuparte.',
  'project.service.ecommerce.main.title2': '¿Qué vas a conseguir con un ecommerce?',
  'project.service.ecommerce.main.list1': 'Vender más, mucho más.',
  'project.service.ecommerce.main.list2': 'Incrementar la visibilidad y el conocimiento de tu marca.',
  'project.service.ecommerce.main.list3': 'Reducir tu costo de venta.',
  'project.service.ecommerce.main.list4': 'Generar ventas a cualquier hora desde cualquier lugar.',
  'project.service.ecommerce.main.list5': 'Aumentar el número de contactos de tu base de datos.',
  'project.service.ecommerce.knowMore.paragraph1': '¿QUIERES SABER MÁS?',
  'project.service.ecommerce.knowMore.paragraph2': 'Escríbenos y te lo contamos todo',
  'project.service.ecommerce.knowMore.paragraph3': '¡Sí! quiero más info',
  'project.service.ecommerce.knowMore.title1': '¿Para qué tipos de negocio realizamos el diseño de tienda online? ',
  'project.service.ecommerce.knowMore.list1': 'PEQUEÑOS NEGOCIOS',
  'project.service.ecommerce.knowMore.list2': 'Diseñamos tiendas online para panaderías, perfumerías, verdulerías y, en realidad, cualquier otro tipo de establecimiento que desee vender en internet.',
  'project.service.ecommerce.knowMore.list3': 'EMPRESAS MEDIANAS',
  'project.service.ecommerce.knowMore.list4': 'Montamos ecommerce para empresas medianas que desean evolucionar y ofrecer sus productos online. Ayudamos a su expansión y crecimiento.',
  'project.service.ecommerce.knowMore.list5': 'GRANDES EMPRESAS',
  'project.service.ecommerce.knowMore.list6': 'Desarrollamos tiendas online para grandes empresas nacionales e internacionales que necesitan un desarrollo a medida y altamente personalizable.',
  'project.service.ecommerce.extraInfo.title1': '¿Qué tipos de ',
  'project.service.ecommerce.extraInfo.title2': 'CMS',
  'project.service.ecommerce.extraInfo.title3': ' utilizamos para ecommerce?',
  'project.service.ecommerce.extraInfo.subTitle1': 'tienda online con magento',
  'project.service.ecommerce.extraInfo.p1': 'Fácil de instalar y de usar. Muestra mucha información sobre los productos y es capaz de gestionar un catálogo amplio. Se integra fácil con otras herramientas de gestión.',
  'project.service.ecommerce.extraInfo.subTitle2': 'tienda online con woocommerce',
  'project.service.ecommerce.extraInfo.p2': 'Intuitivo, visual y personalizable. Alta escalabilidad y costes de tienda bajos. Tiene infinidad de plugins disponibles y se integra fácil con otros softwares.',
  'project.service.ecommerce.youNeed.underlineTitle1': 'Necesitas',
  'project.service.ecommerce.youNeed.title1': ' una agencia especialista en diseño de ecommerce si...',
  'project.service.ecommerce.youNeed.list1': 'No estás en internet. El presente y futuro del comercio está en internet.',
  'project.service.ecommerce.youNeed.list2': 'Tu negocio es poco conocido.',
  'project.service.ecommerce.youNeed.list3': 'Vendes productos o servicios en un sector muy competitivo.',
  'project.service.ecommerce.youNeed.list4': 'Buscas incrementar tus ventas.',
  'project.service.ecommerce.youNeed.list5': 'Quieres llegar a nuevos públicos.',
  'project.service.ecommerce.whyChooseUs.title': '¿Por qué confiarnos el desarrollo de tu ecommerce?',
  'project.service.ecommerce.whyChooseUs.paragraph1': 'Porque somos expertos en ello. Llevamos más de 20 años dedicados al desarrollo de tiendas online, con un equipo de expertos al mando y una cartera de clientes satisfechos que nos avala.',
  'project.service.ecommerce.whyChooseUs.paragraph2': 'Sea lo que sea que estás buscando, podemos hacerlo, porque seguramente ya lo hemos hecho antes. Y si no, investigamos cómo.',
  'project.service.ecommerce.whyChooseUs.list1': 'Cientos de clientes ya han desarrollado su ecommerce con nosotros.',
  'project.service.ecommerce.whyChooseUs.list2': 'Tus necesidades son nuestras prioridad. Nos adaptamos a tus preferencias.',
  'project.service.ecommerce.whyChooseUs.list3': 'Conocemos todos los CMS al dedillo.',
  'project.service.ecommerce.whyChooseUs.list4': 'Nuestros especialistas resolverán cualquier duda que tengas y te asesorarán en lo que necesites.',
  'project.service.ecommerce.whyChooseUs.list5': 'Sacamos el máximo rendimiento a tu presupuesto.',
  'project.service.ecommerce.whyChooseUs.list6': 'Vas a vender más y mejor.',

  'project.service.corporativeWeb.main.title1': 'Agencia especializada en diseño de webs corporativas',
  'project.service.corporativeWeb.main.paragraph1': '¡Haz que te conozcan y aumenta tu presencia y tu visibilidad online! Construye tu propia web a medida y muestra tu negocio y tu marca al mundo.',
  'project.service.corporativeWeb.main.paragraph2': 'Nos sentamos contigo para conocer tus necesidades y las trasladamos a un proyecto adaptado que se ajuste a tu presupuesto y a tus expectativas.',
  'project.service.corporativeWeb.main.title2': '¿Qué vas a conseguir con un site corporativo?',
  'project.service.corporativeWeb.main.list1': 'Generar visibilidad online sobre la empresa y lo que ofreces.',
  'project.service.corporativeWeb.main.list2': 'Mejorar la asistencia y el servicio que aportas a tus clientes.',
  'project.service.corporativeWeb.main.list3': 'Aumentar las probabilidades de venta de productos o servicios.',
  'project.service.corporativeWeb.main.list4': 'Posicionarte como un experto en tu sector o nicho.',
  'project.service.corporativeWeb.main.list5': 'Incrementar la captación de contactos de clientes potenciales.',
  'project.service.corporativeWeb.main.list6': 'Generar un mayor recuerdo de marca gracias a la experiencia de usuario.',
  'project.service.corporativeWeb.knowMore.paragraph1': '¿QUIERES SABER MÁS?',
  'project.service.corporativeWeb.knowMore.paragraph2': 'Escríbenos y te lo contamos todo',
  'project.service.corporativeWeb.knowMore.paragraph3': '¡Sí! quiero más info',
  'project.service.corporativeWeb.knowMore.title1': '¿Para qué tipos de negocio diseñamos webs corporativas?',
  'project.service.corporativeWeb.knowMore.list1': 'PEQUEÑOS NEGOCIOS',
  'project.service.corporativeWeb.knowMore.list2': 'Diseñamos webs corporativas para panaderías, perfumerías, verdulerías y, en realidad, cualquier otro tipo de establecimiento que desee tener presencia en internet.',
  'project.service.corporativeWeb.knowMore.list3': 'EMPRESAS MEDIANAS',
  'project.service.corporativeWeb.knowMore.list4': 'Desarrollamos sites corporativos para empresas medianas que buscan crecer y ganar visibilidad online.',
  'project.service.corporativeWeb.knowMore.list5': 'GRANDES EMPRESAS',
  'project.service.corporativeWeb.knowMore.list6': 'Realizamos webs corporativas para grandes empresas nacionales e internacionales que necesitan un desarrollo a medida y altamente personalizable.',
  'project.service.corporativeWeb.youNeed.underlineTitle1': 'Necesitas',
  'project.service.corporativeWeb.youNeed.title1': ' una agencia especialista en web corporativas si...',
  'project.service.corporativeWeb.youNeed.list1': 'No estás en internet. A día de hoy, es IMPRESCINDIBLE estar en internet.',
  'project.service.corporativeWeb.youNeed.list2': 'Tus productos o servicios no son conocidos o tienes dificultades para venderlos.',
  'project.service.corporativeWeb.youNeed.list3': 'Buscas que nuevas personas descubran tu marca o profundicen en ella.',
  'project.service.corporativeWeb.youNeed.list4': 'No consigues llegar a tu público objetivo.',
  'project.service.corporativeWeb.youNeed.list5': 'Quieres aportar información valiosa sobre tu empresa.',
  'project.service.corporativeWeb.youNeed.list6': 'La competencia en tu sector es elevada y/o agresiva.',
  'project.service.corporativeWeb.whyChooseUs.title': '¿Por qué confiarnos el desarrollo de tu web corporativa?',
  'project.service.corporativeWeb.whyChooseUs.paragraph1': 'Porque llevamos más de 20 años diseñando webs. Contamos con un equipo altamente especializado y con experiencia, y con una cartera de clientes satisfechos que avala nuestra trayectoria.',
  'project.service.corporativeWeb.whyChooseUs.paragraph2': 'Conocemos todos los detalles y trucos del diseño y del desarrollo web y los aplicamos para que tengas la web que te mereces.',
  "project.service.corporativeWeb.whyChooseUs.list1": 'Tú eres lo primero. Adaptamos nuestros conocimientos a tus necesidades',
  "project.service.corporativeWeb.whyChooseUs.list2": 'Te asesoramos con total honestidad durante todo el proceso.',
  "project.service.corporativeWeb.whyChooseUs.list3": 'Hacemos que tu inversión te resulte rentable.',
  "project.service.corporativeWeb.whyChooseUs.list4": 'Buscamos siempre lo mejor para ti.',

  'project.service.marketing.main.title1': 'Agencia de marketing digital especializada',
  'project.service.marketing.main.paragraph1': '¡Incrementa la visibilidad y las ventas de tu empresa! Alcanza nuevos públicos y conviértete en la primera opción de compra.',
  'project.service.marketing.main.paragraph2': 'Diseñamos estrategias a medida y optimizamos tu presupuesto para que consigas el máximo retorno. Nos adaptamos a tus necesidades y trabajamos para cumplir tus objetivos.',
  'project.service.marketing.main.title2': '¿Qué conseguirás con nuestra agencia de marketing digital en A Coruña?',
  'project.service.marketing.main.list1': 'Incrementar las ventas de tu negocio.',
  'project.service.marketing.main.list2': 'Llegar a nuevas audiencias.',
  'project.service.marketing.main.list3': 'Impactar a tu público objetivo en el momento adecuado.',
  'project.service.marketing.main.list4': 'Rentabilizar enormemente tu inversión publicitaria.',
  'project.service.marketing.main.list5': 'Fortalecer la imagen de tu marca.',
  'project.service.marketing.knowMore.paragraph1': '¿QUIERES SABER MÁS?',
  'project.service.marketing.knowMore.paragraph2': 'Escríbenos y te lo contamos todo',
  'project.service.marketing.knowMore.paragraph3': '¡Sí! quiero más info',
  'project.service.marketing.knowMore.title1': '¿Para qué tipo de empresas hacemos marketing online?',
  'project.service.marketing.knowMore.list1': 'PEQUEÑOS NEGOCIOS',
  'project.service.marketing.knowMore.list2': 'Llevamos a cabo estrategias de marketing para pequeños negocios que desean incrementar su visibilidad online.',
  'project.service.marketing.knowMore.list3': 'EMPRESAS MEDIANAS',
  'project.service.marketing.knowMore.list4': 'Prestamos servicios de marketing digital a empresas medianas que buscan incrementar las ventas en sus ecommerce.',
  'project.service.marketing.knowMore.list5': 'GRANDES EMPRESAS',
  'project.service.marketing.knowMore.list6': 'Realizamos estrategias de SEO para grandes empresas nacionales e internacionales que necesitan conseguir objetivos ambiciosos y concretos.',
  'project.service.marketing.youNeed.underlineTitle1': 'Necesitas',
  'project.service.marketing.youNeed.title1': ' una agencia de marketing digital si...',
  'project.service.marketing.youNeed.list1': 'Necesitas mejorar la visibilidad de tu marca.',
  'project.service.marketing.youNeed.list2': 'Necesitas potenciar la imagen de tu negocio.',
  'project.service.marketing.youNeed.list3': 'Quieres llegar a personas que todavía no te conocen.',
  'project.service.marketing.youNeed.list4': 'Quieres aumentar el tráfico a tu web.',
  'project.service.marketing.youNeed.list5': 'Buscas incrementar la tasa de conversión de tu ecommerce.',
  'project.service.marketing.youNeed.list6': 'Buscas convertirte en una de las principales opciones de compra en tu sector.',
  'project.service.marketing.whyChooseUs.title': '¿Por qué somos la agencia de marketing digital que necesitas?',
  'project.service.marketing.whyChooseUs.paragraph1': 'Llevamos más de 20 años desarrollando proyectos digitales. Somos expertos en marketing digital y en realizar campañas que generan resultados.',
  'project.service.marketing.whyChooseUs.paragraph2': 'Somos un equipo de gente que siente pasión por lo que hace. La honestidad es lo nuestro y no te prometemos nada que no vayamos a conseguir.',
  "project.service.marketing.whyChooseUs.list1": 'Clientes satisfechos que llevan 10+ años con nosotros.',
  "project.service.marketing.whyChooseUs.list2": 'Trabajamos diariamente para conseguir que tus objetivos se cumplan.',
  "project.service.marketing.whyChooseUs.list3": 'Escuchamos tus necesidades y diseñamos estrategias adaptadas a tu empresa.',
  "project.service.marketing.whyChooseUs.list4": 'Sacamos el máximo rendimiento a tu presupuesto.',
  "project.service.marketing.whyChooseUs.list5": 'Hacemos que vendas más y mejor.',
  "project.service.marketing.whyChooseUs.underlinedText1": 'Ya hacen ',
  "project.service.marketing.whyChooseUs.underlinedText2": 'SEO',
  "project.service.marketing.whyChooseUs.underlinedText3": ' con nosotros',

  'project.service.seo.main.title1': 'Agencia especializada en posicionamiento SEO',
  'project.service.seo.main.paragraph1': '¡Posiciona tu web entre los primeros resultados de Google y otros motores de búsqueda y haz crecer tu negocio! ¿Cómo? Con una estrategia SEO diseñada a medida.',
  'project.service.seo.main.paragraph2': 'Optimizamos tu web o ecommerce para adaptarlo a los estándares de los buscadores. Detectamos oportunidades y las aprovechamos para llevar tu negocio a lo más alto.',
  'project.service.seo.main.title2': '¿Qué conseguirás con una estrategia SEO?',
  'project.service.seo.main.list1': 'Aumentar la visibilidad de tu marca. ¡Haz que te encuentren!',
  'project.service.seo.main.list2': 'Incrementar el tráfico orgánico a tu web y conquistar a tu público con buenos contenidos.',
  'project.service.seo.main.list3': 'Potenciar las conversiones o ventas de tu site o ecommerce.',
  'project.service.seo.main.list4': 'Mejorar la experiencia de los usuarios de tu web.',
  'project.service.seo.main.list5': 'Rentabilizar tu inversión a largo plazo.',
  'project.service.seo.knowMore.paragraph1': '¿QUIERES SABER MÁS?',
  'project.service.seo.knowMore.paragraph2': 'Escríbenos y te lo contamos todo',
  'project.service.seo.knowMore.paragraph3': '¡Sí! quiero más info',
  'project.service.seo.knowMore.title1': '¿Para qué tipo de empresas hacemos SEO?',
  'project.service.seo.knowMore.list1': 'PEQUEÑOS NEGOCIOS',
  'project.service.seo.knowMore.list2': 'Llevamos a cabo estrategias de SEO para pequeños negocios que quieren posicionarse por búsquedas concretas.',
  'project.service.seo.knowMore.list3': 'EMPRESAS MEDIANAS',
  'project.service.seo.knowMore.list4': 'Prestamos servicios de posicionamiento web a empresas medianas que buscan aumentar su visibilidad en los motores de búsqueda.',
  'project.service.seo.knowMore.list5': 'GRANDES EMPRESAS',
  'project.service.seo.knowMore.list6': 'Realizamos estrategias de SEO para grandes empresas nacionales e internacionales que desean incrementar notablemente las visitas a la web y las conversiones.',
  'project.service.seo.youNeed.underlineTitle1': 'Necesitas',
  'project.service.seo.youNeed.title1': ' una agencia SEO si...',
  'project.service.seo.youNeed.list1': 'Tienes una web y quieres incrementar las conversiones.',
  'project.service.seo.youNeed.list2': 'Tienes un ecommerce y buscas aumentar las ventas.',
  'project.service.seo.youNeed.list3': 'Cuentas con una marca que todavía no es muy conocida.',
  'project.service.seo.youNeed.list4': 'Estás en un sector altamente competitivo.',
  'project.service.seo.youNeed.list5': 'Quieres aumentar el tráfico a tu web sin hacer una fuerte inversión.',
  'project.service.seo.youNeed.list6': 'Buscas llegar a nuevos públicos.',
  'project.service.seo.youNeed.list7': 'Las páginas de tu web no se muestran en Google.',
  'project.service.seo.whyChooseUs.title':'¿Por qué mejorar tu posicionamiento web con nosotros?',
  'project.service.seo.whyChooseUs.paragraph1':'Llevamos 20 años en esto. Nuestra experiencia nos avala. Nuestros casos de éxito también.',
  'project.service.seo.whyChooseUs.paragraph2':'Conseguimos resultados excelentes y no te prometemos nada que no vayamos a conseguir. Somos un equipo de gente honesta que trabaja día a día para que consigas tus objetivos.',
  'project.service.seo.whyChooseUs.list1':'Clientes satisfechos que llevan 10+ años con nosotros.',
  'project.service.seo.whyChooseUs.list2':'Buenos resultados a medio y largo plazo sin trampa ni cartón.',
  'project.service.seo.whyChooseUs.list3':'Nos adherimos a los estándares de Google: te libramos de penalizaciones provocadas por malas prácticas SEO.',
  'project.service.seo.whyChooseUs.list4':'Te escuchamos. Adaptamos nuestros conocimientos a tus necesidades.',
  "project.service.seo.whyChooseUs.underlinedText1": 'Ya hacen ',
  "project.service.seo.whyChooseUs.underlinedText2": 'SEO',
  "project.service.seo.whyChooseUs.underlinedText3": ' con nosotros',

  'project.service.gmb.main.title1': 'Agencia especializada Google My Business',
  'project.service.gmb.main.paragraph1': 'Conviértete en el negocio número uno en tu zona y en tu nicho. Atrae, capta y fideliza clientes. <strong>Haz que te encuentren</strong> y te elijan. ¡Apuesta por Google My Business!',
  'project.service.gmb.main.paragraph2': 'Nos encargamos de todo el proceso. Mantenemos, gestionamos y optimizamos tu ficha en Google. Cuidamos tu reputación y potenciamos tus ventas.',
  'project.service.gmb.main.title2': '¿Qué conseguirás con una ficha de Google optimizada?',
  'project.service.gmb.main.list1': 'Aumentar la visibilidad de tus productos / servicios. ¡Vende más y mejor!',
  'project.service.gmb.main.list2': 'Mejorar las posiciones orgánicas en Google para búsquedas locales.',
  'project.service.gmb.main.list3': 'Incrementar la reputación de tu marca.',
  'project.service.gmb.main.list4': 'Geolocalizar tu negocio en tu zona de interés.',
  'project.service.gmb.main.list5': 'Interactuar con tus clientes de forma directa.',
  'project.service.gmb.knowMore.paragraph1': '¿QUIERES SABER MÁS?',
  'project.service.gmb.knowMore.paragraph2': 'Escríbenos y te lo contamos todo',
  'project.service.gmb.knowMore.paragraph3': '¡Sí! quiero más info',
  'project.service.gmb.knowMore.title1': '¿Con qué tipo de negocios trabajamos Google My Business?',
  'project.service.gmb.knowMore.list1': 'PEQUEÑOS NEGOCIOS',
  'project.service.gmb.knowMore.list2': 'Gestionamos fichas de Google para pequeños negocios que buscan convertirse en la primera opción en su área de operaciones.',
  'project.service.gmb.knowMore.list3': 'EMPRESAS MEDIANAS',
  'project.service.gmb.knowMore.list4': 'Nos hacemos cargo de la cuenta de Google My Business de empresas medianas que quieren incrementar su influencia en ciertas zonas.',
  'project.service.gmb.knowMore.list5': 'GRANDES EMPRESAS',
  'project.service.gmb.knowMore.list6': 'Nos ocupamos de las fichas de Google My Business de grandes empresas nacionales e internacionales que buscan tener una fuerte presencia local.',
  'project.service.gmb.youNeed.underlineTitle1': 'especializada',
  'project.service.gmb.youNeed.title1': 'Necesitas una agencia ',
  'project.service.gmb.youNeed.title2': ' en GMB si...',
  'project.service.gmb.youNeed.list1': 'Tu negocio tiene una fuerte dependencia local.',
  'project.service.gmb.youNeed.list2': 'No tienes puntuaciones o comentarios.',
  'project.service.gmb.youNeed.list3': 'Te mueves en un sector muy competitivo.',
  'project.service.gmb.youNeed.list4': 'Hay competidores locales bien posicionados.',
  'project.service.gmb.youNeed.list5': 'La puntuación y/o los comentarios de tu ficha son malos.',
  'project.service.gmb.youNeed.list6': 'No consigues llegar a tu público objetivo.',
  'project.service.gmb.whyChooseUs.title':'¿Por qué gestionar tu ficha de Google con nosotros?',
  'project.service.gmb.whyChooseUs.paragraph1':'Somos una agencia especializada en Google My Business. Conocemos todos sus detalles y tenemos experiencia gestionando cientos de fichas con una tasa de éxito muy elevada.',
  'project.service.gmb.whyChooseUs.paragraph2': 'Nuestros clientes nos avalan y nuestros resultados también.',
  'project.service.gmb.whyChooseUs.list1':'Agencia oficial Google Partner desde 2013.',
  'project.service.gmb.whyChooseUs.list2':'500+ fichas gestionadas.',
  'project.service.gmb.whyChooseUs.list3':'Estrategia ad hoc para cada negocio y ubicación.',
  'project.service.gmb.whyChooseUs.list4':'Te escuchamos. Adaptamos nuestros conocimientos a tus necesidades.',
  "project.service.gmb.whyChooseUs.underlinedText1": 'Ya hacen ',
  "project.service.gmb.whyChooseUs.underlinedText2": 'SEO',
  "project.service.gmb.whyChooseUs.underlinedText3": ' con nosotros',

  'project.service.digitalMarketing.main.title1': 'Agencia especializada en posicionamiento SEM',
  'project.service.digitalMarketing.main.paragraph1': '¡Apuesta por la publicidad digital e incrementa tus ventas y tu visibilidad! Sorprende a tus clientes potenciales en cualquier rincón de internet.',
  'project.service.digitalMarketing.main.paragraph2': 'Diseñamos, ejecutamos y gestionamos todas las campañas. Realizamos optimizaciones constantes para alcanzar el máximo rendimiento y aumentar tus conversiones.',
  'project.service.digitalMarketing.main.title2': '¿Qué conseguirás con una estrategia de posicionamiento sem o google ads?',
  'project.service.digitalMarketing.main.list1': 'Dar a conocer la marca a nuevos segmentos.',
  'project.service.digitalMarketing.main.list2': 'Aparecer como opción de compra en el momento indicado.',
  'project.service.digitalMarketing.main.list3': 'Impactar directamente a tu público objetivo.',
  'project.service.digitalMarketing.main.list4': 'Generar un número mayor de ventas online.',
  'project.service.digitalMarketing.main.list5': 'Mejorar el posicionamiento de marca en la mente del usuario.',
  'project.service.digitalMarketing.knowMore.paragraph1': '¿QUIERES SABER MÁS?',
  'project.service.digitalMarketing.knowMore.paragraph2': 'Escríbenos y te lo contamos todo',
  'project.service.digitalMarketing.knowMore.paragraph3': '¡Sí! quiero más info',
  'project.service.digitalMarketing.knowMore.title1': '¿Para qué tipo de empresas hacemos SEM?',
  'project.service.digitalMarketing.knowMore.list1': 'PEQUEÑOS NEGOCIOS',
  'project.service.digitalMarketing.knowMore.list2': 'Llevamos a cabo campañas de publicidad digital para pequeños negocios que buscan darse a conocer y aumentar sus ventas.',
  'project.service.digitalMarketing.knowMore.list3': 'EMPRESAS MEDIANAS',
  'project.service.digitalMarketing.knowMore.list4': 'Prestamos servicios publicitarios en internet a empresas medianas que quieren llegar a nuevos públicos e incrementar sus ingresos.',
  'project.service.digitalMarketing.knowMore.list5': 'GRANDES EMPRESAS',
  'project.service.digitalMarketing.knowMore.list6': 'Realizamos campañas publicitarias online para grandes empresas nacionales e internacionales que necesitan expandirse, hacer campañas potentes de branding y/o vender más.',
  'project.service.digitalMarketing.youNeed.underlineTitle1': 'Necesitas',
  'project.service.digitalMarketing.youNeed.title1': ' una agencia especialista en publicidad digital si...',
  'project.service.digitalMarketing.youNeed.list1': 'Tienes una marca nueva y todavía no te conocen.',
  'project.service.digitalMarketing.youNeed.list2': 'Buscas impulsar el branding o rebranding de tu empresa.',
  'project.service.digitalMarketing.youNeed.list3': 'Quieres aumentar tus conversiones.',
  'project.service.digitalMarketing.youNeed.list4': 'Quieres aparecer como resultado para ciertas búsquedas en Google.',
  'project.service.digitalMarketing.youNeed.list5': 'Vendes tus productos o servicios en un sector muy competitivo.',
  'project.service.digitalMarketing.youNeed.list6': 'Buscas impactar directamente a tu público objetivo.',
  'project.service.digitalMarketing.whyChooseUs.title':'¿Por qué elegirnos para campañas publicitarias en internet?',
  'project.service.digitalMarketing.whyChooseUs.paragraph1':'Tenemos más de 20 años de experiencia. Nuestra trayectoria y el éxito de nuestros clientes nos avalan. Y nuestra forma de trabajar también.',
  'project.service.digitalMarketing.whyChooseUs.paragraph2': ' ',
  'project.service.digitalMarketing.whyChooseUs.list1':'Somos agencia oficial Google Partner desde 2013.',
  'project.service.digitalMarketing.whyChooseUs.list2':'Contamos con expertos en publicidad digital nacional e internacional, con cientos de campañas gestionadas.',
  'project.service.digitalMarketing.whyChooseUs.list3':'Sacamos el máximo rendimiento posible a tu presupuesto.',
  'project.service.digitalMarketing.whyChooseUs.list4':'Contamos con clientes satisfechos que llevan con nosotros 10+ años.',
  'project.service.digitalMarketing.whyChooseUs.list5':'Somos transparentes. No te prometemos nada que no podamos cumplir.',
  "project.service.digitalMarketing.whyChooseUs.underlinedText1": 'Ya ',
  "project.service.digitalMarketing.whyChooseUs.underlinedText2": 'confían',
  "project.service.digitalMarketing.whyChooseUs.underlinedText3": ' en nosotros',

  'project.service.socialAds.main.title1': 'Agencia especializada en Social Ads',
  'project.service.socialAds.main.paragraph1': '¡Llega a tus clientes potenciales en las redes sociales en las que pasan más tiempo: Instagram, Facebook, TikTok, LinkedIn…! Muéstrales lo que tienes que ofrecer y haz que conviertan.',
  'project.service.socialAds.main.paragraph2': 'Te escuchamos y te asesoramos. Diseñamos, ejecutamos y gestionamos todas tus campañas. Optimizamos a diario para sacarle el máximo partido a tu inversión.',
  'project.service.socialAds.main.title2': '¿Qué conseguirás con una estrategia de Social Ads?',
  'project.service.socialAds.main.list1': 'Posicionar los valores de tu marca.',
  'project.service.socialAds.main.list2': 'Ganar alcance y llegar a nuevos públicos.',
  'project.service.socialAds.main.list3': 'Impactar a personas que han visitado tu web, pero no han convertido.',
  'project.service.socialAds.main.list4': 'Generar imagen de marca mediante contenido audiovisual.',
  'project.service.socialAds.main.list5': 'Incrementar las conversiones y/o ventas de tu site.',
  'project.service.socialAds.knowMore.paragraph1': '¿QUIERES SABER MÁS?',
  'project.service.socialAds.knowMore.paragraph2': 'Escríbenos y te lo contamos todo',
  'project.service.socialAds.knowMore.paragraph3': '¡Sí! quiero más info',
  'project.service.socialAds.knowMore.title1': '¿Con qué tipos de negocio realizamos campañas de Social Ads? ',
  'project.service.socialAds.knowMore.list1': 'PEQUEÑOS NEGOCIOS',
  'project.service.socialAds.knowMore.list2': 'Llevamos a cabo campañas de Social Ads para pequeños negocios que buscan darse a conocer y/o incrementar sus ventas.',
  'project.service.socialAds.knowMore.list3': 'EMPRESAS MEDIANAS',
  'project.service.socialAds.knowMore.list4': 'Prestamos servicios de Social Ads a empresas medianas que quieren lanzar una campaña de branding o aumentar sus conversiones.',
  'project.service.socialAds.knowMore.list5': 'GRANDES EMPRESAS',
  'project.service.socialAds.knowMore.list6': 'Realizamos campañas de Social Ads para empresas nacionales e internacionales que buscan ampliar mercados o convertirse en la opción preferida entre los usuarios.',
  'project.service.socialAds.youNeed.underlineTitle1': 'especialista',
  'project.service.socialAds.youNeed.title1': 'Necesitas una agencia ',
  'project.service.socialAds.youNeed.title2': ' en Social Ads si...',
  'project.service.socialAds.youNeed.list1': 'No consigues llegar a tu público objetivo.',
  'project.service.socialAds.youNeed.list2': 'Quieres publicitarte, pero no tienes un presupuesto muy elevado.',
  'project.service.socialAds.youNeed.list3': 'Eres capaz de llegar a tu público, pero no consigues que conviertan.',
  'project.service.socialAds.youNeed.list4': 'Se acercan fechas señaladas y quieres aprovecharlas para vender más.',
  'project.service.socialAds.youNeed.list5': 'Buscas reforzar tu imagen de marca y promocionarla.',
  'project.service.socialAds.youNeed.list6': 'Sabes cuáles son tus productos estrella y quieres explotarlos.',
  'project.service.socialAds.whyChooseUs.title':'¿Por qué apostar por campañas de Social Ads con nosotros?',
  'project.service.socialAds.whyChooseUs.paragraph1':'Llevamos trabajando en marketing más de 20 años. Nuestros clientes están satisfechos con nuestros servicios y únicamente prometemos lo que sabemos que podemos hacer.',
  'project.service.socialAds.whyChooseUs.paragraph2': ' ',
  'project.service.socialAds.whyChooseUs.list1':'Muchos de nuestros clientes llevan con nosotros 10+ años. No es casualidad.',
  'project.service.socialAds.whyChooseUs.list2':'Te escuchamos. Adaptamos nuestros conocimientos a tus objetivos.',
  'project.service.socialAds.whyChooseUs.list3':'Nuestro equipo de expertos en Social Ads le sacará el máximo rendimiento a tu presupuesto.',
  'project.service.socialAds.whyChooseUs.list4':'Hacemos crecer tu negocio y te ayudamos a vender más y mejor.',
  "project.service.socialAds.whyChooseUs.underlinedText1": 'Ya ',
  "project.service.socialAds.whyChooseUs.underlinedText2": 'confían',
  "project.service.socialAds.whyChooseUs.underlinedText3": ' en nosotros',

  'project.service.googleAds.main.title1': 'Agencia especializada en Google Ads',
  'project.service.googleAds.main.paragraph1': '¡<strong>Anúnciate en Google</strong> y multiplica tu visibilidad y tus ventas! Decide qué contenido mostrar, dónde, cuándo y a quién. Atrae clientes para que compren tus productos o servicios.',
  'project.service.googleAds.main.paragraph2': 'Nos encargamos de todo el proceso. Diseñamos, ejecutamos y gestionamos todas las campañas. Realizamos optimizaciones diarias para maximizar las conversiones.',
  'project.service.googleAds.main.title2': '¿Qué conseguirás con una estrategia de Google Ads?',
  'project.service.googleAds.main.list1': 'Mayor alcance de marca: llegar a personas que no te conocen.',
  'project.service.googleAds.main.list2': 'Incrementar tus ventas online.',
  'project.service.googleAds.main.list3': 'Captar tráfico de tu competencia.',
  'project.service.googleAds.main.list4': 'Impactar directamente a tu público objetivo gracias a la segmentación.',
  'project.service.googleAds.main.list5': 'Maximizar las probabilidades de conversión mediante el retargeting.',
  'project.service.googleAds.knowMore.paragraph1': '¿QUIERES SABER MÁS?',
  'project.service.googleAds.knowMore.paragraph2': 'Escríbenos y te lo contamos todo',
  'project.service.googleAds.knowMore.paragraph3': '¡Sí! quiero más info',
  'project.service.googleAds.knowMore.title1': '¿Con qué tipos de negocio realizamos campañas de Google Ads?',
  'project.service.googleAds.knowMore.list1': 'PEQUEÑOS NEGOCIOS',
  'project.service.googleAds.knowMore.list2': 'Llevamos a cabo campañas para pequeños negocios que buscan incrementar sus ventas durante un periodo específico o de forma constante.',
  'project.service.googleAds.knowMore.list3': 'EMPRESAS MEDIANAS',
  'project.service.googleAds.knowMore.list4': 'Prestamos servicios de Google Ads a empresas medianas que quieren aumentar sus ingresos y seguir evolucionando.',
  'project.service.googleAds.knowMore.list5': 'GRANDES EMPRESAS',
  'project.service.googleAds.knowMore.list6': 'Realizamos campañas de Google Ads para empresas nacionales e internacionales que desean convertirse en la primera opción de los usuarios.',
  'project.service.googleAds.youNeed.underlineTitle1': 'Necesitas',
  'project.service.googleAds.youNeed.title1': ' una agencia especialista en Google Ads si...',
  'project.service.googleAds.youNeed.list1': 'Estás empezando una marca y todavía no te conocen.',
  'project.service.googleAds.youNeed.list2': 'Quieres aparecer para determinadas búsquedas difíciles de posicionar.',
  'project.service.googleAds.youNeed.list3': 'Buscas incrementar tus conversiones.',
  'project.service.googleAds.youNeed.list4': 'Te interesa llegar directamente a tu público objetivo.',
  'project.service.googleAds.youNeed.list5': 'Estás especializado en un sector muy competitivo.',
  'project.service.googleAds.youNeed.list6': 'Quieres cumplir objetivos a corto plazo.',
  'project.service.googleAds.whyChooseUs.title':'¿Por qué apostar por campañas de Google Ads con nosotros?',
  'project.service.googleAds.whyChooseUs.paragraph1':'Porque llevamos 20 años dedicándonos al marketing con éxito. Satisfacemos las expectativas de nuestros clientes y sólo prometemos lo que podemos cumplir.',
  'project.service.googleAds.whyChooseUs.paragraph2': ' ',
  'project.service.googleAds.whyChooseUs.list1':'Clientes satisfechos que llevan con nosotros 10+ años',
  'project.service.googleAds.whyChooseUs.list2':'Somos agencia oficial Google Partner desde 2013',
  'project.service.googleAds.whyChooseUs.list3':'Sacamos el máximo rendimiento a tu presupuesto.',
  'project.service.googleAds.whyChooseUs.list4':'Somos expertos en Google Ads, con cientos de campañas exitosas nacionales e internacionales a nuestras espaldas.',
  "project.service.googleAds.whyChooseUs.underlinedText1": 'Ya ',
  "project.service.googleAds.whyChooseUs.underlinedText2": 'confían',
  "project.service.googleAds.whyChooseUs.underlinedText3": ' en nosotros',

  'project.service.analytics.main.title1': 'Agencia especializada en analítica web',
  'project.service.analytics.main.paragraph1': '¡Conoce todos los datos de tu web para sacarle mayor rendimiento! Visualiza los resultados de las principales métricas para tomar decisiones estratégicas.',
  'project.service.analytics.main.paragraph2': 'Nosotros nos encargamos de definir los indicadores, de recopilar y analizar la información y de elaborar gráficas e informes interpretados para que conozcas al detalle el estado de tu site.',
  'project.service.analytics.main.title2': '¿Qué vas a conseguir con el análisis de datos de tu web?',
  'project.service.analytics.main.list1': 'Aprovechar las oportunidades y los puntos de mejora de tu sitio.',
  'project.service.analytics.main.list2': 'Información valiosa sobre el comportamiento y las características de tu público objetivo.',
  'project.service.analytics.main.list3': 'Datos específicos que te ayudarán a perfilar tus estrategias de marketing y ventas.',
  'project.service.analytics.main.list4': 'Detectar tendencias de mercado.',
  'project.service.analytics.main.list5': 'Mejorar la experiencia de usuario y las conversiones de tu web.',
  'project.service.analytics.knowMore.paragraph1': '¿QUIERES SABER MÁS?',
  'project.service.analytics.knowMore.paragraph2': 'Escríbenos y te lo contamos todo',
  'project.service.analytics.knowMore.paragraph3': '¡Sí! quiero más info',
  'project.service.analytics.knowMore.title1': '¿Para qué tipos de negocio hacemos analítica web?',
  'project.service.analytics.knowMore.list1': 'PEQUEÑOS NEGOCIOS',
  'project.service.analytics.knowMore.list2': 'Llevamos a cabo análisis puntuales de webs de pequeños negocios que buscan conocer su situación y las oportunidades de las que disponen.',
  'project.service.analytics.knowMore.list3': 'EMPRESAS MEDIANAS',
  'project.service.analytics.knowMore.list4': 'Prestamos servicios de analítica web a empresas medianas que quieren informarse sobre su estado actual para mejorar sus resultados.',
  'project.service.analytics.knowMore.list5': 'GRANDES EMPRESAS',
  'project.service.analytics.knowMore.list6': 'Realizamos análisis web a grandes empresas nacionales e internacionales que necesitan conocer en detalle todas las métricas relacionadas con su site para la toma de decisiones.',
  'project.service.analytics.youNeed.underlineTitle1': 'especialista',
  'project.service.analytics.youNeed.title1': 'Necesitas una agencia ',
  'project.service.analytics.youNeed.title2': ' en analítica web si...',
  'project.service.analytics.youNeed.list1': 'Tus ventas se han estancado o incluso han disminuido.',
  'project.service.analytics.youNeed.list2': 'Te interesa explotar al máximo las fortalezas de tu site.',
  'project.service.analytics.youNeed.list3': 'Quieres incrementar las conversiones de tu negocio.',
  'project.service.analytics.youNeed.list4': 'Quieres seguir evolucionando y necesitas tomar decisiones importantes.',
  'project.service.analytics.youNeed.list5': 'Buscas comprender cómo se comporta realmente tu target.',
  'project.service.analytics.whyChooseUs.title':'¿Por qué confiarnos el análisis de tu web?',
  'project.service.analytics.whyChooseUs.paragraph1':'Porque llevamos más de 20 años en esto. Nuestra trayectoria, nuestra profesionalidad y la satisfacción de nuestros clientes nos avalan.',
  'project.service.analytics.whyChooseUs.paragraph2': 'Somos un equipo de gente honesta que se implica al máximo diariamente para conseguir tus objetivos.',
  'project.service.analytics.whyChooseUs.list1':'Trabajamos con marcas que llevan con nosotros 10+ años.',
  'project.service.analytics.whyChooseUs.list2':'Te escuchamos. Adaptamos nuestros conocimientos a tus necesidades.',
  'project.service.analytics.whyChooseUs.list3':'Somos claros y transparentes. No prometemos nada que no vayamos a cumplir.',
  'project.service.analytics.whyChooseUs.list4':'Haremos que no desperdicies ni una sola oportunidad en tu web.',
  'project.service.analytics.whyChooseUs.list5':'Te ayudaremos a vender más ¡y mejor!',
  "project.service.analytics.whyChooseUs.underlinedText1": 'Ya ',
  "project.service.analytics.whyChooseUs.underlinedText2": 'confían',
  "project.service.analytics.whyChooseUs.underlinedText3": ' en nosotros',

  'project.service.mailMarketing.main.title1': 'Agencia especializada en email marketing',
  'project.service.mailMarketing.main.paragraph1': '¡Comunícate directamente con tus clientes potenciales! Envía correos generales y personalizados a quién quieras, cuándo quieras y cómo quieras.',
  'project.service.mailMarketing.main.paragraph2': 'Nos encargamos de todo el proceso. Diseñamos, ejecutamos y gestionamos todas las campañas. Aprovechamos las oportunidades para generar más conversiones.',
  'project.service.mailMarketing.main.title2': '¿Qué vas a conseguir con una agencia de email marketing?',
  'project.service.mailMarketing.main.list1': 'Aportar información de valor a los contactos de tu base de datos.',
  'project.service.mailMarketing.main.list2': 'Realizar comunicaciones estratégicas con objetivos específicos.',
  'project.service.mailMarketing.main.list3': 'Incrementar tus ventas online y fidelizar a tus clientes.',
  'project.service.mailMarketing.main.list4': 'Posicionarte como un experto en tu sector.',
  'project.service.mailMarketing.main.list5': 'Generar familiaridad y confianza con la marca.',
  'project.service.mailMarketing.knowMore.paragraph1': '¿QUIERES SABER MÁS?',
  'project.service.mailMarketing.knowMore.paragraph2': 'Escríbenos y te lo contamos todo',
  'project.service.mailMarketing.knowMore.paragraph3': '¡Sí! quiero más info',
  'project.service.mailMarketing.knowMore.title1': '¿Para qué tipos de negocio hacemos email marketing?',
  'project.service.mailMarketing.knowMore.list1': 'PEQUEÑOS NEGOCIOS',
  'project.service.mailMarketing.knowMore.list2': 'Hacemos email marketing para panaderías, perfumerías, verdulerías y, en realidad, cualquier otro tipo de establecimiento que desee utilizar estos servicios.',
  'project.service.mailMarketing.knowMore.list3': 'EMPRESAS MEDIANAS',
  'project.service.mailMarketing.knowMore.list4': 'Realizamos envíos estratégicos de emails para empresas medianas que desean captar usuarios, crecer y mejorar la comunicación con sus potenciales clientes.',
  'project.service.mailMarketing.knowMore.list5': 'GRANDES EMPRESAS',
  'project.service.mailMarketing.knowMore.list6': 'Prestamos servicios de email marketing a grandes empresas nacionales e internacionales que necesitan automatizar procesos y enviar un gran número de comunicaciones.',
  'project.service.mailMarketing.youNeed.underlineTitle1': 'especialista',
  'project.service.mailMarketing.youNeed.title1': 'Necesitas una agencia ',
  'project.service.mailMarketing.youNeed.title2': ' en email marketing si...',
  'project.service.mailMarketing.youNeed.list1': 'Tienes una base de datos con la que no te estás comunicando.',
  'project.service.mailMarketing.youNeed.list2': 'Quieres convertirte en una marca de referencia.',
  'project.service.mailMarketing.youNeed.list3': 'Mandas correos electrónicos sin segmentar.',
  'project.service.mailMarketing.youNeed.list4': 'Tu stock es muy cambiante y necesitas vender rápido.',
  'project.service.mailMarketing.youNeed.list5': 'No consigues aumentar tus ventas.',
  'project.service.mailMarketing.youNeed.list6': 'Buscas tener clientes recurrentes.',
  'project.service.mailMarketing.whyChooseUs.title':'¿Por qué confiarnos tus campañas de email marketing?',
  'project.service.mailMarketing.whyChooseUs.paragraph1':'Porque llevamos en esto más de 20 años. Somos profesionales, con una trayectoria sólida y clientes satisfechos que nos avalan.',
  'project.service.mailMarketing.whyChooseUs.paragraph2': 'Conocemos todos los detalles y buenas prácticas del email marketing y los ponemos a tu servicio para que consigas tus objetivos.',
  'project.service.mailMarketing.whyChooseUs.list1':'Tenemos clientes que llevan 10+ años con nosotros. Será por algo, ¿no?',
  'project.service.mailMarketing.whyChooseUs.list2':'Te escuchamos. Adaptamos nuestros conocimientos a tus necesidades.',
  'project.service.mailMarketing.whyChooseUs.list3':'No habrá decepciones. Prometemos únicamente lo que podemos cumplir.',
  'project.service.mailMarketing.whyChooseUs.list4':'Aprovechamos todas las oportunidades para hacer crecer tu negocio.',
  'project.service.mailMarketing.whyChooseUs.list5':'Te ayudamos no sólo a vender más, sino también a vender mejor.',
  "project.service.mailMarketing.whyChooseUs.underlinedText1": 'Ya ',
  "project.service.mailMarketing.whyChooseUs.underlinedText2": 'confían',
  "project.service.mailMarketing.whyChooseUs.underlinedText3": ' en nosotros',

  'project.service.servicesList.mailMarketing.row1.1': 'Formularios',
  'project.service.servicesList.mailMarketing.row1.2': 'Generamos y personalizamos formularios de suscripción y de contacto. Creamos un espacio para la captación de leads integrado con un CRM.',
  'project.service.servicesList.mailMarketing.row1.3': 'Segmentación',
  'project.service.servicesList.mailMarketing.row1.4': 'Segmentamos la base de datos en función de criterios preestablecidos. Creamos grupos específicos con características diferenciales a quienes comunicar mensajes distintos.',
  'project.service.servicesList.mailMarketing.row2.1': 'CAMPAÑAS',
  'project.service.servicesList.mailMarketing.row2.2': 'Definimos contigo qué campañas realizar, cómo, cuándo y a quién.',
  'project.service.servicesList.mailMarketing.row2.3': 'Campañas de producto:',
  'project.service.servicesList.mailMarketing.row2.4': 'Notificamos novedades, descuentos, promociones, códigos de descuento ¡y más!',
  'project.service.servicesList.mailMarketing.row2.5': 'Campañas informativas:',
  'project.service.servicesList.mailMarketing.row2.6': 'Compartimos información valiosa para los usuarios como consejos, ebooks, notas corporativas y demás.',
  'project.service.servicesList.mailMarketing.row3.1': 'Diseño',
  'project.service.servicesList.mailMarketing.row3.2': 'Nos encargamos de la estética de los emails y de distribuir el texto, las imágenes y los vídeos para producir el efecto deseado en los destinatarios.',
  'project.service.servicesList.mailMarketing.row3.3': 'Copywriting',
  'project.service.servicesList.mailMarketing.row3.4': 'Redactamos el contenido que se envía en las comunicaciones. Escuchamos tus necesidades y las traducimos en palabras.',
  'project.service.servicesList.mailMarketing.row3.5': 'Automatizaciones',
  'project.service.servicesList.mailMarketing.row3.6': 'Enviamos correos automatizados para no perder ninguna oportunidad: bienvenida, cumpleaños, carritos abandonados, productos relacionados ¡y mucho más!',
  
  'project.service.magento.main.title1': 'Agencia de desarrollo de Magento para ecommerce',
  'project.service.magento.main.paragraph1': '¡Aumenta tus ventas online y haz crecer tu empresa! Incrementa tu competitividad y sácale el máximo partido a tus productos. Convierte el proceso de compra en una experiencia memorable.',
  'project.service.magento.main.paragraph2': 'Como agencia especializada, nos encargamos de todo el proceso de principio a fin. Diseñamos y programamos todos los aspectos de tu ecommerce. Materializamos tus ideas en una tienda online única.',
  'project.service.magento.main.title2': '¿Qué vas a conseguir con un ecommerce Magento?',
  'project.service.magento.main.list1': 'Incrementar notablemente tus ventas.',
  'project.service.magento.main.list2': 'Llegar a un segmento mayor de tu público objetivo.',
  'project.service.magento.main.list3': 'Aumentar el conocimiento y la visibilidad de tu marca.',
  'project.service.magento.main.list4': 'Poder vender 24/7 desde cualquier punto del mundo.',
  'project.service.magento.main.list5': 'Ser capaz de gestionar tú mismo tu inventario y tus ventas.',  
  'project.service.magento.youNeed.underlineTitle1': 'Necesitas',
  'project.service.magento.youNeed.title1': ' una agencia especialista en Magento si...',
  'project.service.magento.youNeed.list1': ' Tienes tienda física, pero no online.',
  'project.service.magento.youNeed.list2': 'Necesitas llegar a nuevos públicos.',
  'project.service.magento.youNeed.list3': 'Quieres abrir un negocio en internet.',
  'project.service.magento.youNeed.list4': 'Buscas aumentar tus ventas.',
  'project.service.magento.youNeed.list5': 'Tu sector es altamente competitivo.',
  'project.service.magento.whyChooseUs.title': '¿Por qué confiarnos el desarrollo de tu ecommerce con Magento?',
  'project.service.magento.whyChooseUs.paragraph1': 'Llevamos más de 20 años desarrollando tiendas online. Sabemos lo que hacemos. Y, además, solo te prometemos lo que podemos cumplir.',
  'project.service.magento.whyChooseUs.paragraph2': 'Nuestra cartera de clientes nos avala. Nuestra experiencia también. Contamos con un equipo especializado que te entregará exactamente lo que buscas.',
  'project.service.magento.whyChooseUs.list1': 'Te escuchamos. Recogemos tus necesidades y las transformamos en un proyecto.',
  'project.service.magento.whyChooseUs.list2': 'Además del desarrollo, también nos ocupamos de las incidencias y del mantenimiento.',
  'project.service.magento.whyChooseUs.list3': 'Plasmamos las necesidades de UX/UI y CRO en el diseño.',
  'project.service.magento.whyChooseUs.list4': 'Te ayudamos a vender más y mejor.',
  'project.service.magento.whyChooseUs.list5': 'Te asesoramos durante todo el proceso.',
  'project.service.magento.knowMore.paragraph1': '¿QUIERES SABER MÁS?',
  'project.service.magento.knowMore.paragraph2': 'Escríbenos y te lo contamos todo',
  'project.service.magento.knowMore.paragraph3': '¡Sí! quiero más info',
  'project.service.magento.knowMore.title1': '¿Para qué tipos de negocio diseñamos ecommerce con Magento? ',
  'project.service.magento.knowMore.list1': 'PEQUEÑOS NEGOCIOS',
  'project.service.magento.knowMore.list2': 'Diseñamos tiendas online con Magento para pescaderías, queserías, pastelerías y, en realidad, cualquier otro negocio que desee vender online.',
  'project.service.magento.knowMore.list3': 'EMPRESAS MEDIANAS',
  'project.service.magento.knowMore.list4': 'Desarrollamos ecommerce con Magento para empresas medianas que buscan crecer y poner a la venta sus productos en internet.',
  'project.service.magento.knowMore.list5': 'GRANDES EMPRESAS',
  'project.service.magento.knowMore.list6': 'Montamos tiendas online con Magento para grandes empresas nacionales e internacionales que buscan un desarrollo a medida con muchas especificaciones y particularidades.',
  'services.magento.title1': '¿Cuáles son las  ',
  'services.magento.title2': 'ventajas',
  'services.magento.title3': ' de utilizar Magento para tu ecommerce?',
  'services.magento.servicesList.title1': 'Instalación y uso:',
  'services.magento.servicesList.text1': 'Fácil de instalar, de configurar y de utilizar. Es intuitivo, por lo que no es necesario ser un experto para poder usarlo.',
  'services.magento.servicesList.title2': 'Pago seguro:',
  'services.magento.servicesList.text2': 'Se pueden utilizar las principales pasarelas de pago de manera segura. La seguridad de la plataforma y la eliminación de sus vulnerabilidades está en continuo desarrollo.',
  'services.magento.servicesList.title3': 'Inventario extenso:',
  'services.magento.servicesList.text3': 'Magento permite añadir hasta un millón de productos y gestionar hasta 80.000 pedidos por hora. Válido para grandes tiendas, pero también para pequeños comercios.',
  'services.magento.servicesList.title4': 'Ficha avanzada de productos:',
  'services.magento.servicesList.text4': 'Incluye títulos, descripción, vídeos, imágenes, productos relacionados y más. Permite configurar las fichas para maximizar las ventas.',
  'services.magento.servicesList.title5': 'Multitienda:',
  'services.magento.servicesList.text5': 'Magento permite gestionar diversas tiendas online desde un mismo entorno. Su manejo es sencillo y proporciona agilidad.',
  'services.magento.servicesList.title6': 'Cross selling y up selling:',
  'services.magento.servicesList.text6': 'Facilita la venta cruzada  y de productos más caros. Se puede configurar para elegir los productos que se muestran.',
  'services.magento.servicesList.title7': 'SEO friendly:',
  'services.magento.servicesList.text7': 'Magento favorece el posicionamiento SEO. Un ecommerce con este CMS tendrá menos dificultades que otros para captar tráfico orgánico en buscadores.',
  'services.magento.servicesList.title8': 'Integración y herramientas:',
  'services.magento.servicesList.text8': 'Permite y facilita la integración de herramientas externas que facilitan la gestión de inventario y ventas de la empresa en su conjunto.', 


  'project.service.woocommerce.main.title1': 'Agencia de desarrollo de WooCommerce para ecommerce',
  'project.service.woocommerce.main.paragraph1': '¡Vende más y mejor con tu tienda online WooCommerce! Sácale el máximo partido a tu catálogo. Aumenta tus ingresos. Haz que tus clientes repitan.',
  'project.service.woocommerce.main.paragraph2': 'Nos encargamos de todo el proceso, desde el diseño hasta la publicación de la tienda online y su mantenimiento. Creamos tiendas únicas que responden a tus necesidades.',
  'project.service.woocommerce.main.title2': '¿Qué vas a conseguir con un ecommerce WooCommerce?',
  'project.service.woocommerce.main.list1': 'Aumentar tus ventas de manera significativa.',
  'project.service.woocommerce.main.list2': 'Alcanzar a un porcentaje mayor de tu público objetivo.',
  'project.service.woocommerce.main.list3': 'Incrementar la visibilidad y el conocimiento de tu marca.',
  'project.service.woocommerce.main.list4': 'Poder vender en cualquier momento, en cualquier lugar.',
  'project.service.woocommerce.main.list5': 'Poder utilizar tu tienda online de manera autónoma.',  
  'project.service.woocommerce.youNeed.underlineTitle1': 'Necesitas',
  'project.service.woocommerce.youNeed.title1': ' una agencia especialista en WooCommerce si...',
  'project.service.woocommerce.youNeed.list1': 'Todavía no has abierto una tienda online para tu tienda física.',
  'project.service.woocommerce.youNeed.list2': 'Buscas mostrar tus productos/servicios a nuevos públicos.',
  'project.service.woocommerce.youNeed.list3': 'Estás buscando emprender un negocio en internet.',
  'project.service.woocommerce.youNeed.list4': 'Quieres incrementar tus ventas.',
  'project.service.woocommerce.youNeed.list5': 'Te mueves en un sector de competencia elevada.',
  'project.service.woocommerce.whyChooseUs.title': '¿Por qué confiarnos el desarrollo de tu tienda online con WooComerce?',
  'project.service.woocommerce.whyChooseUs.paragraph1': 'Llevamos más de 20 años desarrollando ecommerce. Nuestro equipo de profesionales se encargará de tu proyecto de principio a fin.',
  'project.service.woocommerce.whyChooseUs.paragraph2': 'Contamos con clientes que llevan 10+ años con nosotros. Tenemos la experiencia y los conocimientos necesarios para entregarte lo que necesitas.',
  'project.service.woocommerce.whyChooseUs.list1': 'Tú eres la prioridad. Recogemos tus necesidades y las plasmamos en tu tienda online.',
  'project.service.woocommerce.whyChooseUs.list2': 'Además de desarrollar tu ecommerce, también nos encargamos de su mantenimiento.',
  'project.service.woocommerce.whyChooseUs.list3': 'Tenemos en cuenta la experiencia de usuario y lo reflejamos en el resultado.',
  'project.service.woocommerce.whyChooseUs.list4': 'Conseguirás vender más y mejor.',
  'project.service.woocommerce.whyChooseUs.list5': 'Te asesoraremos en todo lo que necesites y te mantendremos informado en todo momento.',
  'project.service.woocommerce.knowMore.paragraph1': '¿QUIERES SABER MÁS?',
  'project.service.woocommerce.knowMore.paragraph2': 'Escríbenos y te lo contamos todo',
  'project.service.woocommerce.knowMore.paragraph3': '¡Sí! quiero más info',
  'project.service.woocommerce.knowMore.title1': '¿Para qué tipos de negocio diseñamos ecommerce con WooCommerce? ',
  'project.service.woocommerce.knowMore.list1': 'PEQUEÑOS NEGOCIOS',
  'project.service.woocommerce.knowMore.list2': 'Diseñamos ecommerce con WooComerce para academias, librerías, desguaces y, en realidad, cualquier otro negocio que desee vender online.',
  'project.service.woocommerce.knowMore.list3': 'EMPRESAS MEDIANAS',
  'project.service.woocommerce.knowMore.list4': 'Desarrollamos tiendas online con WooCommerce para empresas que quieren crecer y vender sus productos en internet.',
  'project.service.woocommerce.knowMore.list5': 'GRANDES EMPRESAS',
  'project.service.woocommerce.knowMore.list6': 'Montamos ecommerce con WooCommerce para grandes empresas nacionales e internacionales que necesitan un desarrollo a medida que les permita entregar una experiencia de compra de calidad.',
  

  'project.service.ecommerceConsulting.main.title1': 'Consultoría de Ecommerce',
  'project.service.ecommerceConsulting.main.paragraph1': '¡Descubre cómo vender más online! Crea tu propio ecommerce u optimiza el que ya tienes. Construye y rentabiliza tu presencia digital.',
  'project.service.ecommerceConsulting.main.paragraph2': 'Analizamos tu situación y tus necesidades y determinamos el punto de partida. Diseñamos un plan de acción a medida y te orientamos en su aplicación.',
  'project.service.ecommerceConsulting.main.title2': '¿Qué vas a conseguir con una consultora de ecommerce?',
  'project.service.ecommerceConsulting.main.list1': 'Sacarle el máximo partido a tu comercio electrónico.',
  'project.service.ecommerceConsulting.main.list2': 'Incrementar la visibilidad de tus productos o servicios en internet.',
  'project.service.ecommerceConsulting.main.list3': 'Aumentar la visibilidad online de tu marca.',
  'project.service.ecommerceConsulting.main.list4': 'Mejorar la usabilidad de tu tienda online.',
  'project.service.ecommerceConsulting.main.list5': 'Ofrecer una experiencia de compra cómoda y usable.',
  'project.service.ecommerceConsulting.knowMore.paragraph1': '¿QUIERES SABER MÁS?',
  'project.service.ecommerceConsulting.knowMore.paragraph2': 'Escríbenos y te lo contamos todo',
  'project.service.ecommerceConsulting.knowMore.paragraph3': '¡Sí! quiero más info',
  'project.service.ecommerceConsulting.knowMore.title1': '¿Para qué tipos de negocio realizamos consultoría de ecommerce?',
  'project.service.ecommerceConsulting.knowMore.list1': 'PEQUEÑOS NEGOCIOS',
  'project.service.ecommerceConsulting.knowMore.list2': 'Asesoramos a pequeños negocios como ferreterías, tiendas de moda y pescaderías para que puedan contar con el comercio electrónico que desean.',
  'project.service.ecommerceConsulting.knowMore.list3': 'EMPRESAS MEDIANAS',
  'project.service.ecommerceConsulting.knowMore.list4': 'Ayudamos a negocios de tamaño mediano que quieren crecer online e impulsar tanto su visibilidad como sus ventas.',
  'project.service.ecommerceConsulting.knowMore.list5': 'GRANDES EMPRESAS',
  'project.service.ecommerceConsulting.knowMore.list6': 'Guiamos a grandes empresas nacionales e internacionales para sacarle el máximo partido a sus ecommerce e incrementar sus conversiones.',
  'project.service.ecommerceConsulting.whyChooseUs.title': '¿Por qué elegirnos para tus servicios de consultoría de ecommerce?',
  'project.service.ecommerceConsulting.whyChooseUs.paragraph1': 'Llevamos más de 20 años trabajando en el entorno digital. Tenemos una extensa cartera de clientes satisfechos, muchos de los cuales llevan con nosotros más de 10 años.',
  'project.service.ecommerceConsulting.whyChooseUs.paragraph2': 'Contamos con un equipo de profesionales especializados en ecommerce que te guiarán paso por paso para que consigas los resultados que estás buscando.',
  'project.service.ecommerceConsulting.whyChooseUs.list1': 'Llevamos a cabo un análisis detallado y pormenorizado de tu situación.',
  'project.service.ecommerceConsulting.whyChooseUs.list2': 'Realizamos propuestas adaptadas basadas en testeos previos.',
  'project.service.ecommerceConsulting.whyChooseUs.list3': 'Te escuchamos en todo momento. Hacemos nuestros tus objetivos.',
  'project.service.ecommerceConsulting.whyChooseUs.list4': 'Te asesoramos para que tu negocio crezca.',
  'project.service.ecommerceConsulting.whyChooseUs.list5': 'Te haremos vender más y mejor.',
  'project.service.ecommerceConsulting.youNeed.underlineTitle1': 'Necesitas',
  'project.service.ecommerceConsulting.youNeed.title1': ' una consultoría de ecommerce si…',
  'project.service.ecommerceConsulting.youNeed.list1': 'Todavía no tienes un ecommerce, pero quieres uno.',
  'project.service.ecommerceConsulting.youNeed.list2': 'Tienes una tienda online, pero no está optimizada.',
  'project.service.ecommerceConsulting.youNeed.list3': 'Buscas incrementar tus ventas por internet.',
  'project.service.ecommerceConsulting.youNeed.list4': 'Quieres sacarle el máximo rendimiento a tu negocio.',
  'project.service.ecommerceConsulting.youNeed.list5': 'Tienes ideas sobre lo que quieres para tu ecommerce, pero no sabes ejecutarlas.',

  'project.service.marketingConsulting.main.title1': 'Consultoría de marketing digital',
  'project.service.marketingConsulting.main.paragraph1': '¡Descubre qué necesita tu negocio para vender más! Aprovecha las oportunidades y consigue tus objetivos. Aumenta tu visibilidad y tu facturación.',
  'project.service.marketingConsulting.main.paragraph2': 'Analizamos tus activos digitales y tu situación. Diseñamos estrategias a medida y te orientamos en su aplicación.',
  'project.service.marketingConsulting.main.title2': '¿Qué vas a conseguir con una consultora de marketing digital? ',
  'project.service.marketingConsulting.main.list1': 'Aumentar la visibilidad de tu marca en internet.',
  'project.service.marketingConsulting.main.list2': 'Hacer crecer tu empresa.',
  'project.service.marketingConsulting.main.list3': 'Llegar a nuevos públicos.',
  'project.service.marketingConsulting.main.list4': 'Moldear la imagen de tu marca.',
  'project.service.marketingConsulting.main.list5': 'Fidelizar a tus clientes.',
  'project.service.marketingConsulting.knowMore.paragraph1': '¿QUIERES SABER MÁS?',
  'project.service.marketingConsulting.knowMore.paragraph2': 'Escríbenos y te lo contamos todo',
  'project.service.marketingConsulting.knowMore.paragraph3': '¡Sí! quiero más info',
  'project.service.marketingConsulting.knowMore.title1': '¿Para qué tipos de negocios realizamos consultoría de marketing digital?',
  'project.service.marketingConsulting.knowMore.list1': 'PEQUEÑOS NEGOCIOS',
  'project.service.marketingConsulting.knowMore.list2': 'Asesoramos a empresas pequeñas como pescaderías, academias y panaderías y, en realidad, a cualquier otro negocio que necesite nuestros servicios.',
  'project.service.marketingConsulting.knowMore.list3': 'EMPRESAS MEDIANAS',
  'project.service.marketingConsulting.knowMore.list4': 'Ayudamos a negocios de tamaño medio que buscan crecer online y darle un impulso a su visibilidad y a sus conversiones.',
  'project.service.marketingConsulting.knowMore.list5': 'GRANDES EMPRESAS',
  'project.service.marketingConsulting.knowMore.list6': 'Guiamos a grandes empresas nacionales e internacionales para detectar oportunidades y crear un plan a medida que permita sacarles el máximo rendimiento.',
  'project.service.marketingConsulting.whyChooseUs.title': '¿Por qué elegirnos para tus servicios de consultoría de marketing digital?',
  'project.service.marketingConsulting.whyChooseUs.paragraph1': 'Contamos con más de 20 años de experiencia en el entorno digital y una extensa cartera de clientes satisfechos que llevan 10+ años con nosotros.',
  'project.service.marketingConsulting.whyChooseUs.paragraph2': 'Nuestro equipo de profesionales especializados se sentará contigo para conocer tus necesidades y trabajará para ofrecerte la solución que estás buscando.',
  'project.service.marketingConsulting.whyChooseUs.list1': 'Generamos respuestas exclusivas y totalmente adaptadas a tu situación.',
  'project.service.marketingConsulting.whyChooseUs.list2': 'Realizamos propuestas basadas en el análisis y el testeo.',
  'project.service.marketingConsulting.whyChooseUs.list3': 'Nuestros objetivos son los tuyos. Nos esforzamos por conseguirlos.',
  'project.service.marketingConsulting.whyChooseUs.list4': 'Te mantendremos informado en todo momento.',
  'project.service.marketingConsulting.whyChooseUs.list5': 'Conseguirás vender más y mejor.',
  'project.service.marketingConsulting.youNeed.underlineTitle1': 'Necesitas',
  'project.service.marketingConsulting.youNeed.title1': ' una consultoría de marketing digital si…',
  'project.service.marketingConsulting.youNeed.list1': 'Estás buscando que tu empresa crezca en el ámbito digital.',
  'project.service.marketingConsulting.youNeed.list2': 'No cuentas con profesionales especializados.',
  'project.service.marketingConsulting.youNeed.list3': 'Quieres contar con una nueva visión sobre la empresa.',
  'project.service.marketingConsulting.youNeed.list4': 'Buscas fidelizar a tus clientes.',
  'project.service.marketingConsulting.youNeed.list5': 'Estás interesado en aprovechar todas las oportunidades de tu negocio.',

  'project.prefooter.contactBudget.version1.p1' : 'Todos nuestros presupuestos son personalizados.',
  'project.prefooter.contactBudget.version2.p1' : '¿Qué necesitas? Cuéntanoslo y elaboramos un presupuesto a medida solo para ti.',
  'project.prefooter.contactBudget.p2' : 'Solicítalo sin compromiso.',
  'project.prefooter.contactBudget.button' : 'Solicitar presupuesto',
  'project.prefooter.contactBudget.button.alternative' : '¡Cuéntanos tu caso!',

  'project.prefooter.related-links.title' : '¡Tenemos más para ti!',
  'project.prefooter.related-links.title.team' : '¡Conoce nuestros servicios!',
  'project.prefooter.related-links.version1.p1' : 'Si buscas otras formas de promocionar tu negocio quizás deberías probar con:',
  'project.prefooter.related-links.version1.p2' : 'Y si lo que buscas es dar el salto al mundo digital...',
  'project.prefooter.related-links.version2.p1' : 'Si buscas otras opciones para el desarrollo de tu proyecto te podría interesar...',
  'project.prefooter.related-links.version2.p2' : 'Y si lo quieres es darte a conocer, también podemos ayudarte con:',
  'project.prefooter.related-links.version3.p1' : 'Si quieres saber más sobre nuestros trabajos te podría interesar visitar:',

  'project.footer.googlepartner.title': 'Somos...',
  'project.footer.services.title': 'y se nos da muy bien...',
  'project.footer.reviews.title': '¡Lo que dicen de nosotros!',
  'project.footer.contact.title1': 'Sigamos en',
  'project.footer.contact.title2': 'contacto',
  'project.footer.socialnetwork.title': 'En redes:',
  'project.footer.phone.title': 'Por teléfono:',
  'project.footer.email.title': 'Por mail:',
  'project.footer.address.title': 'O en persona:',
  'project.footer.legallinks.advice': 'Aviso Legal',
  'project.footer.legallinks.privacy': 'Política de Privacidad',
  'project.footer.legallinks.cookies': 'Política de Cookies',
  'project.footer.legallinks.funds': 'Fondos Públicos',

  'project.generic.servicesList.text1': 'Necesitas una agencia ',
  'project.generic.servicesList.textUnderline': 'especializada',
  'project.generic.servicesList.text2': ' en GMB si...',
  
  'services.ecommerce.title1': '¿Como se realiza el desarrollo y diseño de ',
  'services.ecommerce.title2': 'tiendas online',
  'services.ecommerce.title3': ' ?',

  'services.ecommerce.servicesList.title1': 'Programación:',
  'services.ecommerce.servicesList.text1': 'Escuchamos lo que necesitas y actuamos en consecuencia. Aplicamos todas las funcionalidades que necesitas en tu ecommerce para que recibas exactamente lo que buscas.',
  'services.ecommerce.servicesList.title2': 'Diseño y maquetación:',
  'services.ecommerce.servicesList.text2': 'Recopilamos tus ideas y las trasladamos a la interfaz de tu tienda. Potenciamos la presencia de tus productos o servicios, pero también tu imagen de marca.',
  'services.ecommerce.servicesList.title3': 'Responsive:',
  'services.ecommerce.servicesList.text3': 'Trabajamos para que tu ecommerce sea perfectamente accesible en todo momento. Nos aseguramos de que se puedan realizar compras cómodamente desde cualquier dispositivo.',
  'services.ecommerce.servicesList.title4': 'Experiencia de usuario:',
  'services.ecommerce.servicesList.text4': 'Ponemos a tus clientes potenciales en el centro. Entregamos una experiencia más que satisfactoria de navegación por la página para facilitar la compra y reforzar la imagen de la marca.',
  'services.ecommerce.servicesList.title5': 'Optimización y carga:',
  'services.ecommerce.servicesList.text5': 'Hacemos que tu site cargue con rapidez. Lo ajustamos a las preferencias de los motores de búsqueda y realizamos todas las optimizaciones pertinentes.',
  'services.ecommerce.servicesList.title6': 'Pasarela de pago:',
  'services.ecommerce.servicesList.text6': 'Implementamos una pasarela de pago para que tu ecommerce sea operativo. Nos encargamos de que todo funcione correctamente y de manera segura.',
  'services.ecommerce.servicesList.title7': 'Integración y conexión con tiendas online:',
  'services.ecommerce.servicesList.text7': 'Conectamos tu ecommerce con tu sistema ERP, software CRM y programas de gestión para facilitar la administración y monitorización diaria de tu negocio.',
  'services.ecommerce.servicesList.title8': 'SSL:',
  'services.ecommerce.servicesList.text8': 'Instalamos el certificado SSL para conexiones seguras.', 
  'services.ecommerce.servicesList.title9': 'Hosting y mantenimiento:',
  'services.ecommerce.servicesList.text9': 'Alojamos tu ecommerce en nuestro servidor. Realizamos las tareas de mantenimiento necesarias y solucionamos cualquier problema técnico que pudiera surgir.',

  'services.corporativeWebServices.title1': '¿Cómo ',
  'services.corporativeWebServices.title2': 'desarrollamos',
  'services.corporativeWebServices.title3': ' y diseñamos webs corporativas?',

  'services.corporativeWebServices.servicesList.title1': 'Programación:',
  'services.corporativeWebServices.servicesList.text1': 'Recopilamos todas tus necesidades y las traducimos en funcionalidades dentro de tu web. Te entregamos exactamente lo que buscas',
  'services.corporativeWebServices.servicesList.title2': 'Diseño y maquetación:',
  'services.corporativeWebServices.servicesList.text2': 'Recogemos tus ideas y las plasmamos en la apariencia de tu site. Lo adaptamos a tu imagen corporativa y al mensaje que quieres transmitir.',
  'services.corporativeWebServices.servicesList.title3': 'Responsive:',
  'services.corporativeWebServices.servicesList.text3': 'Nos aseguramos de que el contenido de la web se vea correctamente desde cualquier dispositivo, prestando especial atención a los móviles.',
  'services.corporativeWebServices.servicesList.title4': 'Experiencia de usuario:',
  'services.corporativeWebServices.servicesList.text4': 'Ponemos a tus clientes potenciales en el centro del diseño. Nos centramos en que los usuarios tengan una sensación lo más agradable posible durante y después de la navegación.',
  'services.corporativeWebServices.servicesList.title5': 'Textos corporativos:',
  'services.corporativeWebServices.servicesList.text5': 'Nos encargamos de la redacción del contenido de tu web en función de tus preferencias. Lo hacemos con perspectiva SEO para que comiences a posicionarte cuánto antes.',
  'services.corporativeWebServices.servicesList.title6': 'Optimización y carga:',
  'services.corporativeWebServices.servicesList.text6': 'Conseguimos que tu site sea rápido. Lo ajustamos a los estándares de Google y satisfacemos las expectativas de velocidad de los usuarios.',
  'services.corporativeWebServices.servicesList.title7': 'SSL:',
  'services.corporativeWebServices.servicesList.text7': 'Instalamos el certificado SSL para conexiones seguras.',
  'services.corporativeWebServices.servicesList.title8': 'Hosting y mantenimiento:',
  'services.corporativeWebServices.servicesList.text8': 'Alojamos tu ecommerce en nuestro servidor. Realizamos las tareas de mantenimiento necesarias y solucionamos cualquier problema técnico que pudiera surgir.', 

  'services.marketing.title1': '¿Qué ',
  'services.marketing.title2': 'servicios',
  'services.marketing.title3': ' de marketing online prestamos?',

  'services.marketing.servicesList.title1': 'SEO:',
  'services.marketing.servicesList.text1': 'Optimizamos tu web al máximo para que aparezca en las primeras posiciones de los motores de búsqueda. Trazamos una estrategia de contenidos a medida que te ayude a crecer orgánicamente.',
  'services.marketing.servicesList.url1': '/seo',  

  'services.marketing.servicesList.title2': 'SEO local/Google My Business:',
  'services.marketing.servicesList.text2': 'Trabajamos el posicionamiento local  y optimizamos tu ficha de Google MyBusiness para sacarle el mayor rendimiento posible. Te hacemos aparecer entre los primeros resultados en tu zona.',
  'services.marketing.servicesList.url2': '/gmb',  

  'services.marketing.servicesList.title3': 'Email Marketing:',
  'services.marketing.servicesList.text3': 'Enviamos emails personalizados a tus contactos para conseguir que conviertan. Comunicamos ofertas, promociones, beneficios ¡y mucho más!',
  'services.marketing.servicesList.url3': '/mail-marketing',  

  'services.marketing.servicesList.title4': 'Sem:',
  'services.marketing.servicesList.text4': 'Desarrollamos una estrategia a medida en los principales canales de publicidad digital: Google Ads y Social Ads. Te ayudamos a vender más.',
  'services.marketing.servicesList.url4': '/sem',  

  'services.marketing.servicesList.title5': 'Google Ads:',
  'services.marketing.servicesList.text5': 'Utilizamos los anuncios de texto en buscadores, la red de display de Google, YouTube y Google Shopping para incrementar tus ingresos. También hacemos publicidad en Bing Ads.',
  'services.marketing.servicesList.url5': '/google-ads',  

  'services.marketing.servicesList.title6': 'Social Ads:',
  'services.marketing.servicesList.text6': 'Generamos anuncios visuales y atractivos y nos encargamos de publicitarlos a través de las redes sociales. Hacemos tu marca atractiva e incrementamos las conversiones.',
  'services.marketing.servicesList.url6': '/social-ads',  

  'services.marketing.servicesList.title7': 'Analytics:',
  'services.marketing.servicesList.text7': 'Analizamos cada dato que sale de tus campañas. Tomamos decisiones basadas en resultados. Aprovechamos todas las oportunidades detectadas.',
  'services.marketing.servicesList.url7': '/analitica',  


  'services.marketing.servicesList.title8': 'Redes sociales:',
  'services.marketing.servicesList.text8': 'Potencia tu presencia en redes sociales con nuestro servicio de gestión. Creamos estrategias a medida, generamos contenido y nos encargamos de la interacción constante con tu audiencia.', 

  'services.marketing.servicesList.title9': 'Consultoría:',
  'services.marketing.servicesList.text9': 'Analizamos tus activos digitales y tu situación. Diseñamos estrategias a medida y te orientamos en su aplicación.', 
  'services.marketing.servicesList.url9': '/consultoria-marketing',  


  'services.seo.title1': '¿Cómo llevamos a cabo el ',
  'services.seo.title2': 'posicionamiento',
  'services.seo.title3': ' en nuestra Agencia SEO en Coruña?',

  'services.seo.servicesList.title1': 'Auditoría SEO:',
  'services.seo.servicesList.text1': 'Analizamos tu web a fondo para detectar errores y puntos de mejora. Proponemos soluciones y las ejecutamos. Monitorizamos los resultados y hacemos recomendaciones.',
  'services.seo.servicesList.title2': 'SEO local:',
  'services.seo.servicesList.text2': 'Te colocamos en el mapa de tu zona y te hacemos destacar. Trabajamos tu posicionamiento como referente local y hacemos que tu público objetivo te encuentre.',
  'services.seo.servicesList.title3': 'Contenidos:',
  'services.seo.servicesList.text3': 'Investigamos qué temáticas se ajustan más a tu negocio y a lo que buscan tus clientes. Generamos contenido que convierte y lo optimizamos para llegar a lo más alto.',
  'services.seo.servicesList.title4': 'Link Building:',
  'services.seo.servicesList.text4': 'Diseñamos estrategias ad hoc de enlazado externo para aumentar la autoridad de tu página. Nos ponemos en contacto con otras webs y lo gestionamos todo de principio a fin.',
  'services.seo.servicesList.title5': 'Migraciones:',
  'services.seo.servicesList.text5': 'Nos encargamos de prepararlo todo para migrar tu site. Ejecutamos la migración y monitorizamos los cambios para mantener el posicionamiento al máximo.',
  'services.seo.servicesList.title6': 'WPO:',
  'services.seo.servicesList.text6': 'Mejoramos constantemente tu site. Realizamos análisis periódicos, detectamos oportunidades y actuamos en consecuencia.',
  'services.seo.servicesList.title7': 'Optimización:',
  'services.seo.servicesList.text7': 'Mejoramos constantemente tu site. Realizamos análisis periódicos, detectamos oportunidades y actuamos en consecuencia.',
  'services.seo.servicesList.title8': 'Medición:',
  'services.seo.servicesList.text8': 'Establecemos KPIs y los analizamos semanalmente para extraer conclusiones y tomar decisiones tácticas. Generamos informes que te permitan conocer la evolución de la estrategia SEO.', 
  'services.seo.servicesList.title9': 'Consultoría SEO:',
  'services.seo.servicesList.text9': 'Analizamos tu web, te ayudamos a definir unos objetivos, te asesoramos para diseñar una estrategia SEO y te acompañamos en el seguimiento de los resultados.', 

  'services.gmb.title1': '¿Qué ',
  'services.gmb.title2': 'servicios',
  'services.gmb.title3': ' de Google My Business ofrecemos?',

  'services.gmb.servicesList.title1': 'Contenidos:',
  'services.gmb.servicesList.text1': 'Redactamos y publicamos mensualmente el número de contenidos que hayamos pactado contigo. Nosotros nos encargamos de todo, incluso de las imágenes y de las creatividades.',
  'services.gmb.servicesList.title2': 'Reseñas:',
  'services.gmb.servicesList.text2': 'Gestionamos las reseñas de tu ficha. Establecemos un tono y unos códigos de comunicación acordes a tu negocio y los aplicamos en las respuestas. Mantenemos tu imagen de marca y trabajamos tu reputación.',
  'services.gmb.servicesList.title3': 'Mensajes:',
  'services.gmb.servicesList.text3': 'Respondemos los mensajes que recibas a través de tu ficha de GMB.',
  'services.gmb.servicesList.title4': 'Informes:',
  'services.gmb.servicesList.text4': 'Extraemos los datos y tomamos decisiones en consecuencia. Generamos reportes detallados para que puedas conocer los resultados de la estrategia de GMB.',
  'services.gmb.servicesList.title5': 'Auditorias:',
  'services.gmb.servicesList.text5': 'Analizamos tu ficha actual de Google My Business, detectamos puntos de mejora y actuamos para aprovecharlos. Hacemos recomendaciones y ejecutamos soluciones.',
  'services.gmb.servicesList.title6': 'Optimización:',
  'services.gmb.servicesList.text6': 'Mantenemos los datos de tu ficha (horarios, días de apertura, servicios, etc.) siempre al día.',

  'services.digitalMarketing.title1': '¿Qué te ofrecemos como agencia de ',
  'services.digitalMarketing.title2': 'publicidad digital',
  'services.digitalMarketing.title3': ' ?',

  'services.digitalMarketing.servicesList.title1': '2 TIPOS DE CAMPAÑAS',
  'services.digitalMarketing.servicesList.title2': 'Prospecting:',
  'services.digitalMarketing.servicesList.text2': 'Atraemos clientes potenciales a tu site mediante anuncios en distintos formatos. Te hacemos conocido para quien no te conoce. Incrementamos tu visibilidad en momentos clave.',
  'services.digitalMarketing.servicesList.title3': 'Remarketing:',
  'services.digitalMarketing.servicesList.text3': 'Reimpactamos con anuncios a aquellas personas que ya han visitado tu web y/o tus productos, pero que todavía no han convertido. Las “perseguimos” con anuncios para que se familiaricen con tu marca y se decanten por ti.',

  'services.digitalMarketing.servicesList.title4': '3 TIPOS DE PUBLICIDAD DIGITAL',
  'services.digitalMarketing.servicesList.title5': 'SEM (Google Ads y Bing Ads):',
  'services.digitalMarketing.servicesList.text5': 'Colocamos anuncios de texto en buscadores como Google y Bing para búsquedas estratégicamente seleccionadas. Promocionamos productos en Google Shopping para que vendas más.',
  'services.digitalMarketing.servicesList.title6': 'Display:',
  'services.digitalMarketing.servicesList.text6': 'Hacemos que tus anuncios en imágenes (banners) aparezcan en las webs que visitan tus clientes potenciales. Aumentamos tu visibilidad como marca y también la probabilidad de venta.',
  'services.digitalMarketing.servicesList.title7': 'Social Ads:',
  'services.digitalMarketing.servicesList.text7': 'Mostramos tus vídeos en las principales redes sociales: Instagram, Facebook, LinkedIn, TikTok, YouTube ¡y más! Seleccionamos las que más se ajustan a tus necesidades y a tu público objetivo.',

  'services.digitalMarketing.servicesList.title8': 'LANDING PAGE',
  'services.digitalMarketing.servicesList.text9': 'Creamos una página específica orientada a la conversión, con información clara y concisa y un formulario que invite a realizar una acción: a efectuar una compra, a suscribirse, a reservar, etc. Concentramos los esfuerzos en una única URL a la que enviamos a quienes clican en los anuncios.',

  'services.socialAds.title1': '¿Cómo ',
  'services.socialAds.title2': 'anunciamos',
  'services.socialAds.title3': ' tu empresa en Social Ads?',

  'services.servicesList.socialAds.title1': '2 TIPOS DE CAMPAÑA DE SOCIAL ADS',
  'services.servicesList.socialAds.title2': 'Prospecting:',
  'services.servicesList.socialAds.text2': 'Generamos tráfico a tu site mediante anuncios en diversos formatos. Te hacemos conocido para quien no te conoce. Aumentamos tu visibilidad gracias a los anuncios de Social Ads y te convertimos en una opción de compra.',
  'services.servicesList.socialAds.title3': 'Remarketing:',
  'services.servicesList.socialAds.text3': 'Mostramos de nuevo tus anuncios a las personas que ya han estado en tu web, pero que todavía no han realizado una conversión. Hacemos que se familiaricen con tu marca y se decanten por ti.',

  'services.servicesList.socialAds.title4': 'PLATAFORMAS DE SOCIAL ADS',
  'services.servicesList.socialAds.title5': 'Facebook:',
  'services.servicesList.socialAds.text5': 'Diseñamos una estrategia personalizada. Segmentamos las audiencias de manera pormenorizada. Mostramos tus anuncios en distintos emplazamientos para que consigas tus objetivos.',
  'services.servicesList.socialAds.title6': 'Instagram:',
  'services.servicesList.socialAds.text6': 'Impactamos a tu público en el feed, en stories, en reels y en la tienda. Mostramos contenido de calidad y productos seleccionados para incrementar las conversiones.',
  'services.servicesList.socialAds.title7': 'LinkedIn:',
  'services.servicesList.socialAds.text7': 'Generamos anuncios únicos. Creamos segmentos personalizados a los que mostrar tu mensaje. Llegamos exactamente al público que te interesa.',
  'services.servicesList.socialAds.title8': 'Tiktok:',
  'services.servicesList.socialAds.text8': 'Utilizamos los mejores vídeos para incrementar el conocimiento de tu marca. Generamos tráfico a tu site para que convierta.',
  'services.servicesList.socialAds.title9': 'Twitter:',
  'services.servicesList.socialAds.text9': 'Creamos anuncios de texto, imagen o vídeo para llegar a nuevos segmentos de la audiencia. Sacamos el máximo rendimiento a tu presupuesto para conseguir resultados excelentes.',

  'services.servicesList.socialAds.title10': 'LANDING PAGE',
  'services.servicesList.socialAds.text10': 'Creamos una página específica orientada a la conversión, con información clara y concisa y un formulario que invite a realizar una acción: a efectuar una compra, a suscribirse, a reservar, etc. Concentramos los esfuerzos en una única URL a la que enviamos a quienes clican en los anuncios.',

  'services.googleAds.title1': '¿Cómo ',
  'services.googleAds.title2': 'anunciamos',
  'services.googleAds.title3': ' tu empresa en Google Ads?',

  'services.servicesList.googleAds.title1': '2 TIPOS DE CAMPAÑA DE GOOGLE ADS',
  'services.servicesList.googleAds.title2': 'Prospecting:',
  'services.servicesList.googleAds.text2': 'Atraemos clientes potenciales a tu site mediante anuncios en distintos formatos. Te hacemos conocido para quien no te conoce. Te convertimos en una opción de compra gracias a la visibilidad que aportan los anuncios de Google Ads.',
  'services.servicesList.googleAds.title3': 'Remarketing:',
  'services.servicesList.googleAds.text3': 'Reimpactamos con anuncios a aquellas personas que ya han visitado tu web y/o tus productos, pero que todavía no han convertido. Las “perseguimos” con anuncios para que se familiaricen con tu marca  y se decanten por ti.',

  'services.servicesList.googleAds.title4': '4 TIPOS DE ANUNCIOS EN GOOGLE ADS',
  'services.servicesList.googleAds.title5': 'Red de búsqueda:',
  'services.servicesList.googleAds.text5': 'Determinamos para qué búsquedas de Google te quieres publicitar. Publicamos anuncios de texto cuando un usuario realiza alguna de esas búsquedas. Le contamos qué le puedes ofrecer. Le invitamos a visitar tu web y a convertir en ella.',
  'services.servicesList.googleAds.title6': 'Google Shopping:',
  'services.servicesList.googleAds.text6': 'Mostramos tus productos cuando un cliente potencial realiza una búsqueda relacionada con lo que ofreces en Google. Te hacemos aparecer entre las marcas más potentes. Potenciamos tu branding y tus ventas.',
  'services.servicesList.googleAds.title7': 'Google Display Network (GDN):',
  'services.servicesList.googleAds.text7': 'Hacemos que tus anuncios en imágenes (banners) aparezcan en webs que visitan tus clientes potenciales. Incrementamos tu visibilidad para tu público objetivo y la probabilidad de venta.',
  'services.servicesList.googleAds.title8': 'Youtube:',
  'services.servicesList.googleAds.text8': 'Publicamos tus anuncios en vídeo, en imágenes o en texto para lleguen a tu target. Utilizamos la variedad de formatos para incrementar el conocimiento de marca y las conversiones.',

  'services.servicesList.googleAds.title9': 'LANDING PAGE',
  'services.servicesList.googleAds.text9': 'Creamos una página específica orientada a la conversión, con información clara y concisa y un formulario que invite a realizar una acción: a efectuar una compra, a suscribirse, a reservar, etc. Concentramos los esfuerzos en una única URL a la que enviamos a quienes clican en los anuncios.',

  'project.team.principal.title': 'Quiénes somos',
  'project.team.principal.subtitle1': 'Contamos con un equipo ',
  'project.team.principal.subtitle2': 'multidisciplinar ',
  'project.team.principal.subtitle3': 'que se compenetra a las mil maravillas. En Pululart estamos muy unidos 💜 y eso se nota en cada uno de los proyectos que llevamos adelante porque ',
  'project.team.principal.subtitle4': 'la pasión por lo que hacemos ',
  'project.team.principal.subtitle5': 'es capaz de aunar a cinéfilos, remeros, músicos, jinetes, gamers, djs, bailarines... todos con el objetivo de acompañar a nuestros clientes y ayudarlos a lograr sus metas. ',
  'project.team.principal.subtitle6': 'Te presentamos a nuestro ',
  'project.team.principal.subtitle7': 'equipo',
  'project.team.heading.subtitle1': 'Pululart ',
  'project.team.heading.subtitle2': 'nace en el 2002 ',
  'project.team.heading.subtitle3': 'cuando el término Wi-Fi empezaba a conocerse, la gente tenía miedo a comprar por Internet y tener una web era algo reservado a unos pocos. Todo eso ha cambiado. Hoy no hay cafetería sin Wi-Fi, compramos desde el móvil con un solo clic y, afortunadamente, tener una web está al alcance de todos. Y, modestia aparte, el mérito también es un poco nuestro. En todo ese tiempo, en Pululart hemos ',
  'project.team.heading.subtitle4': 'desarrollado y promocionado ',
  'project.team.heading.subtitle5': 'centenares de webs y tiendas online',
  'project.team.heading.subtitle6': 'Cientos de proyectos, sencillos y faraónicos, con los que hemos adquirido la experiencia y la solidez necesaria para ganarnos la ',
  'project.team.heading.subtitle7': 'confianza de nuestros clientes, ',
  'project.team.heading.subtitle8': 'que han ido creciendo con nosotros. Y a pesar de esta larga andadura, sentimos que aún estamos al comienzo de nuestro camino.',
  'project.team.heading.subtitle9': '¡Nuestro espíritu se mantiene joven! ',
  'project.team.heading.subtitle10': 'Porque estamos en constante evolución, buscando siempre las mejores vías para renovarnos y con nuestra curiosidad siempre intacta ;)',
  'project.team.octopus.p1a': '¿Tú?',
  'project.team.octopus.p1b': 'Escríbenos a cv@pululart.com',

  'project.team.workWithUs.main.title1': 'Trabaja con nosotros',
  'project.team.workWithUs.main.paragraph1': 'Si vives tus días a tope de energía, te encanta trabajar en equipo y buscas estar en constante evolución, entonces ¡este es tu sitio! Nos encantan las personas como tú, que lo dan todo en cada nuevo reto y que saben aprovechar las nuevas oportunidades',
  'project.team.workWithUs.main.paragraph2': '¡Te estamos esperando! 👇',
  'project.team.workWithUs.main.title2': 'Estamos buscando gente con estos perfiles',
  'project.team.workWithUs.main.list1': 'Programador Web:',
  'project.team.workWithUs.main.list1.paragraph': 'Buscamos pululeños en potencia con mucha iniciativa, tanto si eres desarrollador front-end como programador back-end. Si eres un@ friki del código y te encanta dar vida a nuevos proyectos, entonces ¡te estamos esperando!',
  'project.team.workWithUs.main.list2': 'Marketing digital:',
  'project.team.workWithUs.main.list2.paragraph': 'Buscamos a alguien como tú, creativ@, expert@ en copys y capaz de dominar cada nuevo cambio de algoritmo ;)',

  'project.team.workWithUs.form.title1': '¿Encajas en alguno de estos ',
  'project.team.workWithUs.form.title2': 'perfiles',
  'project.team.workWithUs.form.title3': '?',
  'project.team.workWithUs.form.paragraph1': 'Completa el siguiente formulario enviándonos tu CV y nos pondremos en contacto contigo.',

  'project.team.workWithUs.form.input1': 'Nombre',
  'project.team.workWithUs.form.input2': 'Apellidos',
  'project.team.workWithUs.form.input3': 'Localidad',
  'project.team.workWithUs.form.input4': 'Email',
  'project.team.workWithUs.form.input5': 'Teléfono',
  'project.team.workWithUs.form.input6': 'Adjunta aquí tu CV',
  'project.team.workWithUs.form.input7': 'Mensaje',

  'project.team.workWithUs.form.inputError.text': '*Por favor, completa este campo',
  'project.team.workWithUs.form.inputError.email': '*Añade un correo válido',
  'project.team.workWithUs.form.inputError.phone': '*Añade un teléfono válido',
  'project.team.workWithUs.form.inputError.pdf': '*Añade un archivo',
  'project.team.workWithUs.form.inputError.checkbox': '*Tienes que aceptar las políticas',

  'project.team.workWithUs.form.confirm.text1': 'He leído y acepto las ',
  'project.team.workWithUs.form.confirm.link1': 'políticas de protección de datos',
  'project.team.workWithUs.form.confirm.text2': ' y la ',
  'project.team.workWithUs.form.confirm.link2': 'política de formularios',
  'project.team.workWithUs.form.confirm.text3': ' de la web',

  'project.team.workWithUs.form.button.text1': 'Enviar',
  'project.team.workWithUs.form.button.text2': 'Datos enviados',

  'project.team.workWithUs.form.message.success': 'Tus datos se han enviado correctamente 😄 ¡Gracias por contactar con nosotros!',
  'project.team.workWithUs.form.message.error': '¡Vaya! Parece que no hemos podido enviar el formulario, por favor inténtalo de nuevo',

  'services.analytics.title1': '¿Cómo hacemos el ',
  'services.analytics.title2': 'análisis',
  'services.analytics.title3': ' de datos?',

  'services.servicesList.analytics.title4': '1. ESTABLECIMIENTO DE INDICADORES (KPIs)',
  'services.servicesList.analytics.text4': 'Determinamos qué indicadores analizar para conocer el rendimiento de tu site a fondo y para extraer información que nos ayude a conseguir tus objetivos.',
  'services.servicesList.analytics.title5': '2. MEDICIÓN',
  'services.servicesList.analytics.text5': 'Extraemos todos los datos disponibles para los KPIs establecidos y los tratamos para su posterior interpretación.',
  'services.servicesList.analytics.title6': '3. ANÁLISIS',
  'services.servicesList.analytics.text6': 'Realizamos un estudio detallado de la información extraída, a nivel macro y a nivel micro, para detectar tendencias, debilidades y fortalezas.',
  'services.servicesList.analytics.title7': '4. REPORTING',
  'services.servicesList.analytics.text7': 'Generamos informes simples y complejos que permitan visualizar el análisis y sus conclusiones y los compartimos contigo.',
  'services.servicesList.analytics.title8': '5. OPTIMIZACIÓN',
  'services.servicesList.analytics.text8': 'Establecemos qué ajustes es necesario realizar para mejorar el estado actual de la web, transformar usuarios en clientes e incrementar el número de conversiones.',

  'services.servicesList.analytics.title9': 'LAS HERRAMIENTAS QUE UTILIZAMOS',
  'services.servicesList.analytics.text9': 'Para llevar a cabo la medición, el análisis y el reporting utilizamos herramientas como Google Analytics Universal o Google Data Studio.',
  'services.servicesList.analytics.text10': 'Usamos programas que nos ayudan a extraer los datos con la mayor precisión posible y contamos con expertos capaces de procesarlos con rapidez.',

  'services.mailMarketing.title1': '¿Cómo hacemos ',
  'services.mailMarketing.title2': 'email marketing',
  'services.mailMarketing.title3': '?',

  'services.woocommerce.title1': '¿Cuáles son las ',
  'services.woocommerce.title2': 'ventajas',
  'services.woocommerce.title3': ' de utilizar WooCommerce para tu tienda online?',

  'services.woocommerce.servicesList.title1': 'Instalación rápida:',
  'services.woocommerce.servicesList.text1': 'Su instalación es sencilla y no requiere de muchos recursos. Permite poner en funcionamiento el ecommerce en tiempo relativamente corto.',
  'services.woocommerce.servicesList.title2': 'Entorno seguro:',
  'services.woocommerce.servicesList.text2': 'WooCommerce garantiza el pago seguro en las transacciones que realizan los clientes.',
  'services.woocommerce.servicesList.title3': 'Personalizable:',
  'services.woocommerce.servicesList.text3': 'Se puede crear una tienda online a medida y funcional que incluya características avanzadas  e integraciones específicas.',
  'services.woocommerce.servicesList.title4': 'Múltiples opciones:',
  'services.woocommerce.servicesList.text4': 'Permite vender cualquier cosa, incluyendo productos digitales y físicos, citas, productos de afiliados, etc.',
  'services.woocommerce.servicesList.title5': 'Usable:',
  'services.woocommerce.servicesList.text5': 'Fácil de utilizar. No es necesario tener conocimientos avanzados para realizar la gestión del inventario y de las ventas.',
  'services.woocommerce.servicesList.title6': 'Organización de productos:',
  'services.woocommerce.servicesList.text6': 'Permite añadir categorías, atributos y etiquetas para clasificar el contenido y facilitar la navegación y la compra de los usuarios.',
  'services.woocommerce.servicesList.title7': 'SEO friendly:',
  'services.woocommerce.servicesList.text7': 'WooCommerce facilita la aparición en los principales motores de búsqueda. Su integración con potentes plugins de SEO favorece todavía más el posicionamiento.',
  'services.woocommerce.servicesList.title8': 'Blog integrado:',
  'services.woocommerce.servicesList.text8': 'Cuenta con el blog de WordPress integrado, muy útil para llevar a cabo estrategias de SEO que contribuyan a aumentar el tráfico y las ventas.', 

  'services.ecommerceConsulting.title1': '¿Sobre qué te ',
  'services.ecommerceConsulting.title2': 'asesoramos',
  'services.ecommerceConsulting.title3': '?',

  'services.ecommerceConsulting.servicesList.title1': 'Modelo de negocio online:',
  'services.ecommerceConsulting.servicesList.text1': 'Analizamos tu empresa y tu situación actual. Te ayudamos a tomar decisiones estratégicas y a crear tu tienda online si todavía no tienes y te guiamos en la transición.',
  'services.ecommerceConsulting.servicesList.title2': 'Catálogo:',
  'services.ecommerceConsulting.servicesList.text2': 'Te aconsejamos para estructurar tu catálogo, incluyendo categorías, productos y filtros, y para mostrar información relevante que te ayude a vender.',
  'services.ecommerceConsulting.servicesList.title3': 'Gestión de ventas:',
  'services.ecommerceConsulting.servicesList.text3': 'Te ayudamos a manejar el software de gestión de ventas y a operar con él de manera fluída. Resolvemos cualquier duda que puedas tener al respecto.',
  'services.ecommerceConsulting.servicesList.title4': 'Implementaciones e integraciones:',
  'services.ecommerceConsulting.servicesList.text4': 'Establecemos qué implementaciones e integraciones son necesarias para que puedas cumplir los objetivos que buscas para tu comercio electrónico.',
  'services.ecommerceConsulting.servicesList.title5': 'Experiencia de compra:',
  'services.ecommerceConsulting.servicesList.text5': 'Te asesoramos para que tu web sea usable y navegable y para que los usuarios puedan realizar sus compras de manera cómoda y satisfactoria.',
  'services.ecommerceConsulting.servicesList.title6': 'Rendimiento:',
  'services.ecommerceConsulting.servicesList.text6': 'Detectamos cuáles son los problemas de rendimiento de tu ecommerce y te proponemos soluciones para atajarlos.',
  'services.ecommerceConsulting.servicesList.title7': 'Internacionalización:',
  'services.ecommerceConsulting.servicesList.text7': 'Te guiamos en la creación o adaptación de tu ecommerce para nuevos mercados, incluyendo la gestión logística y de productos.',

  'services.marketingConsulting.title1': '¿Sobre qué te ',
  'services.marketingConsulting.title2': 'asesoramos',
  'services.marketingConsulting.title3': '?',

  'services.marketingConsulting.servicesList.title1': 'Estrategia:',
  'services.marketingConsulting.servicesList.text1': 'Analizamos tu negocio y a tu competencia. Realizamos una auditoría a detalle y extraemos conclusiones. Diseñamos una hoja de ruta adecuada a tus necesidades, incluyendo aquellos canales digitales más apropiados para tu caso y para tu presupuesto.',
  'services.marketingConsulting.servicesList.title2': 'SEO:',
  'services.marketingConsulting.servicesList.text2': 'Revisamos tu posicionamiento actual y el estado técnico de tu site. Proponemos mejoras y pasos a seguir para alcanzar tus objetivos.',
  'services.marketingConsulting.servicesList.title3': 'Google Ads:',
  'services.marketingConsulting.servicesList.text3': 'Proponemos las campañas a realizar. Elegimos los emplazamientos más adecuados para tus anuncios. Te ayudamos a definir tu audiencia y a maximizar la conversión.',
  'services.marketingConsulting.servicesList.title4': 'Social Ads:',
  'services.marketingConsulting.servicesList.text4': 'Definimos las redes sociales donde publicitar tu empresa. Te guiamos para generar públicos personalizados y anuncios que generen ventas.',
  'services.marketingConsulting.servicesList.title5': 'Google My Business:',
  'services.marketingConsulting.servicesList.text5': 'Te ayudamos a dar de alta tu ficha de Google My Business en caso de que sea necesario y te asesoramos sobre qué estrategia seguir para conseguir sacarle el máximo partido.',
  'services.marketingConsulting.servicesList.title6': 'Redes sociales:',
  'services.marketingConsulting.servicesList.text6': 'Analizamos las redes sociales en las que tienes presencia. Definimos cuáles se ajustan más a tus necesidades. Trazamos un plan para alcanzar su máximo rendimiento.',
  'services.marketingConsulting.servicesList.title7': 'Email marketing:',
  'services.marketingConsulting.servicesList.text7': 'Te guiamos para segmentar tu base de datos de clientes y te ayudamos a definir los mensajes y la estrategia a seguir.',

  'project.contact.main.title1': 'Contacto',
  'project.contact.main.button.text1': 'Solicita tu presupuesto',
  'project.contact.main.button.text2': 'Ponte en contacto',
  'project.contact.main.businessInfo.phone': '981 91 34 88',
  'project.contact.main.businessInfo.location': 'Alcalde Puga y Parga 2, 2º 15006, A Coruña',

  'project.contact.main.businessInfo.schedule1.title': 'Horario de invierno',
  'project.contact.main.businessInfo.schedule1.text1': 'Lunes - Jueves:',
  'project.contact.main.businessInfo.schedule1.text2': '8:30-14:30 y 16:00-18:30',
  'project.contact.main.businessInfo.schedule1.text3': 'Viernes:',
  'project.contact.main.businessInfo.schedule1.text4': '8:30-14:30',
  'project.contact.main.businessInfo.schedule2.title': 'Horario de verano',
  'project.contact.main.businessInfo.schedule2.text1': '(15 de junio - 15 de septiembre)',
  'project.contact.main.businessInfo.schedule2.text2': 'Lunes - Viernes:',
  'project.contact.main.businessInfo.schedule2.text3': '8:00-15:00',

  'project.contact.contact.main.title1': 'Déjanos tu ',
  'project.contact.contact.main.underlineTitle1': 'mensaje!',
  'project.contact.contact.main.paragraph1': 'Rellena el siguiente formulario',
  'project.contact.contact.form.input1': '¿Cuál es tu nombre?*',
  'project.contact.contact.form.input2': '¿A dónde te podemos escribir un email?*',
  'project.contact.contact.form.input3': '¿Cuál es el nombre de tu negocio?',
  'project.contact.contact.form.input4': '¿Cuál es tu número de teléfono?*',
  'project.contact.contact.form.input5': '¿Cómo podemos ayudarte?',
  'project.contact.contact.form.inputError.text': '*Por favor, completa este campo',
  'project.contact.contact.form.inputError.email': '*Añade un correo válido',
  'project.contact.contact.form.inputError.phone': '*Añade un teléfono válido',
  'project.contact.contact.form.inputError.checkbox': '*Tienes que aceptar las políticas',
  'project.contact.contact.form.confirm.text1': 'He leído y acepto las ',
  'project.contact.contact.form.confirm.link1': 'políticas de protección de datos',
  'project.contact.contact.form.confirm.text2': ' y la ',
  'project.contact.contact.form.confirm.link2': 'política de formularios',
  'project.contact.contact.form.confirm.text3': ' de la web',     
  'project.contact.contact.form.button.text1': 'Enviar',
  'project.contact.contact.form.button.text2': 'Mensaje enviado',
  'project.contact.contact.form.message.success': 'Tu mensaje se ha enviado correctamente 😄 ¡Gracias por contactar con nosotros!',
  'project.contact.contact.form.message.error': '¡Vaya! Parece que no hemos podido enviar el formulario, por favor inténtalo de nuevo',

  'project.contact.budget.main.title1': 'Envíanos los datos de tu ',
  'project.contact.budget.main.underlineTitle1': 'proyecto',
  'project.contact.budget.main.paragraph1': 'Solicita tu presupuesto, es gratis y sin compromiso',
  'project.contact.budget.form.service.title1': '¿En qué servicio estás interesado?',
  'project.contact.budget.form.service.service1': 'Ecommerce',
  'project.contact.budget.form.service.service2': 'Marketing',
  'project.contact.budget.form.service.service3': 'Web',
  'project.contact.budget.form.service.service4': 'Otros',
  'project.contact.budget.form.service.input1': 'Explícanos que otro tipo de servicio necesitas',
  'project.contact.budget.form.details.title1': '¿Cuáles son los detalles de tu proyecto?',
  'project.contact.budget.form.details.input1': '¿Cómo podemos ayudarte?',
  'project.contact.budget.form.spend.title1': '¿Sabes cuánto quieres invertir?',
  'project.contact.budget.form.spend.option1': 'Si',
  'project.contact.budget.form.spend.option2': 'No',
  'project.contact.budget.form.spend.input1': '¿Cuál es tu nombre?*',
  'project.contact.budget.form.spend.input2': '¿A dónde te podemos escribir un email?*',
  'project.contact.budget.form.spend.input3': '¿Cuál es el nombre de tu negocio?',
  'project.contact.budget.form.spend.input4': '¿Cuál es tu número de teléfono?*',

  'cookies.mandatory.description1': 'Utilizada para la preferencia de comunidad.',
  'cookies.mandatory.description2': 'Utilizada para la aceptación de cookies no necesarias.',
  'cookies.mandatory.description3': 'Para almacenar si el usuario ha consentido o no al uso de cookies. No guarda ningún dato personal.',
  'cookies.mandatory.description4': 'Se usa para almacenar si el usuario ha consentido o no al uso de cookies. No guarda ningún dato personal.',
  'cookies.mandatory.duration': '1 año',
  'cookies.analytics.description1': 'Se utiliza para identificar mediante Google Analytics y distinguir a un usuario. Almacenan un identificador de cliente único (ID de cliente), que se genera aleatoriamente. Se utiliza para calcular las interacciones de los usuarios (visitas, los datos de usuarios, sesiones y campañas).',
  'cookies.analytics.duration1': '2 años',
  'cookies.analytics.description2': 'Se utiliza para diferenciar entre los diferentes objetos de seguimiento creados en la sesión y se actualiza cada vez que envía los datos a Google Analytics.',
  'cookies.analytics.duration2': '1 minuto',
  'cookies.analytics.duration3': '1 día',
  'cookies.analytics.duration4': 'Hasta 2 años',
  'cookies.window.text1': 'Utilizamos cookies propias y de terceros para mejorar la experiencia de navegación y ofrecer contenidos y publicidad de interés. Para obtener más información consulta nuestra ',
  'cookies.window.text2': 'Puedes ',
  'cookies.window.link.politic': 'Política de Cookies',
  'cookies.window.button.text': 'ACEPTAR Y CERRAR',
  'cookies.window.link.config': 'configurarlas o rechazar su uso clicando aquí',
  'cookies.modal.text1': 'Este sitio web utiliza cookies para mejorar su experiencia mientras navega por el sitio web. Fuera de estas cookies, las cookies que se clasifican como necesarias se almacenan en su navegador, ya que son esenciales para el funcionamiento de las funci',
  'cookies.modal.text1.full': 'Este sitio web utiliza cookies para mejorar su experiencia mientras navega por el sitio web. Fuera de estas cookies, las cookies que se clasifican como necesarias se almacenan en su navegador, ya que son esenciales para el funcionamiento de las funcionalidades básicas del sitio web. También utilizamos cookies de terceros que nos ayudan a analizar y comprender cómo utiliza este sitio web. Estas cookies se almacenarán en su navegador solo con su consentimiento. También tiene la opción de optar por no recibir estas cookies. Pero la exclusión voluntaria de algunas de estas cookies puede afectar su experiencia de navegación.',
  'cookies.modal.text.button.more': 'Mostrar más',
  'cookies.modal.text.button.less': 'Mostrar menos',
  'cookies.modal.table.title1': 'Analíticas',
  'cookies.modal.table.title2': 'Necesarias',
  'cookies.modal.title': 'Resumen de privacidad',
  'cookies.modal.table.header1': 'Cookie',
  'cookies.modal.table.header2': 'Duración',
  'cookies.modal.table.header3': 'Descripción',
  'cookies.modal.button.text1': 'Guardar y cerrar',
  'cookies.modal.button.text2': 'Cancelar',
  'cookies.modal.table.text.analytics': 'Las cookies que pueden no ser particularmente necesarias para el funcionamiento del sitio web y se utilizan específicamente para recopilar datos personales del usuario a través de análisis, anuncios y otros contenidos incrustados se denominan cookies no necesarias. Es obligatorio obtener el consentimiento del usuario antes de ejecutar estas cookies en su sitio web.',
  'cookies.modal.table.text.mandatory': 'Las cookies necesarias son absolutamente esenciales para que el sitio web funcione correctamente. Esta categoría solo incluye cookies que garantizan funcionalidades básicas y características de seguridad del sitio web. Estas cookies no almacenan ninguna información personal.',

  'project.projectpage.general.title': 'Proyectos',

  'privacy.title': 'Política de privacidad',
  'privacy.law': 'En cumplimiento del artículo 10 de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y Comercio Electrónico (LSSICE) a continuación se detallan los datos identificativos de la empresa: ',
  'privacy.denomination.social': 'Denominación Social: ',
  'privacy.denomination': 'SL',
  'privacy.name.comercial': 'Nombre Comercial: ',
  'privacy.name': 'Pululart SL',
  'privacy.address.social': 'Domicilio Social: ',
  'privacy.address': 'Alcalde Puga y Parga Nº 2 2º planta, 15006 A Coruña',
  'privacy.cif': 'CIF / NIF: ',
  'privacy.cif.code': 'B15845860',
  'privacy.telephone': 'Teléfono: ',
  'privacy.telephone.number': '981 91 34 88',
  'privacy.email': 'E-mail: ',
  'privacy.email.address': 'info@pululart.com',
  'privacy.name.domain': 'Nombre de dominio: ',
  'privacy.domain': 'pululart.com',
  'privacy.inscription': 'Empresa inscrita en el Registro Mercantil de A Coruña, Tomo 2.621, Folio 95, hoja C-29.285',
  'privacy.explanation': 'Mediante esta política de privacidad nos comprometemos a asegurar que su información personal se encuentre protegida y no se utilice de forma indebida. En este documento explicamos quién es el responsable de tratamiento, con qué finalidad se va a tratar su información personal, la legitimación para el tratamiento, cómo la recabamos, cómo la utilizamos, los derechos que le asisten y también se explican los procesos que hemos dispuesto para proteger su privacidad. Al facilitarnos su información personal y utilizar nuestros sitios web, entendemos que ha leído y comprendido los términos relacionados con la información de protección de datos de carácter personalque se exponen. La asume la responsabilidad de cumplir con la legislación vigente en materia de protección de datos nacional y europea, y tienen el objetivo de tratar sus datos de manera lícita, leal y transparente.',
  
  'privacy.treatment': 'RESPONSABLE DEL TRATAMIENTO',
  'privacy.treatment.content' : 'Pululart SL',
  
  'privacy.purpose': 'FINALIDAD',
  'privacy.purpose1': 'Gestión comercial, administrativa, fiscal y contable.',
  'privacy.purpose2': 'Envío de información sobre nuestros productos.',
  'privacy.purpose3': 'Responder a sus solicitudes de información a través de la web.',
  'privacy.purpose4': 'Envío de boletines.',

  'privacy.legitimation': 'LEGITIMACIÓN',
  'privacy.legitimation1': 'Para el cumplimiento de un contrato y/o relación comercial.',
  'privacy.legitimation2': 'Para el cumplimiento de distintas obligaciones legales.',
  'privacy.legitimation3': 'Por interés legítimo, por ejemplo, por motivos de seguridad, prevención del fraude.',
  'privacy.legitimation4': 'Para mejorar nuestros servicios y productos, o para gestionar las solicitudes,consultas o posibles reclamaciones que pudieran surgir.',
  'privacy.legitimation5': 'Con su consentimiento expreso.',

  'privacy.conservation': 'CONSERVACIÓN',
  'privacy.conservation.text': 'Solo almacenamos su información personal en la medida en que la necesitamos a fin de poder utilizarla según la finalidad por la que fue recabada, y según la base jurídica del tratamiento de la misma de conformidad con la ley aplicable. Mantendremos su información personal mientras exista una relación contractual y/o comercial con usted, o mientras usted no ejerza su derecho de supresión, cancelación y/o limitación del tratamiento de sus datos. En estos casos, mantendremos la información debidamente bloqueada, sin darle ningún uso, mientras pueda ser necesaria para el ejercicio o defensa de reclamaciones o pueda derivarse algún tipo de responsabilidad judicial, legal o contractual de su tratamiento, que deba ser atendida y para lo cual sea necesaria su recuperación.',

  'privacy.addressee': 'DESTINATARIOS',
  'privacy.addressee.text': 'Si usted nos ha dado su consentimiento la información que nos facilita será comunicada a empresas o profesionales directamente relacionados con el responsable: Agencias de viaje, aseguradoras, financieras...',

  'privacy.rights': 'DERECHOS DE LOS INTERESADOS',
  'privacy.rights.text': 'Los interesados podrán ejercer los derechos de acceso, rectificación, supresión, portabilidad y la limitación u oposición, dirigiéndose por escrito, acompañando fotocopia del DNI a: Pululart SL, Alcalde Puga y Parga Nº 2 2º planta, 15006 A Coruña ó info@pululart.com',

  'privacy.transfer': 'TRANSFERENCIA INTERNACIONAL',
  'privacy.transfer.text': 'La información personal que recabamos reside en España, no se realizan transferencias internacionales de datos.',

  'privacy.links': 'ENLACES A SITIOS WEB DE TERCEROS',
  'privacy.links.text': 'En el caso que suministremos enlaces a sitios web que no son operados ni controlados por nosotros, será puntualmente informado. Ya que no disponemos control alguno sobre esos sitios ni somos responsables del contenido de los mismos.',

  'privacy.minors': 'DATOS DE MENORES',
  'privacy.minors.text': 'No recabamos información personal alguna de menores deliberadamente, si no es con la autorización del padre/madre o tutor. Si usted es menor de edad, por favor no intente registrarse como usuario de nuestros sitios web. Si descubrimos que por error hemos obtenido información personal de un menor, eliminaremos dicha información lo antes posible. Hemos implementado las medidas técnicas y organizativas adecuadas con la finalidad de proteger sus datos personales, y estas medidas son revisadas periodicamente. La Entidad se reserva el derecho a modificar la presente política para adaptarla a novedades legislativas o jurisprudenciales así como a prácticas de la industria. En dichos supuestos, La Entidad anunciará en esta página los cambios introducidos con razonable antelación a su puesta en práctica. También le informamos puede dirigirse a la Autoridad de Control en materia de Protección de Datos competente para obtener información adicional o presentar una reclamación.',

  'privacy.link': 'Ver certificado de cumplimiento L.O.P.D.',

  'legalwarning.title': 'Aviso Legal',
  'legalwarning.generalconditions': 'Condiciones generales de uso',
  'legalwarning.generalconditions.text': 'Las presentes Condiciones Generales regulan el uso (incluyendo el mero acceso) de las páginas integrantes de pululart.com, incluidos los contenidos y servicios puestos a disposición en ellas. Toda persona que acceda a pululart.com acepta someterse a las Condiciones Generales vigentes en cada momento en ella.',

  'legalwarning.personaldata': 'Datos personales que recabamos y como lo hacemos',
  'legalwarning.personaldata.text': 'Lee la Política de Privacidad',

  'legalwarning.commitment': 'Compromisos y obligaciones',
  'legalwarning.commitment.text1': 'El usuario queda informado, y acepta, que el acceso a pululart.com no supone, en modo alguno, el inicio de una relación comercial con la empresa. De esta forma, el usuario se compromete a utilizar pululart.com, sus servicios y contenidos sin contravenir la legislación vigente, la buena fe y el orden público. Queda prohibido el uso de pululart.com, con fines ilícitos o lesivos, o que, de cualquier forma, puedan causar perjuicio o impedir el normal funcionamiento de la misma. Respecto de los contenidos, se prohíbe:',
  'legalwarning.commitment.list1': 'Su reproducción, distribución o modificación, total o parcial, a menos que se cuente con la autorización de sus legítimos titulares.',
  'legalwarning.commitment.list2': 'Cualquier vulneración de los derechos del prestador o de los legítimos titulares.',
  'legalwarning.commitment.list3': 'Su utilización para fines comerciales o publicitarios.',
  'legalwarning.commitment.text2': 'En la utilización de pululart.com el usuario se compromete a no llevar a cabo ninguna conducta que pudiera dañar la imagen, los intereses y los derechos de la empresa. o de terceros o que pudiera dañar, inutilizar o sobrecargar pululart.com o que impidiera, de cualquier forma, su normal utilización.',

  'legalwarning.property': 'Derechos de propiedad intelectual e industrial',
  'legalwarning.property.text': 'El usuario conoce y acepta que la totalidad de pululart.com, conteniendo sin carácter exhaustivo el texto, software, contenidos (incluyendo estructura, selección, ordenación y presentación de los mismos) podcast, fotografías, material audiovisual y gráficos, está protegida por marcas, derechos de autor y otros derechos legítimos, de acuerdo con los tratados internacionales en los que España es parte y otros derechos de propiedad y leyes de España. En virtud de lo dispuesto en la Ley de Propiedad Intelectual, quedan expresamente prohibidas la reproducción, la distribución y la comunicación pública, incluida su modalidad de puesta a disposición, de la totalidad o parte de los contenidos de pululart.com, con fines comerciales, en cualquier soporte y por cualquier medio técnico, sin la autorización de la empresa. El usuario se compromete a respetar los derechos de Propiedad Intelectual e Industrial titularidad de EL la empresa.',

  'cookiespolicy.title': 'Política de cookies',
  'cookiespolicy.opening': 'Le informamos acerca del uso de las cookies en las páginas web. Las cookies son archivos que se pueden descargar en su equipo a través de las páginas web. Son herramientas que tienen un papel esencial para la prestación de numerosos servicios de la sociedad de la información. Entre otros, permiten a una página web almacenar y recuperar información sobre los hábitos de navegación de un usuario o de su equipo y, dependiendo de la información obtenida, se pueden utilizar para reconocer al usuario y mejorar el servicio ofrecido.',
  
  'cookiespolicy.entity': 'Según quien sea la entidad que gestione el dominio desde donde se envían las cookies y trate los datos que se obtengan',
  'cookiespolicy.proper': 'Cookies propias: ',
  'cookiespolicy.proper.text': 'Son aquéllas que se envían al equipo terminal del usuario desde un equipo o dominio gestionado por el propio editor y desde el que se presta el servicio solicitado por el usuario.',
  
  'cookiespolicy.others': 'Cookies de terceros: ',
  'cookiespolicy.others.text': 'Son aquéllas que se envían al equipo terminal del usuario desde un equipo o dominio que no es gestionado por el editor, sino por otra entidad que trata los datos obtenidos través de las cookies.',
  
  'cookiespolicy.purpose': 'Según la finalidad para la que se traten los datos obtenidos a través de las cookies',
  'cookiespolicy.purpose.technical': 'Cookies técnicas: ',
  'cookiespolicy.purpose.technical.text': 'Son aquéllas que permiten al usuario la navegación a través de una Página Web, plataforma o Página Web y la utilización de las diferentes opciones o servicios que en ella existan como, por ejemplo, controlar el tráfico y la comunicación de datos, identificar la sesión, acceder a partes de acceso restringido.',
  'cookiespolicy.purpose.customization': 'Cookies de personalización: ',
  'cookiespolicy.purpose.customization.text': 'Son aquéllas que permiten al usuario acceder al servicio con algunas características de carácter general predefinidas en función de una serie de criterios en el terminal del usuario como por ejemplo serian el idioma, el tipo de navegador.',
  'cookiespolicy.purpose.analysis': 'Cookies de análisis: ',
  'cookiespolicy.purpose.analysis.text': 'Son aquéllas que permiten al responsable de las mismas, el seguimiento y análisis del comportamiento de los usuarios a los que están vinculadas. La información recogida mediante este tipo de cookies se utiliza en la medición de la actividad de los sitios web, Página Web o plataforma y para la elaboración de perfiles de navegación de los usuarios.',
  'cookiespolicy.purpose.ads': 'Cookies publicitarias: ',
  'cookiespolicy.purpose.ads.text': 'Son aquéllas que permiten la gestión, de la forma más eficaz posible, de los espacios publicitarios.',
  'cookiespolicy.purpose.behavioural': 'Cookies de publicidad comportamental: ',
  'cookiespolicy.purpose.behavioural.text': 'Estas cookies almacenan información del comportamiento de los usuarios obtenida a través de la observación continuada de sus hábitos de navegación, lo que permite desarrollar un perfil específico para mostrar publicidad en función del mismo.',
  'cookiespolicy.purpose.data': 'Para más información a este respecto puede consultar la Guía sobre el uso de las cookies de la Agencia Española de Protección de Datos.',

  'cookiespolicy.used': 'Cookies utilizadas en la web',
  'cookiespolicy.used.text': 'Las páginas web utilizan Google Analytics , un servicio de análisis de accesos web desarrollada por Google, que permite la medición y análisis de la navegación en las páginas web. Según la tipología anterior se trata de cookies propias, de sesión y de análisis. Puede encontrar más información al respecto e inhabilitar el uso de estas cookies en la política de privacidad de Google. A través de la analítica web se obtiene información relativa al número de usuarios que acceden a la web, el número de páginas vistas, la frecuencia y repetición de las visitas, su duración, el navegador utilizado, el operador que presta el servicio, el idioma, el terminal que utiliza, o la ciudad a la que está asignada su dirección IP. Información que posibilita un mejor y más apropiado servicio por parte de este portal. La web de usa además una cookie propia de identificación de sesión que se utiliza para el mantenimiento de la sesión de usuario en el navegador. Se trata de una cookie exceptuada y técnica utilizada para garantizar el correcto funcionamiento de la página.',

  'cookiespolicy.configuration': 'Cómo modificar la configuración de las cookies',
  'cookiespolicy.configuration.help': 'Usted puede restringir, bloquear o borrar las cookies de esta o cualquier otra página web, utilizando su navegador. En cada navegador la operativa es diferente, la función de "Ayuda" le mostrará cómo hacerlo.',
  'cookiespolicy.configuration.explorer': 'Internet Explorer',
  'cookiespolicy.configuration.firefox': 'FireFox',
  'cookiespolicy.configuration.chrome': 'Chrome',
  'cookiespolicy.configuration.safari': 'Safari',
  'cookiespolicy.configuration.more': 'Además, también puede gestionar el almacén de cookies en su navegador a través de herramientas como las siguientes:',
  'cookiespolicy.configuration.more.ghostery': 'Ghostery',
  'cookiespolicy.configuration.more.choices': 'Your online choices',

  'formpolicy.title': 'Política de formularios',
  'formpolicy.info.text1': 'De conformidad con lo establecido en la normativa vigente de Protección de Datos de Carácter Personal, le informamos que sus datos se incorporarán (o actualizarán) a nuestro sistema de tratamiento con la finalidad de gestionar los datos de los clientes/usuarios que accedan al formulario de contacto de la página Web de la entidad.',
  'formpolicy.info.text2': 'Le informamos que procederemos a tratar sus datos de manera lícita, leal, transparente, adecuada, pertinente, limitada, exacta y actualizada. Es por ello que nos comprometemos a adoptar todas las medidas razonables para que estos se supriman o rectifiquen sin dilación cuando sean inexactos.',
  'formpolicy.info.text3': 'De acuerdo con los derechos que le confiere la normativa vigente en Protección de Datos de Carácter Personal podrá ejercer los derechos de acceso, rectificación, limitación de tratamiento, supresión, portabilidad y oposición; dirigiendo su petición a la dirección ',
  'formpolicy.info.text4': ' A su vez, le informamos que puede contactar con el Delegado de Protección de Datos de la empresa , dirigiéndose por escrito a la dirección de correo ',
  'formpolicy.info.text5': ' o al teléfono ',
  'formpolicy.info.text6': 'Podrá dirigirse a la Autoridad de Control competente para presentar la reclamación que considere oportuna.',
  'formpolicy.info.mail1': 'info@pululart.com',
  'formpolicy.info.mail2': 'legal@nordat.es',
  'formpolicy.info.phone1': '981143858',

  'notFound.route.text': 'nos hemos perdido',
  'notFound.messagesList.message.name1': 'Fede',
  'notFound.messagesList.message.text1.strong': '¡Vaya, parece que nos hemos perdido!',
  'notFound.messagesList.message.text1': ' Este 404 no es lo que buscábamos.',
  'notFound.messagesList.message.name2': 'Fran',
  'notFound.messagesList.message.text2': 'Esto es un callejón sin salida. ¿Cómo salimos de aquí?',
  'notFound.messagesList.message.name3': 'Mou',
  'notFound.messagesList.message.text3': 'Hmm, esto no puede ser 🤦🏻‍♂️. Tenemos dos opciones, o volvemos a donde estábamos, o volvemos a empezar.',
  'notFound.messagesList.message.name4': 'Fede',
  'notFound.messagesList.message.text4': 'Decidid, que se nos hace tarde.',
  'notFound.buttons.text1': 'Volver al inicio',
  'notFound.buttons.text2': 'Ir atrás',

  'publicfunds.h1.text': 'Fondos públicos',
  'publicfunds.title.text': 'Incentivos a la contratación de personas jóvenes inscritas en el Sistema Nacional de Garantía Juvenil en Galicia 2023',
  'publicfunds.subtitle.text': 'PULULART SL ha sido beneficiario de 18.000 € para la contratación de dos jóvenes, mejorando la empleabilidad y la inserción profesional de las personas incluidas como beneficiarias en el fichero del Sistema nacional de garantía juvenil.'

};
