import {combineReducers} from 'redux';

const initialState = {
  featured: [
    {
      route: "/images/projects/brands/abeconsa.webp",
      name: "Abeconsa"
    },
    {
      route: "/images/projects/brands/batilas.webp",
      name: "Batilas"
    },
    {
      route: "/images/projects/brands/botica.webp",
      name: "Botica"
    },
    {
      route: "/images/projects/brands/castro.webp",
      name: "Castro Farmacias"
    },
    {
      route: "/images/projects/brands/cavea.webp",
      name: "Cavea"
    },
    {
      route: "/images/projects/brands/cenor.webp",
      name: "Cenor"
    },
    {
      route: "/images/projects/brands/cetarea-burela.webp",
      name: "Cetarea Burela"
    },
    {
      route: "/images/projects/brands/claudio.webp",
      name: "Claudio"
    },
    {
      route: "/images/projects/brands/cube.webp",
      name: "Cube"
    },
    {
      route: "/images/projects/brands/euronet.webp",
      name: "Euronet"
    },
    {
      route: "/images/projects/brands/gadis.webp",
      name: "Gadis"
    },
    {
      route: "/images/projects/brands/gadisa.webp",
      name: "Gadisa"
    },
    {
      route: "/images/projects/brands/galicine.webp",
      name: "Galicine"
    },
    {
      route: "/images/projects/brands/garrote.webp",
      name: "Garrote"
    },
    {
      route: "/images/projects/brands/goymar.webp",
      name: "Goymar"
    },
    {
      route: "/images/projects/brands/grunver.webp",
      name: "Grunver"
    },
    {
      route: "/images/projects/brands/ifa.webp",
      name: "Ifa"
    },
    {
      route: "/images/projects/brands/kalandraka.webp",
      name: "Kalandraka"
    },
    {
      route: "/images/projects/brands/leiser.webp",
      name: "Leiser"
    },
    {
      route: "/images/projects/brands/mmg.webp",
      name: "Mmg"
    },
    {
      route: "/images/projects/brands/regolodos.webp",
      name: "Regolodos"
    },
    {
      route: "/images/projects/brands/rsd-hipica.webp",
      name: "Rsd Hipica"
    },
    {
      route: "/images/projects/brands/tejas-verea.webp",
      name: "Tejas Verea"
    },
    {
      route: "/images/projects/brands/termaria.webp",
      name: "Termaria"
    }
  ]
};

const featured = (state = initialState.featured, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const reducer = combineReducers({
    featured,
});

export default reducer;
