const getModuleState = (state) => state.contact;

export const getPageInfo = (state) => getModuleState(state).mainInfo;

export const getContactInfo = (state) => getModuleState(state).contact.mainInfo;
export const getContactInputs = (state) => getModuleState(state).contact.inputsList;
export const getContactInputError = (state) => getModuleState(state).contact.inputError;
export const getContactConfirm = (state) => getModuleState(state).contact.confirmForm;
export const getContactSend = (state) => getModuleState(state).contact.sendForm;
export const getContactSubmitMessages = (state) => getModuleState(state).contact.submitMessages;

export const getBudgetInfo = (state) => getModuleState(state).budget.mainInfo;
export const getBudgetInputsGroups = (state) => getModuleState(state).budget.inputsGroups;