import { combineReducers } from "redux";

const initialState = {
    mainInfo: {
        title: "project.contact.main.title1",
        buttons: [
            "project.contact.main.button.text1",
            "project.contact.main.button.text2"
        ],
        businessInfo: {
            mainImage: {
                src: "/images/contact/contact.webp",
                alt: "telefono-maps"
            },

            phone: "project.contact.main.businessInfo.phone",
            location: "project.contact.main.businessInfo.location",
            schedule: [
                [
                    "project.contact.main.businessInfo.schedule1.title",
                    "project.contact.main.businessInfo.schedule1.text1",
                    "project.contact.main.businessInfo.schedule1.text2",
                    "project.contact.main.businessInfo.schedule1.text3",
                    "project.contact.main.businessInfo.schedule1.text4"
                ],
                [
                    "project.contact.main.businessInfo.schedule2.title",
                    "project.contact.main.businessInfo.schedule2.text1",
                    "project.contact.main.businessInfo.schedule2.text2",
                    "project.contact.main.businessInfo.schedule2.text3"
                ]                
            ]
        }
    },

    contact: {
        mainInfo: {
            mainTitle: "project.contact.contact.main.title1",
            underlineTitle: "project.contact.contact.main.underlineTitle1",
            paragraph1: "project.contact.contact.main.paragraph1"
        },
        inputsList: [
            "project.contact.contact.form.input1",
            "project.contact.contact.form.input2",
            "project.contact.contact.form.input3",
            "project.contact.contact.form.input4",
            "project.contact.contact.form.input5",
        ],
        inputError: {
            text: "project.contact.contact.form.inputError.text",
            email: "project.contact.contact.form.inputError.email",
            phone: "project.contact.contact.form.inputError.phone",
            checkbox: "project.contact.contact.form.inputError.checkbox",
        },
        confirmForm: {
            textStart: "project.contact.contact.form.confirm.text1",
            linkFirst: "project.contact.contact.form.confirm.link1",
            textMiddle: "project.contact.contact.form.confirm.text2",
            linkLast: "project.contact.contact.form.confirm.link2",
            textEnd: "project.contact.contact.form.confirm.text3",
        },
        sendForm: {
            button: "project.contact.contact.form.button.text1",
            buttonSuccess: "project.contact.contact.form.button.text2"
        },
        submitMessages: {
            messageSuccess: "project.contact.contact.form.message.success",
            messageError: "project.contact.contact.form.message.error"
        }  
    },

    budget: {
        mainInfo: {
            mainTitle: "project.contact.budget.main.title1",
            underlineTitle: "project.contact.budget.main.underlineTitle1",
            paragraph1: "project.contact.budget.main.paragraph1"
        },
        inputsGroups: {
            serviceGroup: {
                title: "project.contact.budget.form.service.title1",
                servicesList: [
                    "project.contact.budget.form.service.service1",
                    "project.contact.budget.form.service.service2",
                    "project.contact.budget.form.service.service3",
                    "project.contact.budget.form.service.service4"
                ],
                inputsList: [
                    "project.contact.budget.form.service.input1"
                ]
            },
            detailsGroup: {
                title: "project.contact.budget.form.details.title1",
                inputsList: [
                    "project.contact.budget.form.details.input1"
                ]
            },
            spendGroup: {
                title: "project.contact.budget.form.spend.title1",
                options: [
                    "project.contact.budget.form.spend.option1",
                    "project.contact.budget.form.spend.option2"
                ],
                inputsList: [
                    "project.contact.budget.form.spend.input1",
                    "project.contact.budget.form.spend.input2",
                    "project.contact.budget.form.spend.input3",
                    "project.contact.budget.form.spend.input4"
                ]
            },
        }
    }
}

const mainInfo = (state = initialState.mainInfo, action) => {
    switch (action.type) {
        default:
            return state;
    }
};

const contact = (state = initialState.contact, action) => {
    switch (action.type) {
        default:
            return state;
    }
};

const budget = (state = initialState.budget, action) => {
    switch (action.type) {
        default:
            return state;
    }
};

const reducer = combineReducers({
    mainInfo,
    contact,
    budget
});


export default reducer;
