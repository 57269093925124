import { combineReducers, legacy_createStore } from 'redux';

import app from '../modules/app';
import projects from '../modules/projects';
import brands from '../modules/brands';
import services from '../modules/services';
import team from '../modules/team';
import cookies from '../modules/cookies';
import legal from '../modules/legal';
import contact from '../modules/contact';
import notFound from '../modules/notFound';


const reducer = combineReducers({
  app: app.reducer,
  projects: projects.reducer,
  brands: brands.reducer,
  services: services.reducer,
  team: team.reducer,
  cookies: cookies.reducer,
  legal: legal.reducer,
  contact: contact.reducer,
  notFound: notFound.reducer
});

export default reducer;
