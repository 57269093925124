import {combineReducers} from 'redux';

const initialState = {
  pageTitles: {
    privacyPolicy: {
      title: "privacy.title"
    },
    legalWarning: {
      title: 'legalwarning.title'
    },
    cookiesPolicy: {
      title: 'cookiespolicy.title'
    },
    formPolicy: {
      title: 'formpolicy.title'
    }
  },
  businessInfo: {
    title: "",
    law: "privacy.law",
    infoList : [
      {
        name: "privacy.denomination.social",
        info: "privacy.denomination"
      },
      {
        name: "privacy.name.comercial",
        info: "privacy.name"
      },
      {
        name: "privacy.address.social",
        info: "privacy.address"
      },
      {
        name: "privacy.cif",
        info: "privacy.cif.code"
      },
      {
        name: "privacy.telephone",
        info: "privacy.telephone.number"
      },
      {
        name: "privacy.email",
        info: "privacy.email.address"
      },
      {
        name: "privacy.name.domain",
        info: "privacy.domain"
      },
      {
        info: "privacy.inscription",
      }
    ],
    explanation: "privacy.explanation"
  },
  pageContent: [
    {
      title: "privacy.treatment",
      content: "privacy.treatment.content"
    },
    {
      title: "privacy.purpose",
      content: [
        {
          info: "privacy.purpose1"
        },
        {
          info: "privacy.purpose2"
        },
        {
          info: "privacy.purpose3"
        },
        {
          info: "privacy.purpose4"
        },
      ]
    },
    {
      title: "privacy.legitimation",
      content: [
        {
          info: "privacy.legitimation1"
        },
        {
          info: "privacy.legitimation2"
        },
        {
          info: "privacy.legitimation3"
        },
        {
          info: "privacy.legitimation4"
        },
        {
          info: "privacy.legitimation5"
        },
      ]
    },
    {
      title: "privacy.conservation",
      content: "privacy.conservation.text"
    },
    {
      title: "privacy.addressee",
      content: "privacy.addressee.text"
    },
    {
      title: "privacy.rights",
      content: "privacy.rights.text"
    },
    {
      title: "privacy.transfer",
      content: "privacy.transfer.text"
    },
    {
      title: "privacy.links",
      content: "privacy.links.text"
    },
    {
      title: "privacy.minors",
      content: "privacy.minors.text"
    },
  ],
  legalwarning: [
    {
      title: "legalwarning.generalconditions",
      content: "legalwarning.generalconditions.text"
    },
    {
      title: "legalwarning.personaldata",
      content: "legalwarning.personaldata.text"
    },
    {
      title: "legalwarning.commitment",
      content: "legalwarning.commitment.text1"
    },
    {
      title: "",
      content: [
        {
          info: "legalwarning.commitment.list1"
        },
        {
          info: "legalwarning.commitment.list2"
        },
        {
          info: "legalwarning.commitment.list3"
        },
      ]
    },
    {
      title: "",
      content: "legalwarning.commitment.text2"
    },
    {
      title: "legalwarning.property",
      content: "legalwarning.property.text"
    },
  ],
  cookiespolicy: [
    {
      title: "",
      content: "cookiespolicy.opening"
    },
    {
      title: "cookiespolicy.others",
      content: "cookiespolicy.others.text"
    },
    {
      title: "cookiespolicy.entity",
      content: [
        {
          name: "cookiespolicy.proper",
          info: "cookiespolicy.proper.text"
        },
        {
          name: "cookiespolicy.others",
          info: "cookiespolicy.others.text"
        }
      ]
    },
    {
      title: 'cookiespolicy.purpose',
      content: [
        {
          name: "cookiespolicy.purpose.technical",
          info: "cookiespolicy.purpose.technical.text"
        },
        {
          name: "cookiespolicy.purpose.customization",
          info: "cookiespolicy.purpose.customization.text"
        },
        {
          name: "cookiespolicy.purpose.analysis",
          info: "cookiespolicy.purpose.analysis.text"
        },
        {
          name: "cookiespolicy.purpose.ads",
          info: "cookiespolicy.purpose.ads.text"
        },
        {
          name: "cookiespolicy.purpose.behavioural",
          info: "cookiespolicy.purpose.behavioural.text"
        },
      ]
    },
    {
      title: "",
      content: "cookiespolicy.purpose.data"
    },
    {
      title: "cookiespolicy.used",
      content: "cookiespolicy.used.text"
    },
    {
      title: "cookiespolicy.configuration",
      content: "cookiespolicy.configuration.help"
    },
    {
      title: "",
      content: [
        {
          info: "cookiespolicy.configuration.explorer"
        },
        {
          info: "cookiespolicy.configuration.firefox"
        },
        {
          info: "cookiespolicy.configuration.chrome"
        },
        {
          info: "cookiespolicy.configuration.safari"
        },
      ]
    },
    {
      title: "",
      content: "cookiespolicy.configuration.more"
    },
    {
      title: "",
      content: [
        {
          info: "cookiespolicy.configuration.more.ghostery"
        },
        {
          info: "cookiespolicy.configuration.more.choices"
        },
      ]
    },
  ],
  formPolicy: {
    texts: [
        'formpolicy.info.text1',
        'formpolicy.info.text2',
        'formpolicy.info.text3',
        'formpolicy.info.text4',
        'formpolicy.info.text5',
        'formpolicy.info.text6'
    ],
    emails: [
        'formpolicy.info.mail1',
        'formpolicy.info.mail2'
    ],
    phones: [
        'formpolicy.info.phone1'
    ]
  }
};

const pageTitles = (state = initialState.pageTitles, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const businessInfo = (state = initialState.businessInfo, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const pageContent = (state = initialState.pageContent, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const legalWarning = (state = initialState.legalwarning, action) => {
  switch (action.type) {
    default:
      return state;
  }
}

const cookiePolicy = (state = initialState.cookiespolicy, action) => {
  switch (action.type) {
    default:
      return state;
  }
}

const formPolicy = (state = initialState.formPolicy, action) => {
    switch (action.type) {
        default:
            return state;
    }
}
  
const reducer = combineReducers({
  businessInfo,
  pageContent,
  legalWarning,
  pageTitles,
  cookiePolicy,
  formPolicy
});

export default reducer;