const getModuleState = (state) => state.services;

export const getEcommerceInfo = (state) => getModuleState(state).ecommerce;
export const getEcommerceServicesList = (state) => getModuleState(state).ecommerce.servicesList;
export const getEcommerceExtraInfo = (state) => getModuleState(state).ecommerce.extraInfo;
export const getEcommerceKnowMore = (state) => getModuleState(state).ecommerce.knowMore;
export const getEcommerceYouNeed = (state) => getModuleState(state).ecommerce.youNeed;
export const getEcommerceMoreInfoLinks = (state) => getModuleState(state).ecommerce.moreInfoLinks;
export const getEcommerceChooseUs = (state) => getModuleState(state).ecommerce.whyChooseUs;

export const getCorporativeWebInfo = (state) => getModuleState(state).corporativeWeb;
export const getCorporativeWebServicesList = (state) => getModuleState(state).corporativeWeb.servicesList;
export const getCorporativeWebKnowMore = (state) => getModuleState(state).corporativeWeb.knowMore;
export const getCorporativeWebYouNeed = (state) => getModuleState(state).corporativeWeb.youNeed;
export const getCorporativeWebMoreInfoLinks = (state) => getModuleState(state).corporativeWeb.moreInfoLinks;
export const getCorporativeWebChooseUs = (state) => getModuleState(state).corporativeWeb.whyChooseUs;

export const getMarketingInfo = (state) => getModuleState(state).marketing;
export const getMarketingServicesList = (state) => getModuleState(state).marketing.servicesList;
export const getMarketingKnowMore = (state) => getModuleState(state).marketing.knowMore;
export const getMarketingChooseUs = (state) => getModuleState(state).marketing.whyChooseUs;
export const getMarketingYouNeed = (state) => getModuleState(state).marketing.youNeed;
export const getMarketingMoreInfoLinks = (state) => getModuleState(state).marketing.moreInfoLinks;

export const getSeoInfo = (state) => getModuleState(state).seo;
export const getSeoServicesList = (state) => getModuleState(state).seo.servicesList;
export const getSeoKnowMore = (state) => getModuleState(state).seo.knowMore;
export const getSeoChooseUs = (state) => getModuleState(state).seo.whyChooseUs;
export const getSeoYouNeed = (state) => getModuleState(state).seo.youNeed;
export const getSeoMoreInfoLinks = (state) => getModuleState(state).seo.moreInfoLinks;

export const getGmbInfo = (state) => getModuleState(state).gmb;
export const getGmbServicesList = (state) => getModuleState(state).gmb.servicesList;
export const getGmbKnowMore = (state) => getModuleState(state).gmb.knowMore;
export const getGmbChooseUs = (state) => getModuleState(state).gmb.whyChooseUs;
export const getGmbYouNeed = (state) => getModuleState(state).gmb.youNeed;
export const getGmbMoreInfoLinks = (state) => getModuleState(state).gmb.moreInfoLinks;

export const getDigitalMarketingInfo = (state) => getModuleState(state).digitalMarketing;
export const getDigitalMarketingServicesList = (state) => getModuleState(state).digitalMarketing.servicesList;
export const getDigitalMarketingKnowMore = (state) => getModuleState(state).digitalMarketing.knowMore;
export const getDigitalMarketingChooseUs = (state) => getModuleState(state).digitalMarketing.whyChooseUs;
export const getDigitalMarketingYouNeed = (state) => getModuleState(state).digitalMarketing.youNeed;
export const getDigitalMarketingMoreInfoLinks = (state) => getModuleState(state).digitalMarketing.moreInfoLinks;

export const getSocialAdsInfo = (state) => getModuleState(state).socialAds;
export const getSocialAdsServicesList = (state) => getModuleState(state).socialAds.servicesList;
export const getSocialAdsKnowMore = (state) => getModuleState(state).socialAds.knowMore;
export const getSocialAdsChooseUs = (state) => getModuleState(state).socialAds.whyChooseUs;
export const getSocialAdsYouNeed = (state) => getModuleState(state).socialAds.youNeed;
export const getSocialAdsMoreInfoLinks = (state) => getModuleState(state).socialAds.moreInfoLinks;

export const getGoogleAdsInfo = (state) => getModuleState(state).googleAds;
export const getGoogleAdsServicesList = (state) => getModuleState(state).googleAds.servicesList;
export const getGoogleAdsKnowMore = (state) => getModuleState(state).googleAds.knowMore;
export const getGoogleAdsChooseUs = (state) => getModuleState(state).googleAds.whyChooseUs;
export const getGoogleAdsYouNeed = (state) => getModuleState(state).googleAds.youNeed;
export const getGoogleAdsMoreInfoLinks = (state) => getModuleState(state).googleAds.moreInfoLinks;

export const getAnalyticsInfo = (state) => getModuleState(state).analytics;
export const getAnalyticsServicesList = (state) => getModuleState(state).analytics.servicesList;
export const getAnalyticsKnowMore = (state) => getModuleState(state).analytics.knowMore;
export const getAnalyticsChooseUs = (state) => getModuleState(state).analytics.whyChooseUs;
export const getAnalyticsYouNeed = (state) => getModuleState(state).analytics.youNeed;
export const getAnalyticsMoreInfoLinks = (state) => getModuleState(state).analytics.moreInfoLinks;

export const getMailMarketingInfo = (state) => getModuleState(state).mailMarketing;
export const getMailMarketingServicesList = (state) => getModuleState(state).mailMarketing.servicesList;
export const getMailMarketingKnowMore = (state) => getModuleState(state).mailMarketing.knowMore;
export const getMailMarketingChooseUs = (state) => getModuleState(state).mailMarketing.whyChooseUs;
export const getMailMarketingYouNeed = (state) => getModuleState(state).mailMarketing.youNeed;
export const getMailMarketingMoreInfoLinks = (state) => getModuleState(state).mailMarketing.moreInfoLinks;

export const getMagentoInfo = (state) => getModuleState(state).magento;
export const getMagentoServicesList = (state) => getModuleState(state).magento.servicesList;
export const getMagentoKnowMore = (state) => getModuleState(state).magento.knowMore;
export const getMagentoChooseUs = (state) => getModuleState(state).magento.whyChooseUs;
export const getMagentoYouNeed = (state) => getModuleState(state).magento.youNeed;
export const getMagentoMoreInfoLinks = (state) => getModuleState(state).magento.moreInfoLinks;

export const getWoocommerceInfo = (state) => getModuleState(state).woocommerce;
export const getWoocommerceServicesList = (state) => getModuleState(state).woocommerce.servicesList;
export const getWoocommerceKnowMore = (state) => getModuleState(state).woocommerce.knowMore;
export const getWoocommerceChooseUs = (state) => getModuleState(state).woocommerce.whyChooseUs;
export const getWoocommerceYouNeed = (state) => getModuleState(state).woocommerce.youNeed;
export const getWoocommerceMoreInfoLinks = (state) => getModuleState(state).woocommerce.moreInfoLinks;

export const getEcommerceConsultingInfo = (state) => getModuleState(state).ecommerceConsulting;
export const getEcommerceConsultingServicesList = (state) => getModuleState(state).ecommerceConsulting.servicesList;
export const getEcommerceConsultingKnowMore = (state) => getModuleState(state).ecommerceConsulting.knowMore;
export const getEcommerceConsultingChooseUs = (state) => getModuleState(state).ecommerceConsulting.whyChooseUs;
export const getEcommerceConsultingYouNeed = (state) => getModuleState(state).ecommerceConsulting.youNeed;
export const getEcommerceConsultingMoreInfoLinks = (state) => getModuleState(state).ecommerceConsulting.moreInfoLinks;

export const getMarketingConsultingInfo = (state) => getModuleState(state).marketingConsulting;
export const getMarketingConsultingServicesList = (state) => getModuleState(state).marketingConsulting.servicesList;
export const getMarketingConsultingKnowMore = (state) => getModuleState(state).marketingConsulting.knowMore;
export const getMarketingConsultingChooseUs = (state) => getModuleState(state).marketingConsulting.whyChooseUs;
export const getMarketingConsultingYouNeed = (state) => getModuleState(state).marketingConsulting.youNeed;
export const getMarketingConsultingMoreInfoLinks = (state) => getModuleState(state).marketingConsulting.moreInfoLinks;