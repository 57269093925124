const getModuleState = (state) => state.projects;

export const getFeaturedProjects = (state) => getModuleState(state).featured;

export const getCubicajeProjectInfo = (state) => getModuleState(state).cubicaje;
export const getCubicajeServicesList = (state) => getModuleState(state).cubicaje.servicesList;
export const getCubicajeExternalLink = (state) => getModuleState(state).cubicaje.externalLink;
export const getCubicajeMainImage = (state) => getModuleState(state).cubicaje.mainImage;
export const getCubicajeImageDescription = (state) => getModuleState(state).cubicaje.descriptionImage;
export const getCubicajeTextDescription = (state) => getModuleState(state).cubicaje.descriptionText;
export const getCubicajeGalleryImages = (state) => getModuleState(state).cubicaje.galleryImages;
export const getCubicajeMoreInfoLinks = (state) => getModuleState(state).cubicaje.moreInfoLinks;

export const getBouretProjectInfo = (state) => getModuleState(state).bouret;
export const getBouretServicesList = (state) => getModuleState(state).bouret.servicesList;
export const getBouretExternalLink = (state) => getModuleState(state).bouret.externalLink;
export const getBouretMainImage = (state) => getModuleState(state).bouret.mainImage;
export const getBouretGifImage = (state) => getModuleState(state).bouret.mainGif;
export const getBouretImageDescription = (state) => getModuleState(state).bouret.descriptionImage;
export const getBouretTextDescription = (state) => getModuleState(state).bouret.descriptionText;
export const getBouretGalleryImages = (state) => getModuleState(state).bouret.galleryImages;
export const getBouretMoreInfoLinks = (state) => getModuleState(state).bouret.moreInfoLinks;

export const getRegolodosProjectInfo = (state) => getModuleState(state).regolodos;
export const getRegolodosServicesList = (state) => getModuleState(state).regolodos.servicesList;
export const getRegolodosExternalLink = (state) => getModuleState(state).regolodos.externalLink;
export const getRegolodosMainImage = (state) => getModuleState(state).regolodos.mainImage;
export const getRegolodosGifImage = (state) => getModuleState(state).regolodos.mainGif;
export const getRegolodosImageDescription = (state) => getModuleState(state).regolodos.descriptionImage;
export const getRegolodosTextDescription = (state) => getModuleState(state).regolodos.descriptionText;
export const getRegolodosGalleryImages = (state) => getModuleState(state).regolodos.galleryImages;
export const getRegolodosMoreInfoLinks = (state) => getModuleState(state).regolodos.moreInfoLinks;

export const getCastroProjectInfo = (state) => getModuleState(state).castro;
export const getCastroServicesList = (state) => getModuleState(state).castro.servicesList;
export const getCastroExternalLink = (state) => getModuleState(state).castro.externalLink;
export const getCastroMainImage = (state) => getModuleState(state).castro.mainImage;
export const getCastroGifImage = (state) => getModuleState(state).castro.mainGif;
export const getCastroImageDescription = (state) => getModuleState(state).castro.descriptionImage;
export const getCastroTextDescription = (state) => getModuleState(state).castro.descriptionText;
export const getCastroGalleryImages = (state) => getModuleState(state).castro.galleryImages;
export const getCastroMoreInfoLinks = (state) => getModuleState(state).castro.moreInfoLinks;

export const getCetareaBurelaProjectInfo = (state) => getModuleState(state).cetareaBurela;
export const getCetareaBurelaServicesList = (state) => getModuleState(state).cetareaBurela.servicesList;
export const getCetareaBurelaExternalLink = (state) => getModuleState(state).cetareaBurela.externalLink;
export const getCetareaBurelaMainImage = (state) => getModuleState(state).cetareaBurela.mainImage;
export const getCetareaBurelaGifImage = (state) => getModuleState(state).cetareaBurela.mainGif;
export const getCetareaBurelaImageDescription = (state) => getModuleState(state).cetareaBurela.descriptionImage;
export const getCetareaBurelaTextDescription = (state) => getModuleState(state).cetareaBurela.descriptionText;
export const getCetareaBurelaGalleryImages = (state) => getModuleState(state).cetareaBurela.galleryImages;
export const getCetareaBurelaMoreInfoLinks = (state) => getModuleState(state).cetareaBurela.moreInfoLinks;

export const getGalicineProjectInfo = (state) => getModuleState(state).galicine;
export const getGalicineServicesList = (state) => getModuleState(state).galicine.servicesList;
export const getGalicineExternalLink = (state) => getModuleState(state).galicine.externalLink;
export const getGalicineMainImage = (state) => getModuleState(state).galicine.mainImage;
export const getGalicineGifImage = (state) => getModuleState(state).galicine.mainGif;
export const getGalicineImageDescription = (state) => getModuleState(state).galicine.descriptionImage;
export const getGalicineTextDescription = (state) => getModuleState(state).galicine.descriptionText;
export const getGalicineGalleryImages = (state) => getModuleState(state).galicine.galleryImages;
export const getGalicineMoreInfoLinks = (state) => getModuleState(state).galicine.moreInfoLinks;

export const getBatilasProjectInfo = (state) => getModuleState(state).batilas;
export const getBatilasServicesList = (state) => getModuleState(state).batilas.servicesList;
export const getBatilasExternalLink = (state) => getModuleState(state).batilas.externalLink;
export const getBatilasMainImage = (state) => getModuleState(state).batilas.mainImage;
export const getBatilasGifImage = (state) => getModuleState(state).batilas.mainGif;
export const getBatilasImageDescription = (state) => getModuleState(state).batilas.descriptionImage;
export const getBatilasTextDescription = (state) => getModuleState(state).batilas.descriptionText;
export const getBatilasGalleryImages = (state) => getModuleState(state).batilas.galleryImages;
export const getBatilasMoreInfoLinks = (state) => getModuleState(state).batilas.moreInfoLinks;

export const getGadisProjectInfo = (state) => getModuleState(state).gadis;
export const getGadisServicesList = (state) => getModuleState(state).gadis.servicesList;
export const getGadisExternalLink = (state) => getModuleState(state).gadis.externalLink;
export const getGadisMainImage = (state) => getModuleState(state).gadis.mainImage;
export const getGadisGifImage = (state) => getModuleState(state).gadis.mainGif;
export const getGadisImageDescription = (state) => getModuleState(state).gadis.descriptionImage;
export const getGadisTextDescription = (state) => getModuleState(state).gadis.descriptionText;
export const getGadisGalleryImages = (state) => getModuleState(state).gadis.galleryImages;
export const getGadisMoreInfoLinks = (state) => getModuleState(state).gadis.moreInfoLinks;

export const getJeniferProjectInfo = (state) => getModuleState(state).jenifer;
export const getJeniferServicesList = (state) => getModuleState(state).jenifer.servicesList;
export const getJeniferExternalLink = (state) => getModuleState(state).jenifer.externalLink;
export const getJeniferMainImage = (state) => getModuleState(state).jenifer.mainImage;
export const getJeniferGifImage = (state) => getModuleState(state).jenifer.mainGif;
export const getJeniferImageDescription = (state) => getModuleState(state).jenifer.descriptionImage;
export const getJeniferTextDescription = (state) => getModuleState(state).jenifer.descriptionText;
export const getJeniferGalleryImages = (state) => getModuleState(state).jenifer.galleryImages;
export const getJeniferMoreInfoLinks = (state) => getModuleState(state).jenifer.moreInfoLinks;

export const getAutoradioProjectInfo = (state) => getModuleState(state).autoradio;
export const getAutoradioServicesList = (state) => getModuleState(state).autoradio.servicesList;
export const getAutoradioExternalLink = (state) => getModuleState(state).autoradio.externalLink;
export const getAutoradioMainImage = (state) => getModuleState(state).autoradio.mainImage;
export const getAutoradioGifImage = (state) => getModuleState(state).autoradio.mainGif;
export const getAutoradioImageDescription = (state) => getModuleState(state).autoradio.descriptionImage;
export const getAutoradioTextDescription = (state) => getModuleState(state).autoradio.descriptionText;
export const getAutoradioGalleryImages = (state) => getModuleState(state).autoradio.galleryImages;
export const getAutoradioMoreInfoLinks = (state) => getModuleState(state).autoradio.moreInfoLinks;

export const getGrunverProjectInfo = (state) => getModuleState(state).grunver;
export const getGrunverServicesList = (state) => getModuleState(state).grunver.servicesList;
export const getGrunverExternalLink = (state) => getModuleState(state).grunver.externalLink;
export const getGrunverMainImage = (state) => getModuleState(state).grunver.mainImage;
export const getGrunverGifImage = (state) => getModuleState(state).grunver.mainGif;
export const getGrunverImageDescription = (state) => getModuleState(state).grunver.descriptionImage;
export const getGrunverTextDescription = (state) => getModuleState(state).grunver.descriptionText;
export const getGrunverGalleryImages = (state) => getModuleState(state).grunver.galleryImages;
export const getGrunverMoreInfoLinks = (state) => getModuleState(state).grunver.moreInfoLinks;

export const getTermariaProjectInfo = (state) => getModuleState(state).termaria;
export const getTermariaServicesList = (state) => getModuleState(state).termaria.servicesList;
export const getTermariaExternalLink = (state) => getModuleState(state).termaria.externalLink;
export const getTermariaMainImage = (state) => getModuleState(state).termaria.mainImage;
export const getTermariaGifImage = (state) => getModuleState(state).termaria.mainGif;
export const getTermariaImageDescription = (state) => getModuleState(state).termaria.descriptionImage;
export const getTermariaTextDescription = (state) => getModuleState(state).termaria.descriptionText;
export const getTermariaGalleryImages = (state) => getModuleState(state).termaria.galleryImages;
export const getTermariaMoreInfoLinks = (state) => getModuleState(state).termaria.moreInfoLinks;

export const getProjectSliders = (state) => getModuleState(state).projectsSlider;
export const getProjectSlidersTitles = (state) => getModuleState(state).projectsSlider.titles

export const getProjectsSuperiorRow = (state) => getModuleState(state).projectsSuperiorRow;
export const getProjectsDoubleRow = (state) => getModuleState(state).projectsDoubleRow;
export const getNormalProjects = (state) => getModuleState(state).normalProjects;
export const getProjectsMoreInfoLinks = (state) => getModuleState(state).projectsMoreInfoLinks;
